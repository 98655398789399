import { Card } from 'react-bootstrap'
import useTransacc from '../../../context/Transacc'

function Encabezado ({ noProfMod, sinEncabezado }) {
  const [stdTransacc, , , tiposTarea] = useTransacc()

  if (sinEncabezado) return null
  return (
    <Card.Header
      className={stdTransacc.tarea === tiposTarea.consultando
        ? 'h5 bg-dark-subtle text-center fw-bold p-0'
        : stdTransacc.tarea === tiposTarea.modificando
          ? 'h5 bg-success-subtle text-center fw-bold p-0'
          : 'h5 bg-secondary-subtle text-center fw-bold p-0'}
    >{stdTransacc.tarea === tiposTarea.consultando
      ? 'PROFORMAS EXISTENTES'
      : stdTransacc.tarea === tiposTarea.modificando
        ? 'MODIFICANDO LA PROFORMA #' + noProfMod
        : stdTransacc.tarea === tiposTarea.filtrando
          ? 'FILTRANDO PROFORMAS EXISTENTES'
          : 'PROFORMAS NUEVAS'}
    </Card.Header>
  )
}

export default Encabezado
