/* eslint-disable react/jsx-indent */
import { useEffect, useState, useRef } from 'react'
import { Form, InputGroup, Row, Col, Container, Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'

import TransaccDetTotales from './TransaccDetTotales'
import TransaccDetList from './TransaccDetList'
import TablaFiltro from '../tablas/TablaFiltro'
import TablaOpcion from '../tablas/TablaOpcion'
import PVsXItem from '../../ventas/PVsXItem'
import CampoNumerico from '../CampoNumerico'
import TransaccDetBotones from './TransaccDetBotones'
import ItemDatos from '../../productos/items/ItemDatos'
import ItemFiltro from '../../productos/items/ItemFiltro'

import restAPIAxios from '../../../utils/axios'
import encryptStorage from '../../../utils/encryptStorage'
import useAdmin from '../../../context/Admin'
import useTransacc from '../../../context/Transacc'

import {
  infoGItemFiltro, infoItemFiltro, numberFormat, infoUndMedXItemSel,
  gcFmtoBgVUnit, gcFmtosColorFondo
} from '../../../utils/generales'

function TransaccDet (props) {
  const { datosAPI, noCliente, fechaDoc, esNC } = props

  const [stdAdmin] = useAdmin()
  const [stdTransacc, , , tiposTarea] = useTransacc()
  const consultando = stdTransacc.tarea === tiposTarea.consultando
  const filtrando = stdTransacc.tarea === tiposTarea.filtrando
  const creando = stdTransacc.tarea === tiposTarea.creando
  const modificando = stdTransacc.tarea === tiposTarea.modificando

  // Para verificar si cambio de Documento y poder colocar en un Nuevo Registro (lado izquierdo)
  const docAnt = useRef({ clave: stdTransacc.clave, index: stdTransacc.index })

  const [datDetalles, setDatDetalles] = useState([])

  const [esNuevoItem, setEsNuevoItem] = useState(true)
  const [consultandoItem, setConsultandoItem] = useState(false)

  const [noItem, setNoItem] = useState('')
  const [noUndMed, setNoUndMed] = useState('')
  const [noGItem, setNoGItem] = useState('')

  const [filtroGCod, setFiltroGCod] = useState('')
  const [filtroGDescrip, setFiltroGDescrip] = useState('')
  const [filtroItemNo, setFiltroItemNo] = useState('')
  const [filtroItemCod, setFiltroItemCod] = useState('')
  const [filtroItemDescrip, setFiltroItemDescrip] = useState('')
  const [filtroItemAux, setFiltroItemAux] = useState('')
  const [filtroItemOtrosCod, setFiltroItemOtrosCod] = useState('')
  const [itemTIVA, setItemTIVA] = useState('2')
  const itemExist = useRef(0)
  const itemUbicc = useRef('')

  const [itemCant, setItemCant] = useState(esNC ? -1 : 1)
  const [itemPV, setItemPV] = useState(0)
  const [itemPDscto, setItemPDscto] = useState(0)
  const [itemVDscto, setItemVDscto] = useState(0)
  const [itemVUnit, setItemVUnit] = useState(0)
  const [itemVTot, setItemVTot] = useState(0)
  const [itemDetalle, setItemDetalle] = useState('')

  // Identifica el registro real (index + 1) del arreglo de detalles
  const [indexClickDet, setIndexClickDet] = useState(consultando ? 1 : 0)
  // Para manejar la regarga de los Detalles usando la bandera exclusivamente
  // la fuente (source) es para identificar que evento produce la recarga
  // y poder colocar la parte izquierda en un Nuevo o Existente registro (item) de la lista
  const [recargaRegs, setRecargaRegs] = useState({ source: consultando ? 'existente' : '', bandera: false })
  const [cargando, setCargando] = useState(false)

  const poolName = stdAdmin.orclHost

  // Extrae los datos de la empresa (mysql). Parámetros de la empresa y usuario
  const userEmpConfig = encryptStorage.getItem('config')
  const pDsctoMax = userEmpConfig.p_dscto_max
  const decimales = userEmpConfig.decimales
  const decMoneda = 2

  const userVSAM = encryptStorage.getItem('userVSAM')
  // Variables de Acceso
  let accDsctoItem = ''
  let accItemOtrasEmp = ''
  if (stdAdmin.paramsEmp && Object.keys(stdAdmin.paramsEmp).length) {
    accDsctoItem = stdAdmin.paramsEmp.CTRL_DSCTO_CLIENTES === 'I' &&
      userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_VTAS_DSCTO_ITEMS_MOD) === -1
    accItemOtrasEmp = userVSAM.RESTRICC_ACC_PARAM.indexOf(process.env.REACT_APP_ACC_EXIST_OTRAS_EMP) > -1
  }

  // Carga los detalles de la transacción para cargar la tabla
  useEffect(() => {
    console.log('🚀 ~ file: TransaccDet.jsx ~ useEffect ~ recargaRegs, datosAPI, esNC', 'Cargando Detalles ...', recargaRegs, datosAPI, esNC, stdTransacc)
    const noBodega = stdTransacc.otrosDat.noBodega
    setConsultandoItem(false)
    async function cargaTransaccDetList () {
      setCargando(true)
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CARGAR LOS DETALLES' + datosAPI.msjErrorDet,
        {
          method: 'get',
          url: consultando
            ? `${datosAPI.urlBase}/existente/detalles/${poolName}/${stdAdmin.orclUsuario}/${stdAdmin.noEmpresa}/${stdTransacc.clave}/${noBodega}`
            : `${datosAPI.urlBase}/nueva/detalles/${poolName}/${stdAdmin.orclUsuario}/${stdTransacc.clave}/${noBodega}`
        }
      )
      setCargando(false)
      if (respuestaAPI.status === 200) {
        const mismoDoc = (stdTransacc.clave === docAnt.current.clave &&
          stdTransacc.index === docAnt.current.index)
        setDatDetalles(respuestaAPI.data)
        props.devuelveDets(respuestaAPI.data)
        if (consultando) {
          setIndexClickDet(1)
          ctrlClickTablaItems(respuestaAPI.data[0], 0)
        } else {
          if (recargaRegs.source === '' || recargaRegs.source === 'elimina' || !mismoDoc) {
            setEsNuevoItem(true)
            setIndexClickDet(0)
            limpiaCampos('GRUPO')
            limpiaCampos('ITEM')
            limpiaCampos('VAL')
            // Por si acaso haya entrado a este if por !mismoDoc
            setRecargaRegs({ source: '', bandera: recargaRegs.bandera })
            docAnt.current = { clave: stdTransacc.clave, index: stdTransacc.index }
          }
          if (recargaRegs.source === 'nuevo') {
            ctrlClickTablaItems(respuestaAPI.data[respuestaAPI.data.length - 1], respuestaAPI.data.length - 1)
          }
          if (recargaRegs.source === 'modifica') {
            ctrlClickTablaItems(respuestaAPI.data[indexClickDet - 1], indexClickDet - 1)
          }
        }
      // En caso de que la API devuelva error
      } else {
        setDatDetalles([])
      }
    }
    // Para evitar errores como 404 en el url o de undefined en noBodega
    // cuando pasa de Facturas a Proformas y no hay noPtoVta en otrosDat del stdTransacc
    // Pasa por este componente con clave = '' cuando viene de consultando o modificando
    // y todavía no ha cargado los registros nuevos del documento
    if (stdTransacc.clave && noBodega) cargaTransaccDetList()
  }, [stdAdmin.noEmpresa, stdAdmin.orclUsuario, stdTransacc.clave, esNC,
    datosAPI.urlBase, poolName, consultando, stdTransacc.otrosDat.noBodega,
    recargaRegs.bandera])
  //* * No debe ir "indexClickDet" en el arreglo del useEffect,
  //* * para que no vuelva a cargar los datos al elegir o cambiar un item

  // FUNCIONES PRIVADAS DEL COMPONENTE
  // Efecto que sirve solamente para colocar el foco en un campo en particular del componente

  function limpiaCampos (opcion) {
    switch (opcion) {
      case 'GRUPO' :
        setNoGItem('')
        setFiltroGCod('')
        setFiltroGDescrip('')
        break
      case 'ITEM' :
        setNoItem('')
        setNoUndMed('')
        setFiltroItemNo('')
        setFiltroItemCod('')
        setFiltroItemDescrip('')
        setFiltroItemAux('')
        setFiltroItemOtrosCod('')
        setItemTIVA('2')
        itemExist.current = 0
        itemUbicc.current = ''
        break
      case 'VAL' :
        setItemCant(esNC ? -1 : 1)
        setItemPV(0)
        setItemPDscto(0)
        setItemVDscto(0)
        setItemVUnit(0)
        setItemVTot(0)
        setItemDetalle('')
        break
      default:
    }
  }
  function datosValidos () {
    let noValido = false
    if (noItem === '') {
      Swal.fire(
        'FALTA ELEGIR UN ITEM PARA PODER GRABAR EL REGISTRO',
        'Tiene que seleccionar un item antes de grabar el registro',
        'info'
      )
      noValido = true
    }
    if (!noValido && noUndMed === '') {
      Swal.fire(
        'EL ITEM NO TIENE ASIGNADA UNA UNIDAD DE MEDIDA BASICA',
        'puede crearla en Items - Existencias',
        'info'
      )
      noValido = true
    }
    return !noValido
  }
  function condGrupo () {
    return !noGItem ? '' : ' and A.NO_GRUPO_ITEM = ' + noGItem
  }

  // FUNCIONES EVENTOS CLICK DE LOS CAMPOS
  function ctrlChangeTIVA (e) {
    // e.preventDefault()
    setItemTIVA(e.target.value)
  }
  function ctrlChangeDetalle (e) {
    // e.preventDefault()
    setItemDetalle(e.target.value)
  }
  // FUNCIONES EVENTOS CLICK DE BOTONES
  function ctrlClickLimpiaVal (e) {
    // e.preventDefault()
    limpiaCampos('VAL')
  }
  function ctrlClickLimpiaItem (e) {
    // e.preventDefault()
    limpiaCampos('GRUPO')
    limpiaCampos('ITEM')
  }
  function ctrlClickNuevoReg (e) {
    // e.preventDefault()
    setEsNuevoItem(true)
    limpiaCampos('GRUPO')
    limpiaCampos('ITEM')
    limpiaCampos('VAL')
  }
  function ctrlClickEliminaReg (e) {
    // e.preventDefault()
    async function deleteRegDet () {
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ELIMINAR EL REGISTRO',
        {
          method: 'delete',
          url: `${datosAPI.urlBase}/nueva/detalles/${stdTransacc.clave}`,
          data: { poolName, noRegDoc: indexClickDet }
        }
      )
      if (respuestaAPI.status === 200) {
        // Swal.fire({
        //   title: `EL REGISTRO ${indexClickDet} SE ELIMINO CON EXITO`,
        //   text: '',
        //   icon: 'success'
        // })
        setRecargaRegs({ source: 'elimina', bandera: !recargaRegs.bandera })
      }
    }
    Swal.fire({
      title: 'CONFIRME',
      text: `Desea eliminar el registro ${indexClickDet}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) deleteRegDet()
    })
  }
  async function ctrlGrabaDetalle (e) {
    e.preventDefault()
    //  noItem, noUndMed, itemTIVA, itemCant, itemVUnit, itemPV, itemDetalle})
    if (datosValidos()) {
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE ${esNuevoItem ? 'CREAR' : 'ACTUALIZAR'} EL REGISTRO'`,
        {
          method: esNuevoItem ? 'post' : 'patch',
          url: datosAPI.urlBase + '/nueva/detalles',
          data: {
            poolName,
            noReg: stdTransacc.clave,
            noRegDoc: esNuevoItem ? datDetalles.length + 1 : indexClickDet,
            noItem,
            noUndMed,
            itemTIVA,
            itemCant,
            itemVUnit,
            itemPV,
            itemDetalle
          }
        }
      )
      if (respuestaAPI.status === 200) {
        // Para que actualice los TOTALES fuera del componente
        setRecargaRegs({ source: esNuevoItem ? 'nuevo' : 'modifica', bandera: !recargaRegs.bandera })
      }
    }
  }
  function ctrlClickDatosItem (e) {
    // e.preventDefault()
    if (noItem) {
      setConsultandoItem(true)
    } else {
      Swal.fire(
        'ELIJA UN ITEM PARA SU CONSULTA',
        'No se reconoce ningún item para ser consultado',
        'info'
      )
    }
  }

  // FUNCIONES DE CONTROL (EVENTOS) DEVUELTOS POR LOS COMPONENTES
  function ctrlClickTablaItems (registro, index) {
    if (index >= 0 && registro !== undefined) {
      setIndexClickDet(parseInt(index) + 1)
      setEsNuevoItem(false)
      setNoItem(registro.NO_ITEM)
      setNoUndMed(registro.NO_UND_MED)
      setNoGItem(registro.NO_GRUPO_ITEM === null ? '' : registro.NO_GRUPO_ITEM)

      setFiltroGCod(registro.GRUPO_COD === null ? '' : registro.GRUPO_COD)
      setFiltroGDescrip(registro.GRUPO_DESCRIP === null ? '' : registro.GRUPO_DESCRIP)
      setFiltroItemNo(registro.NO_ITEM_USER)
      setFiltroItemCod(registro.CODIGO)
      setFiltroItemDescrip(registro.DESCRIP)
      setFiltroItemAux(registro.DESCRIP_AUX === null ? '' : registro.DESCRIP_AUX)
      setFiltroItemOtrosCod(registro.OTROS_COD === null ? '' : registro.OTROS_COD)

      setItemTIVA(registro.T_IVA)
      if (registro.GRUPO_L_SERVICIO === -1) itemExist.current = '-- servicio --'
      else itemExist.current = registro.EXIST === null ? 0 : registro.EXIST
      itemUbicc.current = registro.UBICACION === null ? '' : registro.UBICACION

      setItemCant(registro.CANT)
      setItemPV(registro.VU_SIN_DSCTO)
      setItemPDscto(registro.VU_SIN_DSCTO === 0 ? 0 : (registro.VU_SIN_DSCTO - registro.VALOR_UNIT) / registro.VU_SIN_DSCTO * 100)
      setItemVDscto(numberFormat(registro.VU_SIN_DSCTO - registro.VALOR_UNIT, decimales, true))
      setItemVUnit(registro.VALOR_UNIT)
      setItemVTot(numberFormat(registro.CANT * registro.VALOR_UNIT, decMoneda, true))
      setItemDetalle(registro.DETALLE_ITEM === null ? '' : registro.DETALLE_ITEM)
    }
  }
  function setValoresGrupo (txtCampoEnTabla, filtro, registro) {
    // Si devolvió un registro sin valores
    if (Object.keys(registro).length === 0) {
      setNoGItem('')
      if (txtCampoEnTabla !== 'DESCRIP') setFiltroGDescrip('')
    } else {
      // Asigna valores Grupo
      setNoGItem(registro.KEY_TAB)
      setFiltroGCod(registro.CODIGO)
      setFiltroGDescrip(registro.DESCRIP)
      // Limpia valores Item
      limpiaCampos('ITEM')
    }
  }
  function setValoresItem (txtCampoEnTabla, filtro, registro) {
    // Existe el item (registro con valores)
    if (Object.keys(registro).length) {
      // Cambio el valor
      if (registro.NO_ITEM !== noItem) {
        setNoItem(registro.KEY_TAB)
        // Asigna valores Grupo
        setNoGItem(registro.NO_GRUPO_ITEM === null ? '' : registro.NO_GRUPO_ITEM)
        setFiltroGCod(registro.GRUPO_COD === null ? '' : registro.GRUPO_COD)
        setFiltroGDescrip(registro.GRUPO_DESCRIP === null ? '' : registro.GRUPO_DESCRIP)
        // Asigna valores Item
        setFiltroItemNo(registro.NO_ITEM_USER)
        setFiltroItemCod(registro.CODIGO)
        setFiltroItemDescrip(registro.DESCRIP)
        setFiltroItemAux(registro.DESCRIP_AUX === null ? '' : registro.DESCRIP_AUX)
        setFiltroItemOtrosCod(registro.OTROS_COD === null ? '' : registro.OTROS_COD)
        if (registro.GRUPO_L_SERVICIO === -1) itemExist.current = '-- servicio --'
        else itemExist.current = registro.EXIST === null ? 0 : registro.EXIST
        itemUbicc.current = registro.UBICACION === null ? '' : registro.UBICACION
        setItemTIVA(registro.T_IVA)
        // Asigna Unidad de Medida
        setNoUndMed(registro.NO_UND_MED)
        // Asigna Precio de Venta
        setItemPV(registro.V_PVENTA)
        setItemPDscto(0)
        setItemVDscto(0)
        setItemVUnit(registro.V_PVENTA)
        setItemVTot(itemCant * registro.V_PVENTA)
      }
    // No hay valores de item (no se encontró el item)
    } else {
      // Borra todos los valores
      setNoItem('')
      limpiaCampos('GRUPO')
      limpiaCampos('ITEM')
      limpiaCampos('VAL')
      // Recupera los valores del filtro
      if (txtCampoEnTabla === 'NO_ITEM_USER') setFiltroItemNo(filtro)
      else if (txtCampoEnTabla === 'CODIGO') setFiltroItemCod(filtro)
    }
  }
  function recalculaValores (campo, valor) {
    let tmpPDscto
    let tmpVDscto
    switch (campo) {
      case 'itemCant': {
        const vUnit = numberFormat(valor * itemVUnit, decimales, true)
        setItemCant(valor)
        setItemVTot(vUnit)
        break
      }
      case 'pvsXItem':
        setItemPV(numberFormat(valor, decimales, true))
        setItemVUnit(numberFormat(valor - itemVDscto, decimales, true))
        setItemVTot(numberFormat(itemCant * (valor - itemVDscto), decMoneda, true))
        break
      case 'itemPDscto':
        tmpPDscto = numberFormat(valor, decimales, true)
        // Verifica que el %DSCTO no sea mayor al permitido
        if (tmpPDscto > pDsctoMax) {
          tmpPDscto = pDsctoMax
          Swal.fire(
              `EL % DSCTO. NO PUEDE SER MAYOR A ${pDsctoMax}`,
              'El usuario no tiene nivel de acceso para utilizar un mayor descuento',
              'info'
          )
        }
        setItemPDscto(numberFormat(tmpPDscto, decimales, true))
        tmpVDscto = numberFormat(itemPV * tmpPDscto / 100, decimales, true)
        setItemVDscto(tmpVDscto)
        setItemVUnit(numberFormat(itemPV - tmpVDscto, decimales, true))
        setItemVTot(numberFormat(itemCant * (itemPV - tmpVDscto), decMoneda, true))
        break
      case 'itemVDscto': {
        const pDscto = itemPV === 0 ? 0 : numberFormat(valor / itemPV * 100, decimales, true)
        // Verifica que el %DSCTO no sea mayor al permitido
        if (pDscto > pDsctoMax) {
          setItemPDscto(numberFormat(pDsctoMax, decimales, true))
          tmpVDscto = numberFormat(itemPV * pDsctoMax / 100, decimales, true)
          setItemVDscto(tmpVDscto)
          setItemVUnit(numberFormat(itemPV - tmpVDscto, decimales, true))
          setItemVTot(numberFormat(itemCant * (itemPV - tmpVDscto), decMoneda, true))
          Swal.fire(
              `EL % DSCTO. NO PUEDE SER MAYOR A ${pDsctoMax}`,
              'El usuario no tiene nivel de acceso para utilizar un mayor descuento',
              'info'
          )
        } else {
          setItemVDscto(numberFormat(valor, decimales, true))
          setItemPDscto(pDscto)
          setItemVUnit(numberFormat(itemPV - valor, decimales, true))
          setItemVTot(numberFormat(itemCant * (itemPV - valor), decMoneda, true))
        }
        break
      }
      case 'itemVUnit':
        if (valor > itemPV) {
          setItemVUnit(numberFormat(itemPV, decimales, true))
          setItemPDscto(numberFormat(0, decimales, true))
          setItemVDscto(numberFormat(0, decimales, true))
          setItemVTot(itemCant * itemPV)
          Swal.fire(
            'EL VALOR UNITARIO NO PUEDE SER MAYOR AL PV',
            'El sistema reasignará el PV al Valor Unitario',
            'info'
          )
        } else {
          setItemVUnit(numberFormat(valor, decimales, true))
          setItemPDscto(numberFormat(itemPV === 0 ? 0 : (itemPV - valor) / itemPV * 100, decimales, true))
          setItemVDscto(numberFormat(itemPV - valor, decimales, true))
          setItemVTot(itemCant * valor)
        }
        break
      case 'itemVTot': {
        const cant = itemVUnit === 0 ? 0 : numberFormat(valor / itemVUnit, decimales, true)
        setItemVTot(numberFormat(valor, decMoneda, true))
        setItemCant(cant)
        break
      }
      default:
    }
  }

  if (!stdAdmin.auth || !userVSAM || !userEmpConfig) return null

  const fmtoRowTipoDato = 'pt-1 pb-2 mb-1 border-bottom border-primary'
  const fmtoTxtTipoDato = 'border border-primary text-primary fw-bold pt-0 pb-0'
  let fmtoBgExist = ''
  if (datDetalles.length && indexClickDet) {
    fmtoBgExist = datDetalles[indexClickDet - 1].GRUPO_L_SERVICIO === 0 && (datDetalles[indexClickDet - 1].EXIST < datDetalles[indexClickDet - 1].EXIST_MIN || datDetalles[indexClickDet - 1].EXIST > datDetalles[indexClickDet - 1].EXIST_MAX)
      ? gcFmtosColorFondo.existMinMax
      : ''
  }
  let fmtoBgVUnit = ''
  if (datDetalles.length && indexClickDet) {
    fmtoBgVUnit = gcFmtoBgVUnit(datDetalles[indexClickDet - 1], consultando)
  }
  return (
    cargando
      ? <div className='text-center m-3'><Spinner animation='grow' size='sm' />Cargando detalles ...</div>
      : consultandoItem
        ? <ItemDatos
            solicita={datosAPI.urlBase.substr(1)}
            datosItem={datDetalles[indexClickDet - 1]}
            noEmp={stdAdmin.noEmpresa} noItem={noItem} noCliProv={noCliente}
            fechaDoc={fechaDoc} accItemOtrasEmp={accItemOtrasEmp}
            devuelveSalirConsulta={(e) => setConsultandoItem(false)}
          />
        : <>
            {/* TOTALES */}
            <TransaccDetTotales
              datosAPI={datosAPI} orden={props.orden}
              devuelveTots={props.devuelveTots}
              devuelveDblClick={props.devuelveDblClick}
            />
            {filtrando || creando || modificando
              ? <ItemFiltro
                  idName='DET_' esNC={esNC} datosAPI={datosAPI}
                  sigReg={datDetalles.length + 1}
                  devuelveRecargaRegs={(valor) => setRecargaRegs({ source: valor.source, bandera: !recargaRegs.bandera })}
                />
              : null}
            {/* DETALLES */}
            <Row className='ms-0 me-0'>
              {/* LADO IZQUIERDO. Datos de un detalle (nuevo o existente) */}
              <Col
                className='border border-primary-subtle border-start-0 border-bottom-0 p-0'
                id='setRegistro'
                xs={4} xl={3}
                style={{ zIndex: '2' }}
              >
                <Form onSubmit={ctrlGrabaDetalle}>
                  <div
                    className={esNuevoItem
                      ? ('h6 pt-1 pb-1 m-0 border-primary-subtle border-bottom text-center' + gcFmtosColorFondo.nuevoReg)
                      : ('h6 pt-1 pb-1 m-0 border-primary-subtle border-bottom text-center' + gcFmtosColorFondo.editandoReg)}
                  >{esNuevoItem
                    ? 'NUEVO REGISTRO No. ' + (datDetalles.length + 1)
                    : consultando ? 'REGISTRO No. ' + indexClickDet : 'EDITA REGISTRO No. ' + indexClickDet}
                  </div>
                  {/* BOTONES DETALLE */}
                  <TransaccDetBotones
                    esNuevoItem={esNuevoItem}
                    devuelveClickLimpiaItem={ctrlClickLimpiaItem}
                    devuelveClickLimpiaVal={ctrlClickLimpiaVal}
                    devuelveClickNuevoReg={ctrlClickNuevoReg}
                    devuelveClickEliminaReg={ctrlClickEliminaReg}
                    devuelveClickDatosItem={ctrlClickDatosItem}
                  />
                  {/* DATOS INDIVIDUALES Y PARA EL INGRESO DEL ITEM */}
                  <Container className={'pt-1 pb-2 ' + (esNuevoItem ? 'bg-light' : 'bg-secondary-subtle')}>
                    {/* DATOS DEL GRUPO DEL ITEM */}
                    <Row id='datosGrupoItems' className={fmtoRowTipoDato} xs={3}>
                      <Col className=''>
                        <InputGroup.Text className={fmtoTxtTipoDato} style={{ width: 80 }}>GRUPO</InputGroup.Text>
                      </Col>
                      {/* Grupo código */}
                      <Col style={{ width: 200 }}>
                        <TablaFiltro
                          infoTablaLista={infoGItemFiltro(stdTransacc.doc, 'CODIGO', consultando)}
                          devuelveFiltro={(valFiltro) => { setFiltroGCod(valFiltro) }}
                          devuelveRegistro={setValoresGrupo}
                        >{filtroGCod}
                        </TablaFiltro>
                      </Col>
                      {/* Grupo descripción */}
                      <Col xs={12}>
                        <TablaFiltro
                          infoTablaLista={infoGItemFiltro(stdTransacc.doc, 'DESCRIP', consultando)}
                          devuelveFiltro={(valFiltro) => { setFiltroGDescrip(valFiltro) }}
                          devuelveRegistro={setValoresGrupo}
                          style={{ fontSize: '10px' }}
                        >{filtroGDescrip}
                        </TablaFiltro>
                      </Col>
                    </Row>
                    {/* DATOS DEL ITEM */}
                    <Row id='datosItem' className={fmtoRowTipoDato} xs={3}>
                      <Col>
                        <InputGroup.Text className={fmtoTxtTipoDato} style={{ width: 70 }}>ITEM</InputGroup.Text>
                      </Col>
                      {/* Número de item user */}
                      <Col style={{ width: 200 }}>
                        <TablaFiltro
                          infoTablaLista={infoItemFiltro(stdTransacc.doc
                            , 'NO_ITEM_USER', consultando
                            , ' and E.NO_BODEGA = ' + stdTransacc.otrosDat.noBodega, condGrupo())}
                          devuelveFiltro={(valFiltro) => { setFiltroItemNo(valFiltro) }}
                          devuelveRegistro={setValoresItem}
                        >{filtroItemNo}
                        </TablaFiltro>
                      </Col>
                      {/* Código */}
                      <Col style={{ width: 300 }}>
                        <TablaFiltro
                          infoTablaLista={infoItemFiltro(stdTransacc.doc
                            , 'CODIGO', consultando
                            , ' and E.NO_BODEGA = ' + stdTransacc.otrosDat.noBodega, condGrupo())}
                          devuelveFiltro={(valFiltro) => { setFiltroItemCod(valFiltro) }}
                          devuelveRegistro={setValoresItem}
                        >{filtroItemCod}
                        </TablaFiltro>
                      </Col>
                      {/* Descripción */}
                      <Col xs={12}>
                        <TablaFiltro
                          infoTablaLista={infoItemFiltro(stdTransacc.doc
                            , 'DESCRIP', consultando
                            , ' and E.NO_BODEGA = ' + stdTransacc.otrosDat.noBodega, condGrupo())}
                          devuelveFiltro={(valFiltro) => { setFiltroItemDescrip(valFiltro) }}
                          devuelveRegistro={setValoresItem}
                          style={{ fontSize: '10px' }}
                        >{filtroItemDescrip}
                        </TablaFiltro>
                      </Col>
                      {/* Descripción auxiliar */}
                      <Col xs={12}>
                        <TablaFiltro
                          infoTablaLista={infoItemFiltro(stdTransacc.doc
                            , 'DESCRIP_AUX', consultando
                            , ' and E.NO_BODEGA = ' + stdTransacc.otrosDat.noBodega, condGrupo())}
                          devuelveFiltro={(valFiltro) => { setFiltroItemAux(valFiltro) }}
                          devuelveRegistro={setValoresItem}
                          style={{ fontSize: '10px' }}
                        >{filtroItemAux}
                        </TablaFiltro>
                      </Col>
                      {/* Otros códigos */}
                      <Col xs={12}>
                        <TablaFiltro
                          infoTablaLista={infoItemFiltro(stdTransacc.doc
                            , 'OTROS_COD', consultando
                            , ' and E.NO_BODEGA = ' + stdTransacc.otrosDat.noBodega, condGrupo())}
                          devuelveFiltro={(valFiltro) => { setFiltroItemOtrosCod(valFiltro) }}
                          devuelveRegistro={setValoresItem}
                          style={{ fontSize: '10px' }}
                        >{filtroItemOtrosCod}
                        </TablaFiltro>
                      </Col>
                      {/* Existencia */}
                      <InputGroup className='' size='sm' style={{ maxWidth: 280 }}>
                        <InputGroup.Text className='text-primary' id=''>Existencia:</InputGroup.Text>
                        <CampoNumerico
                          idName='itemExist' obligatorio={false} deshabilitado
                          fmtoInput={'text-primary' + fmtoBgExist}
                          decimales={decimales} soloPositivo={false}
                        >{itemExist.current}
                        </CampoNumerico>
                        <InputGroup.Text
                          className='text-primary' id=''
                        >{datDetalles.length > 0 && indexClickDet > 0 && indexClickDet <= datDetalles.length
                          ? datDetalles[indexClickDet - 1].ABREV
                          : 'UM'}
                        </InputGroup.Text>
                      </InputGroup>
                      {/* Ubicación */}
                      <InputGroup className='' size='sm'>
                        <InputGroup.Text id=''>Ubicc.:</InputGroup.Text>
                        <Form.Control
                          className='text-center'
                          value={itemUbicc.current} disabled
                        />
                      </InputGroup>
                    </Row>
                    {/* DATOS DE LOS VALORES */}
                    {/* Tipo IVA, cantidad y UM */}
                    <Row id='datosValores'>
                      <InputGroup className='pt-1' size='sm'>
                        <Col>
                          <InputGroup.Text className={fmtoTxtTipoDato} style={{ width: 100 }}>VALORES</InputGroup.Text>
                        </Col>
                        {/* Tipo de IVA */}
                        <Form.Select
                          className='ms-5' style={{ maxWidth: 120 }}
                          value={itemTIVA}
                          disabled={consultando}
                          onChange={ctrlChangeTIVA}
                        >
                          <option value='2'>{'IVA<>0%'}</option>
                          <option value='0'>IVA 0%</option>
                        </Form.Select>
                      </InputGroup>
                      {/* Cantidad y Unidad de Medida */}
                      <InputGroup className='' size='sm' style={{ maxWidth: 350 }}>
                        {/* Cantidad */}
                        <InputGroup.Text id='itemCant'>Cantidad:</InputGroup.Text>
                        <CampoNumerico
                          idName='itemCant' devuelveVal={recalculaValores}
                          fmtoInput={'me-2' + (!consultando && itemCant > itemExist.current ? gcFmtosColorFondo.sinStock : '')}
                          decimales={decimales} obligatorio
                          deshabilitado={consultando} soloPositivo={!esNC}
                        >{itemCant}
                        </CampoNumerico>
                        {/* Unidad de Medida */}
                        <TablaOpcion
                          valTabla={noUndMed}
                          infoTablaLista={infoUndMedXItemSel()}
                          devuelveDatos={({ nuevoVal, index, descrip }) => setNoUndMed(nuevoVal)}
                          deshabilitado={consultando}
                          claveAdicional={noItem}
                        />
                      </InputGroup>
                    </Row>
                    {/* Precio de Venta */}
                    <PVsXItem
                      valTabla={itemPV} noItem={noItem}
                      fmtoText='fw-bold text-primary'
                      fmtoInput='fw-bold text-primary text-end'
                      deshabilitado={consultando} devuelveFiltro={recalculaValores}
                    />
                    {/* % y Valor de Descuento */}
                    <Row>
                      <InputGroup className='' size='sm' style={{ maxWidth: 210 }}>
                        <InputGroup.Text id='itemPDscto'>Dscto.:</InputGroup.Text>
                        <CampoNumerico
                          idName='itemPDscto' devuelveVal={recalculaValores}
                          fmtoInput='' decimales={decimales} obligatorio
                          deshabilitado={consultando || !accDsctoItem} soloPositivo
                          min={0} max={100}
                        >{itemPDscto}
                        </CampoNumerico>
                        <InputGroup.Text id='' className=''>%</InputGroup.Text>
                      </InputGroup>
                      <InputGroup className='' size='sm' style={{ maxWidth: 220 }}>
                        <InputGroup.Text id='itemVDscto'>V.Dscto.:</InputGroup.Text>
                        <CampoNumerico
                          idName='itemVDscto' devuelveVal={recalculaValores}
                          fmtoInput='' decimales={decimales} obligatorio
                          deshabilitado={consultando || !accDsctoItem} soloPositivo
                        >{itemVDscto}
                        </CampoNumerico>
                      </InputGroup>
                    </Row>
                    {/* Valor Unitario y Total */}
                    <InputGroup className='' size='sm' style={{ maxWidth: 250 }}>
                      <InputGroup.Text id=''>Valor unit.  :</InputGroup.Text>
                      <CampoNumerico
                        idName='itemVUnit' devuelveVal={recalculaValores}
                        fmtoInput={'' + fmtoBgVUnit}
                        decimales={decimales} obligatorio
                        deshabilitado={consultando} soloPositivo
                      >{itemVUnit}
                      </CampoNumerico>
                    </InputGroup>
                    <InputGroup className='' size='sm' style={{ maxWidth: 250 }}>
                      <InputGroup.Text className='fw-bold' id=''>Valor total:</InputGroup.Text>
                      <CampoNumerico
                        idName='itemVTot' devuelveVal={recalculaValores}
                        fmtoInput='fw-bold' decimales={decMoneda} obligatorio
                        deshabilitado={consultando} soloPositivo={!esNC}
                      >{itemVTot}
                      </CampoNumerico>
                    </InputGroup>
                    {/* Detalle de Item */}
                    <InputGroup className='' size='sm'>
                      <InputGroup.Text id=''>Detalle:</InputGroup.Text>
                      <Form.Control
                        id='' className='' type='text' style={{ fontSize: '10px' }}
                        value={itemDetalle}
                        disabled={consultando}
                        onChange={ctrlChangeDetalle}
                      />
                    </InputGroup>
                  </Container>

                  {/* BOTONES DETALLE */}
                  <TransaccDetBotones
                    esNuevoItem={esNuevoItem}
                    devuelveClickLimpiaItem={ctrlClickLimpiaItem}
                    devuelveClickLimpiaVal={ctrlClickLimpiaVal}
                    devuelveClickNuevoReg={ctrlClickNuevoReg}
                    devuelveClickEliminaReg={ctrlClickEliminaReg}
                    devuelveClickDatosItem={ctrlClickDatosItem}
                  />
                </Form>
              </Col>
              {/* LADO DERECHO. Tabla de detalles del Documento */}
              <Col
                className='border border-primary-subtle border-start-0 border-end-0 border-bottom-0 p-0'
                id='tablaLista'
                xs={8} xl={9}
                style={{ zIndex: '1' }}
              ><TransaccDetList
                registros={datDetalles} datosAPI={datosAPI}
                devuelveClickLista={ctrlClickTablaItems}
               />
              </Col>
            </Row>
          </>
  )
}
export default TransaccDet
