import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import useAdmin from '../context/Admin.jsx'

// import { getParamsEmp } from '../utils/bd.js'
import verificaAuth from '../utils/verificaAuth.js'
import encryptStorage from '../utils/encryptStorage.js'

// Ya no hace falta porque ahora esta en el Reducer de useAdmin
// export function useGetParamsEmp () {
//   const [stdAdmin] = useAdmin()

//   const [params, setParams] = useState('')
//   useEffect(() => {
//     async function getParams () {
//       const paramsEmp = await getParamsEmp(stdAdmin.orclHost, stdAdmin.orclUsuario, stdAdmin.noEmpresa)
//       if (paramsEmp.status === 200) setParams(paramsEmp.datos[0])
//       // Es mejor devolver un string vacio cuando hay error, para simplificar el if() respectivo
//       else setParams('')
//     }
//     getParams()
//   }, [stdAdmin.orclHost, stdAdmin.orclUsuario, stdAdmin.noEmpresa])
//   return params
// }

export function useVerificaToken () {
  const [stdAdmin, dispatchAdmin, accionAdminTipo] = useAdmin()

  const navigate = useNavigate()

  useEffect(() => {
    async function verifica () {
      try {
        const resVerifAuth = await verificaAuth(stdAdmin)
        // Maneja el error de Verificación de la Autenticación
        if (resVerifAuth.ok) {
          const adminStorage = encryptStorage.getItem('admin')
          // Token válido pero sin autorización (hay que reasignar)
          if (resVerifAuth.opcion === 2) {
            Swal.fire(
              'LA AUTORIZACION DE SU ULTIMA SESION SE RECUPERO CON EXITO',
                            `Usuario reconocido: ${resVerifAuth.msj.nombre}`,
                            'info'
            )
            // Actualiza la autorización (admin) para los controles
            dispatchAdmin({ tipo: accionAdminTipo.login, nEstado: { ...adminStorage, auth: true } })
          }
          // Token inválido o con error
        } else {
          switch (resVerifAuth.opcion) {
            // Hubo error
            case 0:
              // console.log(resVerifAuth.msj, typeof resVerifAuth.msj)
              Swal.fire(
                'ERROR AL TRATAR DE VERIFICAR LOS DATOS DEL USUARIO',
                resVerifAuth.msj.TypeError,
                'error'
              )
              dispatchAdmin({ tipo: accionAdminTipo.logout })
              navigate('/login')
              break
              // El token expiró
            case 3:
              Swal.fire(
                'EL TIEMPO DE SU SESION EXPIRO',
                'Para ingresar nuevamente al sistema, debe digitar su usuario y contraseña',
                'info'
              )
              dispatchAdmin({ tipo: accionAdminTipo.logout })
              navigate('/login')
              break
            // redireccionar a Login sin mensaje
            case 4:
              navigate('/login')
              break
            default:
              navigate('/login')
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    verifica()
  }, [])
  // No van dependencias en este useEffect porque se debe ejecutar una sola vez al cargar la pantalla
}
