/* eslint-disable react/jsx-indent */
import { Row } from 'react-bootstrap'
import { gcFmtosColorFondo } from '../../../utils/generales'

function BarraInfo ({ mensaje }) {
  const fmtoRow = 'ms-1 me-1 mb-1 ps-1 pe-1 justify-content-center rounded border border-primary ' + gcFmtosColorFondo.msjeInfo

  return (mensaje
    ? <Row
        className={fmtoRow}
        style={{ fontSize: '11px' }}
      >{mensaje}
      </Row>
    : null
  )
}

export default BarraInfo
