import { Card } from 'react-bootstrap'
import useTransacc from '../../../context/Transacc'

function Encabezado ({ noDocMod, sinEncabezado, esHeader }) {
  const [stdTransacc, , , tiposTarea] = useTransacc()

  const fmto = stdTransacc.tarea === tiposTarea.consultando
    ? 'h5 bg-dark-subtle text-center fw-bold p-0'
    : stdTransacc.tarea === tiposTarea.modificando
      ? 'h5 bg-success-subtle text-center fw-bold p-0'
      : 'h5 bg-secondary-subtle text-center fw-bold p-0'
  const noDocModValido = noDocMod === undefined || noDocMod === null ? '' : noDocMod
  const modNC = noDocModValido.substring(0, 1) === 'B'
  const texto = stdTransacc.tarea === tiposTarea.consultando
    ? 'FACTURAS EXISTENTES'
    : stdTransacc.tarea === tiposTarea.modificando
      ? `MODIFICANDO ${(modNC ? 'LA NOTA DE CREDITO #' : 'LA FACTURA #') + noDocModValido.substring(1)}`
      : stdTransacc.tarea === tiposTarea.filtrando
        ? 'FILTRANDO FACTURAS EXISTENTES'
        : 'FACTURAS NUEVAS'

  if (sinEncabezado) return null
  return (
    <>
      {esHeader
        ? <Card.Header className={fmto}>{texto}</Card.Header>
        : <Card.Footer className={fmto}>{texto}</Card.Footer>}
    </>
  )
}

export default Encabezado
