import { Link, useNavigate } from 'react-router-dom'

import { Dropdown, SplitButton } from 'react-bootstrap'

import restAPIAxios from '../../utils/axios'
import encryptStorage from '../../utils/encryptStorage'

import useAdmin from '../../context/Admin'
import useTransacc from '../../context/Transacc'

import { gcTiposDoc } from '../../utils/generales'

const MnuProformas = () => {
  const [stdAdmin, dispatchAdmin, accionAdminTipo] = useAdmin()
  const [stdTransacc, dispatchTransacc, tiposAccionTransacc, tiposTarea] = useTransacc()
  const navigate = useNavigate()

  const userEmpConfig = encryptStorage.getItem('config')
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (!userVSAM) return null
  // Asigna si tiene acceso a todo el botón (menú Facturación y opción Ventas)
  const accMnuProf = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_FACTURACION) === -1
  const accMnuVtas = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_VENTAS) === -1
  const accCejaProf = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_CEJA) === -1
  const accModificar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_MOD) === -1

  const ctrlClickNuevas = async (e) => {
    let ok = true
    // Para que se ejecute una sola vez al presionar la opción de menú
    if (!stdAdmin.cargoDatosHoy[gcTiposDoc.prof]) {
      // Actualiza las tablas temporales con datos actuales (de hoy)
      async function temporalesHoy () {
        const respuestaAPI = await restAPIAxios('ERROR AL ACTUALIZAR REGISTROS TEMPORALES',
          {
            method: 'patch',
            url: '/proformas/nuevas/setDatosHoy',
            data: {
              poolName: stdAdmin.orclHost,
              orclUserVSAM: stdAdmin.orclUsuario,
              noUser: stdAdmin.noUsuario,
              noEmp: stdAdmin.noEmpresa,
              noProfMax: userEmpConfig.numeros_maximos.proforma
            }
          }
          , false)
        return (respuestaAPI.status === 200)
      }
      ok = await temporalesHoy()
      if (ok) {
        dispatchAdmin({
          tipo: accionAdminTipo.setCargoDatosHoy,
          cargoDatosHoy: { ...stdAdmin.cargoDatosHoy, [gcTiposDoc.prof]: true }
        })
      }
    }
    if (ok) {
      // Por si acaso este modificando una Proforma o ya este en CREANDO
      // Se conservan los mismos regsCab e index en caso de que ya este creando
      dispatchTransacc({
        doc: gcTiposDoc.prof,
        tipo: tiposAccionTransacc.cambiaTarea,
        nuevaTarea: tiposTarea.creando,
        regsCab: stdTransacc.doc === gcTiposDoc.prof && stdTransacc.tarea === tiposTarea.creando
          ? stdTransacc.regsCab
          : undefined,
        index: stdTransacc.doc === gcTiposDoc.prof && stdTransacc.tarea === tiposTarea.creando
          ? stdTransacc.index
          : undefined
      })
      navigate('/proformas/nuevas')
    }
  }

  function ctrlClickExistente (e) {
    // Por si acaso ya este en FILTRANDO
    // Se conservan los mismos regsCab e index en caso de no cambiar la tarea
    dispatchTransacc({
      doc: gcTiposDoc.prof,
      tipo: tiposAccionTransacc.cambiaTarea,
      nuevaTarea: tiposTarea.filtrando,
      regsCab: stdTransacc.tarea === tiposTarea.filtrando
        ? stdTransacc.regsCab
        : undefined,
      index: stdTransacc.tarea === tiposTarea.filtrando
        ? stdTransacc.index
        : undefined
    })
    navigate('/proformas/existentes')
  }

  function ctrlClickModificando (e) {
    // Por si acaso ya este en MODIFICANDO
    // Se conservan los mismos regsCab e index en caso de no cambiar la tarea
    dispatchTransacc({
      doc: gcTiposDoc.prof,
      tipo: tiposAccionTransacc.cambiaTarea,
      nuevaTarea: tiposTarea.modificando,
      regsCab: stdTransacc.tarea === tiposTarea.modificando
        ? stdTransacc.regsCab
        : undefined,
      index: stdTransacc.tarea === tiposTarea.modificando
        ? stdTransacc.index
        : undefined
    })
    navigate('/proformas/nuevas')
  }
  const oculto = stdAdmin.menuSinAcc && stdAdmin.menuSinAcc.proforma
  return (
    <SplitButton
      className='bg-secondary-subtle me-3'
      size='sm'
      variant='outline-secondary text-black'
      title='Proformas'
      disabled={!accMnuProf}
      hidden={oculto}
      onClick={ctrlClickNuevas}
    >
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas || !accCejaProf}
      >
        <Link
          className={'dropdown-item' + ((accMnuVtas && accCejaProf) ? '' : ' disabled')}
          to='/proformas/nuevas' onClick={ctrlClickNuevas}
        >Nuevas
        </Link>
      </Dropdown.Item>
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas || !accCejaProf}
      >
        <Link
          className={'dropdown-item' + (accMnuVtas && accCejaProf ? '' : ' disabled')}
          to='/proformas/existentes' onClick={ctrlClickExistente}
        >Existentes
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas || !accModificar}
      >
        <Link
          className={'dropdown-item' + (accMnuVtas && accModificar ? '' : ' disabled')}
          to='/proformas/nuevas' onClick={ctrlClickModificando}
        >Modificando
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        className='p-0'
        size='sm'
      >
        <Link
          className='dropdown-item'
          to='/proformas/reporte'
        >Reporte
        </Link>
      </Dropdown.Item>
    </SplitButton>
  )
}

export default MnuProformas
