import React, { useContext, useReducer } from 'react'
import encryptStorage from '../utils/encryptStorage'
import { gcTiposDoc } from '../utils/generales'

const Admin = React.createContext()

function useAdmin () {
  return useContext(Admin)
}
// ESTADO INICIAL
const estadoIni = {
  token: '',
  userNombre: '** Sin Usuario **',
  noUsuario: 0,
  orclHost: '',
  orclUsuario: '',
  noEmpresa: 0,
  emailConfig: { auth: { pass: '', user: '' }, secure: true, service: 'gmail' },
  menuSinAcc: {},
  cargoDatosHoy: { [gcTiposDoc.prof]: false, [gcTiposDoc.fact]: false },
  paramsEmp: {},
  auth: false
}
// TIPOS DE ACCION
const tiposAccion = {
  logout: 'LOGOUT',
  login: 'LOGIN',
  asignaEmp: 'ASIGNAR EMPRESA',
  setCargoDatosHoy: 'ASIGNA BANDERA DATOS HOY'
}
// REDUCER
function reducer (estado, accion) {
  switch (accion.tipo) {
    case tiposAccion.logout: {
      encryptStorage.clear()
      return estadoIni
    }
    case tiposAccion.login: {
      const adminSinAuth = { ...accion.nEstado }
      delete adminSinAuth.auth
      encryptStorage.setItem('admin', adminSinAuth)
      return accion.nEstado
    }
    case tiposAccion.asignaEmp: {
      const nEstado = { ...estado, noEmpresa: accion.noEmpresa, paramsEmp: accion.regEmpresa }
      const adminSinAuth = { ...nEstado }
      delete adminSinAuth.auth
      encryptStorage.setItem('admin', adminSinAuth)
      return nEstado
    }
    case tiposAccion.setCargoDatosHoy: {
      const nEstado = { ...estado, cargoDatosHoy: accion.cargoDatosHoy }
      const adminSinAuth = { ...nEstado }
      delete adminSinAuth.auth
      encryptStorage.setItem('admin', adminSinAuth)
      return nEstado
    }
    default: return estado
  }
}
// COMPONENTE PROVIDER
export const AdminProvider = ({ children }) => {
  // definir el state inicial
  let sesionIni
  if (encryptStorage.getItem('admin')) {
    const {
      token, userNombre, noUsuario, orclHost, orclUsuario, noEmpresa,
      emailConfig, menuSinAcc, cargoDatosHoy, paramsEmp
    } = encryptStorage.getItem('admin')
    sesionIni = {
      token,
      userNombre,
      noUsuario,
      orclHost,
      orclUsuario,
      noEmpresa,
      emailConfig,
      menuSinAcc,
      cargoDatosHoy,
      paramsEmp,
      auth: true
    }
  // No hay ningún elemento en el sessionStorage
  } else {
    sesionIni = estadoIni
    // Para recuperar los datos de la última sesión en caso de haber usado
    // navigate(...) u otra forma de ir directamente a una página específica
  }
  const [estado, dispatch] = useReducer(reducer, sesionIni)

  return (
    <Admin.Provider value={[estado, dispatch, tiposAccion]}>
      {children}
    </Admin.Provider>
  )
}

export default useAdmin
