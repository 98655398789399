/* eslint-disable react/jsx-indent */
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap'

import useAdmin from '../../../context/Admin'
import useTransacc from '../../../context/Transacc'
import restAPIAxios, { restAPIMySQL } from '../../../utils/axios'
import encryptStorage from '../../../utils/encryptStorage.js'

import Encabezado from './ProfEncabezado'
import EmpSel from '../../admin/EmpSel'
import Clientes from '../clientes/Clientes'
import Botones from './ProfBotones'
import TablaOpcion from '../../generales/tablas/TablaOpcion.jsx'
import TablaCampo from '../../generales/tablas/TablaCampo.jsx'
import TablaCampoFecha from '../../generales/tablas/TablaCampoFecha.jsx'
import TransaccDet from '../../generales/transaccDet/TransaccDet.jsx'
import ItemFiltro from '../../productos/items/ItemFiltro.jsx'
import ListaExistentes from './ProfListaExistentes'
import BarraAuditoria from '../../generales/barras/BarraAuditoria'
import BarraInfo from '../../generales/barras/BarraInfo'

import { msjInfoCli, existeNoProf, _sqlItemFiltroWhere } from '../../../utils/bd'

import {
  infoPtoVtaSel, infoVendedorSel, setInfoCampoProforma, gcTablasLogicas,
  rutaReps, gcModulos, gcTiposDoc, colocaFocoDirecto, gcFmtosColorFondo
} from '../../../utils/generales'

import { useVerificaToken } from '../../../hooks/admin'
// import { useForzaFiltroEnRefresh } from '../../../hooks/generales'

function Proformas ({ existentes }) {
  const [stdAdmin] = useAdmin()
  const [stdTransacc, dispatchTransacc, tiposAccionTransacc, tiposTarea] = useTransacc()
  const navigate = useNavigate()

  const [datDetalles, setDatDetalles] = useState([])
  const [whereExistentes, setWhereExistentes] = useState('')
  const [ordenExistentes, setOrdenExist] = useState('NO_PROFORMA')
  const [datCliente, setDatCliente] = useState({})

  const [grabando, setGrabando] = useState({ enProceso: false, msjPantalla: '' })
  // const [cargando, setCargando] = useState(false)
  const [editando, setEditando] = useState(false)
  const [recargar, setRecargar] = useState(false) // Bandera para recargar la cabecera
  const [fmtoWarningNoProf, setFmtoWarningNoProf] = useState(false)

  const datExistentes = useRef([])

  // VERIFICA TOKEN
  // ** Debe ir antes de cualquier uso de useAdmin porque aqui se puede recargar los valores desde el local storage
  useVerificaToken()

  const poolName = stdAdmin.orclHost
  const creando = stdTransacc.tarea === tiposTarea.creando
  const modificando = stdTransacc.tarea === tiposTarea.modificando
  const consultando = (stdTransacc.tarea === tiposTarea.consultando)
  const filtrando = stdTransacc.tarea === tiposTarea.filtrando
  const listando = stdTransacc.listando
  const hayNoCliente = stdTransacc.otrosDat.cliente && stdTransacc.otrosDat.cliente[0]
  // Extrae los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')

  // Controla el nivel de acceso al menú de Proformas
  let accMnuProf = false
  let accMnuVtas = false
  let accCejaProf = false
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (userVSAM) {
    // Asigna si tiene acceso a todo el botón (menú Facturación y opción Ventas)
    accMnuProf = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_FACTURACION) === -1
    accMnuVtas = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_VENTAS) === -1
    accCejaProf = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_CEJA) === -1
  }
  // Carga los parametros de la empresa
  const paramsEmp = stdAdmin.paramsEmp

  // Solo para pasar al Filtrando cuando se refresca la pantalla en /proformas/existentes
  // ya que al refrescarla directamente desde windows se queda cargando datos indefinidamente
  // porque al refrescar la pantalla se pierde datProf. Por lo tanto hay que volver al filtrado de datos
  // useForzaFiltroEnRefresh({ tipoDoc: gcTiposDoc.prof, setListando })

  // Carga las Proformas NUEVAS (del usuario y la empresa respectivos)
  // o Carga Cabecera Proformas vacio para filtro
  useEffect(() => {
    const noProfMax = userEmpConfig.numeros_maximos ? userEmpConfig.numeros_maximos.proforma : undefined
    setFmtoWarningNoProf(false)
    console.log('🚀 ~ file: Proformas.jsx ~ useEffect:', 'Cargando Proformas...', noProfMax, modificando, datExistentes.current, stdTransacc, stdAdmin)
    async function cargaProfNuevas () {
      const respuestaAPI = await restAPIAxios(`NO SE PUDO CARGAR
                ${modificando ? ' LA PROFORMA PARA SU MODIFICACION' : ' LAS PROFORMAS NUEVAS'}`,
      {
        method: 'get',
        url: `/proformas/nuevas/${poolName}/${stdAdmin.orclUsuario}/${stdAdmin.noUsuario}/${stdAdmin.noEmpresa}/${modificando ? 'SI' : 'NO'}/${noProfMax}`
      })
      if (respuestaAPI.status === 200) {
        if (respuestaAPI.data.length > 0) {
          let indexProf = 0
          if (modificando) {
            // Se coloca en la proforma que desea modificar (por si acaso haya mas registros para modificar)
            indexProf = respuestaAPI.data.findIndex(registro => {
              return (registro.NO_REG === (stdTransacc.regsCab.length ? stdTransacc.regsCab[0].NO_REG : 0))
            })
            if (indexProf === -1) indexProf = 0
            dispatchTransacc({
              doc: gcTiposDoc.prof,
              tipo: tiposAccionTransacc.cargaRegs,
              tarea: tiposTarea.modificando,
              regsCab: respuestaAPI.data,
              index: indexProf
            })
          } else {
            if (creando) {
              // Para conservar el index del registro creado que se tiene actualmente en el estado
              indexProf = respuestaAPI.data.findIndex(registro => {
                return (registro.NO_REG === stdTransacc.clave)
              })
            } else {
              // Verifica que el índice de la transacción sea válido
              indexProf = respuestaAPI.data.length - 1 < stdTransacc.index ? 0 : stdTransacc.index
            }
            if (indexProf === -1) indexProf = 0
            dispatchTransacc({
              doc: gcTiposDoc.prof,
              tipo: tiposAccionTransacc.cargaRegs,
              tarea: tiposTarea.creando,
              regsCab: respuestaAPI.data,
              index: indexProf
            })
          }
          // Para asignar el formato warning del NO_PROFORMA inicial
          existeProf(respuestaAPI.data[indexProf].NO_PROFORMA, respuestaAPI.data[indexProf].NO_PROFORMA_MOD)
        // En caso de que la BD todavía no haya creado el registro
        } else {
          dispatchTransacc({
            doc: gcTiposDoc.prof,
            tipo: tiposAccionTransacc.cambiaTarea,
            nuevaTarea: tiposTarea.filtrando
          })
          Swal.fire(
            'NO EXISTEN PROFORMAS EN PROCESO DE MODIFICACION',
            'Seleccione una proforma para su modificación',
            'info'
          )
          navigate('/proformas/existentes')
        }
      // En caso de que la API devuelva error
      } else {
        navigate('/')
      }
    }

    function setBuscarExistentes () {
      if (datExistentes.current.length > 0) {
        dispatchTransacc({
          doc: gcTiposDoc.prof,
          tipo: tiposAccionTransacc.cargaRegs,
          tarea: tiposTarea.consultando,
          regsCab: datExistentes.current
        })
      } else {
        const regVacio = {
          CLI_NOMBRE: '',
          NO_PTOVENTA: null,
          NO_PROFORMA: '',
          NO_CLIENTE: '',
          NO_VENDEDOR: '',
          FECHA: '',
          VALIDEZ_OFERTA: '',
          TIEMPO_ENTREGA: '',
          CONDICION_OFERTA: '',
          ATENCION_PERSONA: '',
          OBS: '',
          NO_PROFORMA_MOD: 0
        }
        dispatchTransacc({
          doc: gcTiposDoc.prof,
          tipo: tiposAccionTransacc.cargaRegs,
          tarea: tiposTarea.filtrando,
          regsCab: [regVacio]
        })
      }
    }

    // Si no tiene acceso a la opción de menú Proformas
    if (!accMnuProf || !accMnuVtas || !accCejaProf) {
      Swal.fire(
        'NO TIENE ACCESO A PROFORMAR',
        'Intente acceder a otras páginas utilizando las opciones de menú disponibles',
        'info'
      )
      navigate('/bienvenida')
    } else if (existentes) setBuscarExistentes()
    else cargaProfNuevas()
  }, [recargar, poolName, stdAdmin.noEmpresa, stdAdmin.noUsuario, stdAdmin.orclUsuario,
    stdTransacc.tarea])

  // Solamente para colocar el el foco del cursor en el campo stdTransacc.idFoco
  useEffect(() => {
    if (stdTransacc.idFoco) {
      colocaFocoDirecto(stdTransacc.idFoco)
    }
  }, [stdTransacc.idFoco])

  // FUNCIONES PRIVADAS DEL COMPONENTE
  async function profValida () {
    const fCerradoHasta = paramsEmp.F_CERRADOHASTA === null ? '' : paramsEmp.F_CERRADOHASTA.substring(0, 10)
    if (fCerradoHasta >= stdTransacc.regsCab[stdTransacc.index].FECHA.substring(0, 10)) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} PROFORMAS ANTERIORES A ` +
            paramsEmp.F_CERRADOHASTA.substring(0, 10),
        'Cambie la fecha actual por una superior a la indicada',
        'info'
      )
      return false
    }
    // Verifica que EXISTAN ITEMS
    if (datDetalles.length === 0) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} PROFORMAS SIN ITEMS`,
        'Cree por lo menos un item en la proforma antes de grabarla',
        'info'
      )
      return false
    }
    if (modificando) {
      // Capturo los datos de la proforma que se va a modificar para realizar varias validaciones
      const regProfMod = await existeNoProf(
        stdTransacc.regsCab[stdTransacc.index].NO_PROFORMA_MOD,
        poolName, stdAdmin.orclUsuario, stdAdmin.noEmpresa
      )
      // Verifica que exista la proforma
      if (!Object.keys(regProfMod.registro).length) {
        Swal.fire({
          title: `LA PROFORMA No. ${stdTransacc.regsCab[stdTransacc.index].NO_PROFORMA_MOD} QUE DESEA MODIFICAR YA NO EXISTE EN LA BASE DE DATOS`,
          text: 'El sistema cancelará automáticamente esta modificación',
          icon: 'info'
        }).then((result) => {
          if (result.value) clickCancelar(true)
        })
        return false
      }
      // Verifica que la proforma no haya sido anulada
      if (regProfMod.registro.ESTADO === 'A') {
        Swal.fire(
          `LA PROFORMA No. ${stdTransacc.regsCab[stdTransacc.index].NO_PROFORMA_MOD} QUE DESEA MODIFICAR YA ESTA ANULADA`,
          'El sistema cancelará automáticamente esta modificación',
          'info'
        ).then((result) => {
          if (result.value) clickCancelar(true)
        })
        return false
      }
    }
    return true
  }
  async function cargaProfExistentes (where, orden, estaOrdenando) {
    setGrabando({ enProceso: true, msjPantalla: 'Cargando datos...' })
    const noProfTmp = stdTransacc.regsCab[stdTransacc.index].NO_PROFORMA
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CARGAR LAS PROFORMAS',
      {
        method: 'post',
        url: '/proformas/existentes',
        data: { poolName, orclUserVSAM: stdAdmin.orclUsuario, noEmp: stdAdmin.noEmpresa, where, orden }
      }
    )
    if (respuestaAPI.status === 200) {
      // No existen Proformas con esas condiciones.  Se queda en buscar
      if (respuestaAPI.data.length === 0) {
        Swal.fire(
          'NO EXISTEN PROFORMAS CON ESAS CONDICIONES',
          'Cambie las condiciones e intente nuevamente',
          'info'
        )
      } else {
        datExistentes.current = respuestaAPI.data

        // Verifica que el índice de sesión sea válido
        const indexProfTmp = respuestaAPI.data.findIndex((reg) => reg.NO_PROFORMA === noProfTmp)
        const indexProf = estaOrdenando ? (indexProfTmp === -1 ? 0 : indexProfTmp) : 0
        dispatchTransacc({
          doc: gcTiposDoc.prof,
          tipo: tiposAccionTransacc.cargaRegs,
          tarea: tiposTarea.consultando,
          regsCab: respuestaAPI.data,
          index: indexProf
        })
      }
    }
    setGrabando({ enProceso: false, msjPantalla: '' })
  }
  // Pinta con amarillo el No. de Proforma si no existe en la BD
  async function existeProf (noProf, noProfMod) {
    const res = await existeNoProf(noProf, poolName, stdAdmin.orclUsuario, stdAdmin.noEmpresa)
    // Coloca el color warning si existe el nuevo NO_PROFORMA al crear
    // o si existe y es diferente al original cuando esta modificando
    if (creando || (modificando && noProf !== noProfMod)) {
      setFmtoWarningNoProf(res.existe)
    } else setFmtoWarningNoProf(false)
  }

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  function ctrlClickConsultar (e) {
    if (e !== undefined) e.preventDefault()
    // Crea el WHERE con las condiciones establecidas por el usuario
    function condicion (datos) {
      const noProforma = datos.NO_PROFORMA.toString()
      let where = ''
      if (regActual.NO_PTOVENTA) where = ' and A.NO_PTOVENTA = ' + regActual.NO_PTOVENTA
      if (noProforma) {
        const pos = noProforma.toString().indexOf('-')
        if (pos === 0) {
          where = where + ` and A.NO_PROFORMA <= ${noProforma.substring(pos + 1)}`
        } else if (pos === (noProforma.length - 1)) {
          where = where + ` and A.NO_PROFORMA >= ${noProforma.substring(0, pos)}`
        } else if (pos > 0) {
          noProforma.substring(pos).length > 1
            ? where = where + ` and A.NO_PROFORMA >= ${noProforma.substring(0, pos)}` +
                ` and A.NO_PROFORMA <= ${noProforma.substring(pos + 1)}`
            : where = where + ` and A.NO_PROFORMA >= ${noProforma.substring(0, pos)}`
        } else where = where + ` and A.NO_PROFORMA = ${noProforma}`
      }
      if (datos.CLI_NOMBRE) {
        where = where + ` and B.NOMBRE like '${datos.CLI_NOMBRE.replaceAll('*', '%')}%'`
      }
      if (datos.NO_VENDEDOR) {
        where = where + ` and A.NO_VENDEDOR = ${datos.NO_VENDEDOR}`
      }
      if (datos.FECHA) {
        where = where + ` and to_char(A.FECHA, 'yyyy-mm-dd') ${stdTransacc.fechaCond[0]} '${datos.FECHA}'`
      }
      if (datos.VALIDEZ_OFERTA) {
        where = where + ` and A.VALIDEZ_OFERTA = ${datos.VALIDEZ_OFERTA}`
      }
      if (datos.TIEMPO_ENTREGA) {
        if (datos.TIEMPO_ENTREGA === '*') {
          where = where + ' and not A.TIEMPO_ENTREGA is null'
          where = where + " and replace(replace(A.TIEMPO_ENTREGA, chr(13), '|'), chr(10), '|') <> '||'"
        } else {
          where = where + ` and A.TIEMPO_ENTREGA like '${datos.TIEMPO_ENTREGA.replaceAll('*', '%')}%'`
        }
      }
      if (datos.CONDICION_OFERTA) {
        if (datos.CONDICION_OFERTA === '*') {
          where = where + ' and not A.CONDICION_OFERTA is null'
          where = where + " and replace(replace(A.CONDICION_OFERTA, chr(13), '|'), chr(10), '|') <> '||'"
        } else {
          where = where + ` and A.CONDICION_OFERTA like '${datos.CONDICION_OFERTA.replaceAll('*', '%')}%'`
        }
      }
      if (datos.ATENCION_PERSONA) {
        if (datos.ATENCION_PERSONA === '*') {
          where = where + ' and not A.ATENCION_PERSONA is null'
        } else {
          where = where + ` and A.ATENCION_PERSONA like '${datos.ATENCION_PERSONA.replaceAll('*', '%')}%'`
        }
      }
      if (datos.OBS) {
        if (datos.OBS === '*') {
          where = where + ' and not A.OBS is null'
          where = where + " and replace(replace(A.OBS, chr(13), '|'), chr(10), '|') <> '||'"
        } else {
          where = where + ` and A.OBS like '${datos.OBS.replaceAll('*', '%')}%'`
        }
      }
      return where
    }

    const whereCab = condicion(stdTransacc.regsCab[0])
    let where = whereCab
    // Asigna el where de items unico
    if (Object.keys(stdTransacc.itemFiltro.registro).length) {
      where = where + `and exists
        (select AB.NO_ITEM from ${stdAdmin.orclUsuario}.T_DET_PROFORMA AB
         where AB.NO_INSTIT_SIST = A.NO_INSTIT_SIST and AB.NO_PROFORMA = A.NO_PROFORMA
          and AB.NO_ITEM = ${stdTransacc.itemFiltro.registro.KEY_TAB})`
    // Asigna el where de items utilizando el filtro
    } else if (stdTransacc.itemFiltro.filtro) {
      where = where + `and exists
        (select AB.NO_ITEM from ${stdAdmin.orclUsuario}.T_DET_PROFORMA AB
          inner join ${stdAdmin.orclUsuario}.V_ITEMS_L_GRUPOS AC on AB.NO_ITEM = AC.NO_ITEM
         where AB.NO_INSTIT_SIST = A.NO_INSTIT_SIST and AB.NO_PROFORMA = A.NO_PROFORMA
          ${_sqlItemFiltroWhere(stdTransacc.itemFiltro.filtro,
            'AC.' + stdTransacc.itemFiltro.campoOrden).replaceAll('A.', 'AC.')})`
    }
    setWhereExistentes(where)
    cargaProfExistentes(where, ordenExistentes, false)
  }

  // FUNCIONES DE CONTROL (EVENTOS) DEVUELTOS POR LOS COMPONENTE
  function ctrlCambioEmp () {
    ctrlNuevaProf([])
  }
  function clickCancelar (sinMsjeConfirma) {
    async function cancela () {
      setGrabando({ enProceso: true, msjPantalla: 'Cancelando...' })
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE
        ${modificando ? ' CANCELAR LA MODIFICACION' : ' ELIMINAR LOS REGISTROS'}`,
      {
        method: 'post',
        url: `${modificando ? '/proformas/cancelaMod' : '/proformas/nueva/cancela'}`,
        data: modificando
          ? {
              poolName,
              orclUserVSAM: stdAdmin.orclUsuario,
              noReg: stdTransacc.clave,
              noProforma: regActual.NO_PROFORMA_MOD
            }
          : {
              poolName,
              orclUserVSAM: stdAdmin.orclUsuario,
              noEmp: stdAdmin.noEmpresa,
              noReg: stdTransacc.clave
            }
      })
      setGrabando({ enProceso: false, msjPantalla: '' })
      if (respuestaAPI.status === 200) {
        setRecargar(!recargar)
      }
    }
    if (sinMsjeConfirma) {
      cancela()
    } else {
      Swal.fire({
        title: 'CONFIRME',
        text: modificando
          ? 'Desea cancelar el proceso de modificación de la proforma?'
          : 'Desea eliminar todos los registros de la proforma?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO'
      }).then((result) => {
        if (result.isConfirmed) cancela()
      })
    }
  }

  async function clickAnterior () {
    if (consultando) return
    const noProf = stdTransacc.regsCab[stdTransacc.index - 1].NO_PROFORMA
    const noProfMod = stdTransacc.regsCab[stdTransacc.index - 1].NO_PROFORMA_MOD
    await existeProf(noProf, noProfMod)
  }
  async function click10Ant () {
    if (consultando) return
    const noProf = stdTransacc.regsCab[stdTransacc.index - 10].NO_PROFORMA
    const noProfMod = stdTransacc.regsCab[stdTransacc.index - 10].NO_PROFORMA_MOD
    await existeProf(noProf, noProfMod)
  }
  async function clickPrimera () {
    if (consultando) return
    const noProf = stdTransacc.regsCab[0].NO_PROFORMA
    const noProfMod = stdTransacc.regsCab[0].NO_PROFORMA_MOD
    await existeProf(noProf, noProfMod)
  }

  async function clickSiguiente () {
    if (consultando) return
    const noProf = stdTransacc.regsCab[stdTransacc.index + 1].NO_PROFORMA
    const noProfMod = stdTransacc.regsCab[stdTransacc.index + 1].NO_PROFORMA_MOD
    await existeProf(noProf, noProfMod)
  }
  async function click10Sig () {
    if (consultando) return
    const noProf = stdTransacc.regsCab[stdTransacc.index + 10].NO_PROFORMA
    const noProfMod = stdTransacc.regsCab[stdTransacc.index + 10].NO_PROFORMA_MOD
    await existeProf(noProf, noProfMod)
  }
  async function clickUltima () {
    if (consultando) return
    const noProf = stdTransacc.regsCab[stdTransacc.regsCab.length - 1].NO_PROFORMA
    const noProfMod = stdTransacc.regsCab[stdTransacc.regsCab.length - 1].NO_PROFORMA_MOD
    await existeProf(noProf, noProfMod)
  }

  function clickListado (index) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      index,
      listando: false
    })
  }
  function ctrlNuevoFiltroLista () {
    datExistentes.current = []
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaTarea,
      nuevaTarea: tiposTarea.filtrando
    })
    navigate('/proformas/existentes')
  }
  function dblClickOrdenar (campo) {
    if (consultando) {
      const orden = campo === undefined ? ordenExistentes : campo
      setOrdenExist(orden)
      cargaProfExistentes(whereExistentes, orden, true)
    }
  }
  function ctrlNuevoFiltroBotones () {
    // Solamente debe limpiar los datos existentes cuando ya este consultando y requiera
    // un nuevo filtro al último capturado
    if (stdTransacc.tarea === tiposTarea.consultando) {
      datExistentes.current = []
    }
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaTarea,
      nuevaTarea: tiposTarea.filtrando
    })
    navigate('/proformas/existentes')
  }
  function ctrlNuevaProf (registro) {
    datExistentes.current = registro
    // Para que refresque los nuevos campos y detalles, luego de crear o modificar proformas
    setRecargar(!recargar)
  }
  function ctrlSetNoProf (valor) {
    stdTransacc.regsCab[stdTransacc.index].NO_PROFORMA = (!filtrando && valor === '' ? null : valor)
    if (filtrando) return
    existeProf(valor, stdTransacc.regsCab[stdTransacc.index].NO_PROFORMA_MOD)
  }
  async function clickRepIndividual (formato, conEmail) {
    if (conEmail && !regActual.CLI_EMAIL) {
      Swal.fire(
        'EL CLIENTE NO TIENE NINGUN CORREO ELECTRONICO ASIGNADO PARA EL ENVIO DE LA PROFORMA',
        'La proforma no puede ser enviada por correo electrónico',
        'info'
      )
      return
    }
    if (conEmail) setGrabando({ enProceso: true, msjPantalla: 'Enviando el correo electrónico...' })

    let url = '/proformas/reportes/individual'
    if (formato === 'INTERNO') url = '/proformas/reportes/interno'

    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CREAR EL REPORTE SOLICITADO DE LA PROFORMA',
      {
        method: 'post',
        url,
        data: {
          cabeceras: stdTransacc.regsCab[stdTransacc.index],
          detalles: datDetalles,
          rutaCarpetas: userEmpConfig.ruta_carpetas,
          saveFile: conEmail
        },
        responseType: 'blob'
      }
    )
    if (respuestaAPI.status === 200) {
      // Envía el Email con el attach del PDF
      if (conEmail) {
        let fileName = 'proforma.pdf'
        if (respuestaAPI.headers['content-disposition']) {
          fileName = respuestaAPI.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
        }

        const resAPIEmail = await restAPIMySQL('ERROR AL ENVIAR AL CLIENTE LA PROFORMA POR CORREO ELECTRONICO',
          {
            method: 'post',
            url: '/sendEmail',
            data: {
              emailConfig: stdAdmin.emailConfig,
              datos: {
                from: `${regActual.EMP_NOMBRE} - ${stdAdmin.userNombre}`,
                to: `${regActual.CLI_EMAIL}`,
                subject: `Proforma No. ${regActual.NO_PROFORMA}`,
                text: `Estimado ${regActual.CLI_NOMBRE}`,
                html: `<p style="text-align: center">${regActual.EMP_NOMBRE} le envía adjunto la <b>Proforma No. ${regActual.NO_PROFORMA}</b></p>`
              },
              configAttach: {
                filename: `Proforma No. ${regActual.NO_PROFORMA}.pdf`,
                path: rutaReps(userEmpConfig.ruta_carpetas, gcModulos.vtas) + fileName
              }
            }
          }
        )
        if (resAPIEmail.status === 200) {
          Swal.fire(
            `LA PROFORMA SE ENVIO CON EXITO A "${regActual.CLI_NOMBRE}"`,
            `Email ${regActual.CLI_EMAIL}`,
            'info'
          )
        }
      } else {
        let pdf = new Blob([respuestaAPI.data], { type: 'application/pdf' })
        pdf = window.URL.createObjectURL(pdf)
        window.open(pdf)
      }
      // // Para obtener el nombre de archivo devuelto en las cabeceras
      // let fileName = 'proforma.pdf'
      // if (respuestaAPI.headers['content-disposition']) {
      //   fileName = respuestaAPI.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
      // }
      // console.log('🚀 ~ file: Proformas.jsx:557 ~ clickRepIndividual ~ fileName:', fileName)
      // // Para bajarse el archivo (con el nombre devuelto fileName) automáticamente en Descargas
      // const link = document.createElement('a')
      // link.href = pdf
      // link.setAttribute('download', fileName)
      // document.body.appendChild(link)
      // link.click()
      // document.body.removeChild(link)
    }
    if (conEmail) setGrabando({ enProceso: false, msjPantalla: '' })
  }

  if (!stdAdmin.auth || !stdTransacc.regsCab.length) return null

  const fmtoOrden = consultando ? 'bg-primary text-white' : ''
  const regActual = stdTransacc.regsCab.length
    ? stdTransacc.regsCab[stdTransacc.index]
    : []
  return (
    <Card className='border-light'>
      <Encabezado
        noProfMod={modificando ? regActual.NO_PROFORMA_MOD : ''} sinEncabezado={false}
      />
      {listando
        ? <ListaExistentes
            registros={stdTransacc.regsCab} devuelveClickListado={clickListado}
            devuelveClickNuevoFiltro={ctrlNuevoFiltroLista} orden={ordenExistentes}
          />
        : grabando.enProceso
          ? <div className='mt-5 mb-5 pt-5 pb-5 h3 text-center'>
              <Spinner animation='grow' />{grabando.msjPantalla}
            </div>
          : <Card.Body
              className={'p-0' + (creando ? '' : ' bg-dark-subtle')}
            >
            {/* EMPRESAS Y BOTONES */}
              <Card.Title className=''>
                <Container className='p-0'>
                  <Row>
                    <EmpSel devuelveCambiaEmp={ctrlCambioEmp} esHeader />
                  </Row>
                  <Row>
                    {filtrando
                      ? <Container className=''>
                        <Row className='h6 p-0 m-0 text-secondary justify-content-center bg-dark-subtle'>
                          <span className='text-center p-1'>Si desea establezca valores en los campos (un filtro):&nbsp;&nbsp;</span>
                          <Button
                            className='' size='sm' style={{ width: 100 }}
                            onClick={ctrlClickConsultar}
                          >Consultar
                          </Button>
                        </Row>
                        </Container>
                      : <Botones
                          totalRegs={stdTransacc.regsCab.length}
                          profValida={profValida} editando={editando}
                          estaGrabando={(valor) => setGrabando(valor)}
                          recargarCab={() => setRecargar(!recargar)}
                          devuelveAnterior={clickAnterior}
                          devuelveSiguiente={clickSiguiente}
                          devuelve10Ant={click10Ant}
                          devuelve10Sig={click10Sig}
                          devuelvePrimera={clickPrimera}
                          devuelveUltima={clickUltima}
                          devuelveCancelar={clickCancelar}
                          devuelveNuevoReg={ctrlNuevaProf}
                          devuelveNuevoFiltro={ctrlNuevoFiltroBotones}
                          devuelveRepIndividual={clickRepIndividual}
                        >{regActual.NO_PROFORMA}
                        </Botones>}
                  </Row>
                </Container>
              </Card.Title>
              {/* CABECERA SIN TOTALES */}
              <>
                {/* Datos Informativos */}
                {creando
                  ? <BarraInfo mensaje={msjInfoCli(false, datCliente)} />
                  : null}

                {/* Ptos.Venta, fecha, validez y No. Proforma */}
                <Row className='justify-content-between ms-2 me-2 mb-1' xs={1} sm={3} md={4} lg={4}>
                  {/* NO_PTOVENTA */}
                  <Col className='p-0' style={{ width: 420 }}>
                    <TablaOpcion
                      valTabla={regActual.NO_PTOVENTA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('NO_PTOVENTA', regActual.NO_PTOVENTA)}
                      infoTablaLista={infoPtoVtaSel(['', ordenExistentes === 'PTO_VENTA' ? fmtoOrden : '', ''])}
                      // devuelveDatos={({ nuevoVal, index, descrip, campoAdi }) => {
                      //   dispatchTransacc({
                      //     tipo: tiposAccionTransacc.setOtrosDat,
                      //     otrosDat: { noBodega: nuevoVal }
                      //   })
                      // }}
                      deshabilitado={existentes && !filtrando}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('PTO_VENTA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                  {/* ESTADO */}
                  <Col
                    className='rounded border border-primary-subtle bg-info-subtle text-center fw-bold'
                    style={{ width: 120 }}
                    hidden={!existentes || filtrando || regActual.ESTADO !== 'A'}
                  >ANULADA
                  </Col>
                  {/* FECHA */}
                  <Col className='p-0' xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto'>
                    <TablaCampoFecha
                      infoCampo={
                        {
                          fmto: ['', ordenExistentes === 'FECHA' ? fmtoOrden : '', ''],
                          id: 'fechaProf',
                          label: 'Fecha:',
                          required: true,
                          disabled: (existentes && !filtrando),
                          condFiltro: stdTransacc.fechaCond[0],
                          showHora: existentes && !filtrando
                        }
                      }
                      valTabla={regActual.FECHA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('FECHA', regActual.FECHA)}
                      devuelveVal={(valor) => { stdTransacc.regsCab[stdTransacc.index].FECHA = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('FECHA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                      devuelveCond={(valor) => dispatchTransacc({
                        tipo: tiposAccionTransacc.setFechaCond,
                        fCondIndex: 0,
                        fCondVal: valor
                      })}
                    />
                  </Col>
                  {/* VALIDEZ_OFERTA */}
                  <Col className='p-0' style={{ width: 195 }}>
                    <TablaCampo
                      infoCampo={
                        {
                          fmto: ['', ordenExistentes === 'VALIDEZ_OFERTA' ? fmtoOrden : '', 'text-end'],
                          type: 'number',
                          id: 'validezOferta',
                          label: 'Validez oferta:',
                          txtDespues: 'dias',
                          min: 0,
                          max: null,
                          required: false,
                          disabled: (existentes && !filtrando),
                          ceroEsNulo: true
                        }
                      }
                      valTabla={regActual.VALIDEZ_OFERTA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('VALIDEZ_OFERTA', regActual.VALIDEZ_OFERTA)}
                      devuelveVal={(valor) => { stdTransacc.regsCab[stdTransacc.index].VALIDEZ_OFERTA = valor }}
                      deshabilidado={existentes && !filtrando}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('VALIDEZ_OFERTA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                  {/* NO_PROFORMA */}
                  <Col className='p-0' style={{ width: 150 }}>
                    <TablaCampo
                      infoCampo={
                          {
                            fmto: ['', ordenExistentes === 'NO_PROFORMA' ? fmtoOrden : '',
                              'text-end bg-primary-subtle fw-bold' +
                              (fmtoWarningNoProf ? gcFmtosColorFondo.noExiste : '')],
                            type: existentes ? 'text' : 'number',
                            id: 'noProf',
                            label: 'No.',
                            txtDespues: '',
                            min: 1,
                            max: 99999999,
                            required: true,
                            disabled: (existentes && !filtrando),
                            ceroEsNulo: false
                          }
                        }
                      valTabla={regActual.NO_PROFORMA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('NO_PROFORMA', regActual.NO_PROFORMA)}
                      devuelveVal={ctrlSetNoProf}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('NO_PROFORMA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                </Row>
                {/* Cliente y vendedor */}
                <Row className='justify-content-between ms-2 me-2'>
                  <Col className='p-0 pe-2' xs={8}>
                  <Clientes
                    opcMenuCall={gcTablasLogicas.prof} updateTablaDoc
                    esOrden={ordenExistentes === 'CLI_NOMBRE'}
                    devuelveFiltro={(valor) => { stdTransacc.regsCab[stdTransacc.index].CLI_NOMBRE = valor }}
                    devuelveRegistro={(txtCampoTabla, filtro, regCliente) => setDatCliente(regCliente)}
                    devuelveDblClick={() => dblClickOrdenar('CLI_NOMBRE')}
                  >{hayNoCliente ? regActual.CLI_NOMBRE : regActual.N_CLIENTETMP}
                  </Clientes>
                  </Col>
                  <Col className='p-0'>
                    <TablaOpcion
                      valTabla={regActual.NO_VENDEDOR}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('NO_VENDEDOR', regActual.NO_VENDEDOR)}
                      infoTablaLista={infoVendedorSel(['', ordenExistentes === 'VENDEDOR' ? fmtoOrden : '', ''])}
                      devuelveDatos={({ nuevoVal, index, descrip }) => {
                        stdTransacc.regsCab[stdTransacc.index].NO_VENDEDOR = nuevoVal
                      }}
                      deshabilitado={existentes && !filtrando}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('VENDEDOR')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                </Row>
                {/* Tiempo y condición entrega */}
                <Row className='justify-content-between ms-2 me-2 mb-1'>
                  <Col className='p-0 me-2'>
                    <TablaCampo
                      infoCampo={
                        {
                          fmto: ['', ordenExistentes === 'TIEMPO_ENTREGA' ? fmtoOrden : '', ''],
                          type: 'text',
                          id: 'tiempoEntrega',
                          label: 'Tiempo de entrega:',
                          txtDespues: '',
                          min: null,
                          max: 100,
                          required: false,
                          disabled: (existentes && !filtrando),
                          ceroEsNulo: true
                        }
                      }
                      valTabla={regActual.TIEMPO_ENTREGA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('TIEMPO_ENTREGA', regActual.TIEMPO_ENTREGA)}
                      devuelveVal={(valor) => { stdTransacc.regsCab[stdTransacc.index].TIEMPO_ENTREGA = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('TIEMPO_ENTREGA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                  <Col className='p-0'>
                    <TablaCampo
                      infoCampo={
                        {
                          fmto: ['', ordenExistentes === 'CONDICION_OFERTA' ? fmtoOrden : '', ''],
                          type: 'text',
                          id: 'condicionEntrega',
                          label: userEmpConfig.textos_labels.profCondEntrega || 'Condición entrega:',
                          txtDespues: '',
                          min: null,
                          max: 100,
                          required: false,
                          disabled: (existentes && !filtrando),
                          ceroEsNulo: true
                        }
                      }
                      valTabla={regActual.CONDICION_OFERTA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('CONDICION_OFERTA', regActual.CONDICION_OFERTA)}
                      devuelveVal={(valor) => { stdTransacc.regsCab[stdTransacc.index].CONDICION_OFERTA = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('CONDICION_OFERTA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                </Row>
                {/* Atención persona y observaciones */}
                <Row className='justify-content-between ms-2 me-2 mb-2'>
                  <Col className='p-0 me-2' xs={4}>
                    <TablaCampo
                      infoCampo={
                        {
                          fmto: ['', ordenExistentes === 'ATENCION_PERSONA' ? fmtoOrden : '', ''],
                          type: 'text',
                          id: 'attPersona',
                          label: 'Atención persona:',
                          txtDespues: '',
                          min: null,
                          max: 30,
                          required: false,
                          disabled: (existentes && !filtrando),
                          ceroEsNulo: true
                        }
                      }
                      valTabla={regActual.ATENCION_PERSONA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('ATENCION_PERSONA', regActual.ATENCION_PERSONA)}
                      devuelveVal={(valor) => { stdTransacc.regsCab[stdTransacc.index].ATENCION_PERSONA = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('ATENCION_PERSONA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                  <Col className='p-0'>
                    <TablaCampo
                      infoCampo={
                        {
                          fmto: ['', ordenExistentes === 'OBS' ? fmtoOrden : '', ''],
                          type: 'text',
                          id: 'obsProf',
                          label: userEmpConfig.textos_labels.profObs || 'Observaciones:',
                          txtDespues: '',
                          min: null,
                          max: 250,
                          required: false,
                          disabled: (existentes && !filtrando),
                          ceroEsNulo: true
                        }
                      }
                      valTabla={regActual.OBS}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('OBS', regActual.OBS)}
                      devuelveVal={(valor) => { stdTransacc.regsCab[stdTransacc.index].OBS = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('OBS')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                </Row>
                {filtrando
                  ? <ItemFiltro
                      datosAPI={{ urlBase: '/proformas', msjErrorDet: ' DE LA PROFORMA' }}
                      idName='PROF_' esNC={false} sigReg={datDetalles.length + 1}
                    />
                  : <TransaccDet
                      orden={ordenExistentes}
                      noCliente={regActual.NO_CLIENTE} fechaDoc={regActual.FECHA}
                      datosAPI={{ urlBase: '/proformas', msjErrorDet: ' DE LA PROFORMA' }}
                      devuelveDets={(detalles) => setDatDetalles(detalles)}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar(campoOrden)}
                    />}
              </>
              {/* BARRA DE AUDITORIA */}
              {stdTransacc.tarea === tiposTarea.consultando
                ? <BarraAuditoria
                    userNo={stdTransacc.regsCab[stdTransacc.index].NO_USUARIO}
                    userNombre={stdTransacc.regsCab[stdTransacc.index].USER_NOMBRE}
                    fModifica={stdTransacc.regsCab[stdTransacc.index].F_MODIFICA}
                  />
                : null}
              {/* EMPRESAS Y BOTONES */}
              {filtrando || grabando.enProceso
                ? null
                : <Card.Footer className='mt-1 pb-0 pt-0 border-0'>
                    <Container className='p-0'>
                      <Row>
                        <Botones
                          totalRegs={stdTransacc.regsCab.length}
                          profValida={profValida} editando={editando}
                          estaGrabando={(valor) => setGrabando(valor)}
                          recargarCab={() => setRecargar(!recargar)}
                          devuelveCancelar={clickCancelar}
                          devuelveNuevoReg={ctrlNuevaProf}
                          devuelveNuevoFiltro={ctrlNuevoFiltroBotones}
                          devuelveRepIndividual={clickRepIndividual}
                        >{regActual.NO_PROFORMA}
                        </Botones>
                      </Row>
                      <Row><EmpSel devuelveCambiaEmp={ctrlCambioEmp} esHeader={false} /></Row>
                    </Container>
                  </Card.Footer>}
            </Card.Body>}
      <Encabezado
        noProfMod={modificando ? regActual.NO_PROFORMA_MOD : ''} sinEncabezado={filtrando}
      />
    </Card>
  )
}

export default Proformas
