/* eslint-disable react/jsx-indent */
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Button, Card, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap'

import restAPIAxios, { restAPIMySQL } from '../../../utils/axios.js'
import encryptStorage from '../../../utils/encryptStorage.js'
import useAdmin from '../../../context/Admin.jsx'
import useTransacc from '../../../context/Transacc.jsx'

import {
  existeNoFact, existeNoProf, factConStockNegativo, msjInfoCli, _sqlItemFiltroWhere
} from '../../../utils/bd.js'
import {
  cargaSeries, fechaRestaFechas, fechaSumaDias, numberFormat, infoPtoVtaSel,
  infoBodegaSel, infoVendedorSel, setInfoCampoFactura, gcTiposDoc, gcTablasLogicas,
  gcModulos, rutaReps, sriDatosDoc, fechaFormat, colocaFocoDirecto, gcFmtosColorFondo
} from '../../../utils/generales.js'

import Encabezado from './FactEncabezado.jsx'
import EmpSel from '../../admin/EmpSel.jsx'
import Botones from './FactBotones.jsx'

import FPsFactSimple from '../FPsFactSimple.jsx'
import ListaExistentes from './FactListaExistentes.jsx'
import BarraAuditoria from '../../generales/barras/BarraAuditoria.jsx'
import BarraSRI from '../../generales/barras/BarraSRI.jsx'
import BarraInfo from '../../generales/barras/BarraInfo.jsx'

// import TablaFiltro from '../generales/TablaFiltro.jsx'
import Clientes from '../clientes/Clientes.jsx'
import TablaOpcion from '../../generales/tablas/TablaOpcion.jsx'
import TablaCampo from '../../generales/tablas/TablaCampo.jsx'
import TablaCampoFecha from '../../generales/tablas/TablaCampoFecha.jsx'
import NoFactura from '../NoFactura.jsx'
import CampoNumerico from '../../generales/CampoNumerico.jsx'
import TransaccDet from '../../generales/transaccDet/TransaccDet.jsx'
import ItemFiltro from '../../productos/items/ItemFiltro.jsx'

import { useVerificaToken } from '../../../hooks/admin.jsx'
import { useWAncho1200 } from '../../../hooks/generales.jsx'

function Facturas ({ existentes }) {
  const [stdAdmin] = useAdmin()
  const [stdTransacc, dispatchTransacc, tiposAccionTransacc, tiposTarea] = useTransacc()
  const navigate = useNavigate()

  const [datDetalles, setDatDetalles] = useState([])
  const [datTotales, setDatTotales] = useState({
    subtotal: 0,
    totDsctoItems: 0,
    totIVA0: 0,
    pDsctoAdi: 0,
    vDsctoAdi: 0,
    vImponible: 0,
    pIVA: 0,
    vIVA: 0,
    totNeto: 0
  })
  const datosFPVacia = {
    vEfectivo: 0,
    vCambio: 0,
    vCredito: 0,
    vCheque: 0,
    chNo: 0,
    chBco: '',
    chCta: '',
    vTarjeta: 0,
    tarjNoInstit: 0,
    tarjDescrip: '',
    tarjNo: '',
    sriFP: '20',
    sinAsignar: true
  }
  const [datosFPs, setDatosFPs] = useState(datosFPVacia)
  const [datCliente, setDatCliente] = useState({})

  // const [noBodega, setNoBodega] = useState(null)
  const [diasCre, setDiasCre] = useState(0)
  const [tDocFiltrando, setTDocFiltrando] = useState('todos')

  const [whereExistentes, setWhereExistentes] = useState('')
  const [ordenExistentes, setOrdenExist] = useState('NO_FACTURA')

  const [grabando, setGrabando] = useState({ enProceso: false, msjPantalla: '' })
  // const [cargando, setCargando] = useState(false)
  // Se usa solo para pasar como parámetro a Botones para evitar que lo presionen mientras se editan campos
  const [editando, setEditando] = useState(false)
  const [recargar, setRecargar] = useState(false) // Bandera para recargar la cabecera
  const [enEspera, setEnEspera] = useState(false)
  const [fmtoWarningNoProf, setFmtoWarningNoProf] = useState(false)
  const [ocultaFP, setOcultaFP] = useState(true)

  const [wAncho1200, setWAncho1200] = useState(window.innerWidth < 1200)

  const esElect = useRef(false)
  const datExistentes = useRef([])

  // VERIFICA TOKEN
  // ** Debe ir antes de cualquier uso de stdAdmin porque aqui se puede recargar los valores desde el local storage
  useVerificaToken()

  // Asigna variables generales
  const poolName = stdAdmin.orclHost
  // Asigna las variables que extrae de los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')
  let pDsctoMax = 100
  if (userEmpConfig) {
    pDsctoMax = userEmpConfig.p_dscto_max
  }
  const creando = (stdTransacc.tarea === tiposTarea.creando)
  const modificando = (stdTransacc.tarea === tiposTarea.modificando)
  const consultando = (stdTransacc.tarea === tiposTarea.consultando)
  const filtrando = (stdTransacc.tarea === tiposTarea.filtrando)
  const listando = stdTransacc.listando
  const hayNoCliente = stdTransacc.otrosDat.cliente && stdTransacc.otrosDat.cliente[0]
  const esNC = stdTransacc.otrosDat.esNC

  // Controla el nivel de acceso al menú de Facturas y asigna variables de acceso
  let accModNo = false
  let accModFecha = false
  let accModFKardex = false
  let accSinStock = false
  let accDiasCre = false
  let accCambiaPMinUtil = false
  let accFormasPago = false
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (userVSAM) {
    // Asigna si tiene acceso a todo el botón (menú Facturación y opción Ventas)
    const accMnuFact = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_FACTURACION) === -1
    const accMnuVtas = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_VENTAS) === -1

    if (!accMnuFact || !accMnuVtas) {
      Swal.fire(
        'NO TIENE ACCESO A FACTURAR',
        'Intente acceder a otras páginas utilizando las opciones de menú disponibles',
        'info'
      )
      navigate('/bienvenida')
    }
    // Variables de Acceso
    accModNo = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_MOD_NO) === -1
    accModFecha = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_MOD_F) === -1
    accModFKardex = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_MOD_FKX) === -1
    accSinStock = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_SIN_STOCK) === -1
    accDiasCre = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_DIAS_CRE) === -1
    accCambiaPMinUtil = userVSAM.RESTRICC_ACC_PARAM.indexOf(process.env.REACT_APP_ACC_CAMBIA_PMIN_UTIL) >= 0
    accFormasPago = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_FORMAS_PAGO) === -1
  }

  let maxValXFactCliDscto = 0
  let maxFactsMenXCliDscto = 0
  let lFactElectronica = -1
  let fCerradoHasta = ''
  // Carga los parametros de la empresa
  const paramsEmp = stdAdmin.paramsEmp
  if (Object.keys(paramsEmp).length) {
    maxValXFactCliDscto = paramsEmp.MAX_VXFACT_MEN_X_CLI_CON_DSCTO
    maxFactsMenXCliDscto = paramsEmp.MAX_FACT_MEN_X_CLI_CON_DSCTO
    lFactElectronica = paramsEmp.L_FACT_ELECTRONICA
    fCerradoHasta = paramsEmp.F_CERRADOHASTA ? paramsEmp.F_CERRADOHASTA.substring(0, 10) : ''
  }

  // Solo para pasar al Filtrando cuando se refresca la pantalla en /facturas/existentes
  // ya que al refrescarla directamente desde windows se queda cargando datos indefinidamente
  // porque al refrescar la pantalla se pierde datFact. Por lo tanto hay que volver al filtrado de datos
  // useForzaFiltroEnRefresh({ tipoDoc: gcTiposDoc.fact, setListando })

  // Captura el evento resize de la pantalla, para conocer si es menor a 1200
  // y así poder reorganizar el No. de Factura y de Proforma o NC
  useWAncho1200(setWAncho1200)

  // Carga las Facturas NUEVAS (del usuario y la empresa respectivos)
  // o Carga Cabecera Facturas vacio para filtro
  useEffect(() => {
    console.log('🚀 ~ file: Facturas.jsx ~ useEffect ~ paramsEmp:', stdAdmin, stdTransacc)
    setFmtoWarningNoProf(false)
    // ** No carga registros si todavía no se asigna paramsEmp porque no hay series
    // paramsEmp se asigna en <EmpSel> y se necesita para obtener las series
    if (Object.keys(paramsEmp).length) {
      // Carga todas las series válidas para el usuario y empresa
      const series = cargaSeries(gcTiposDoc.fact, paramsEmp, userEmpConfig.series)
      // Escoge la primera serie de la lista de series del arreglo de todas las series
      let serie = series[0][0]
      serie = 'A' + serie.split(',')[0]

      async function cargaFactNuevas () {
        const respuestaAPI = await restAPIAxios(`NO SE PUDO CARGAR
                  ${modificando ? ' LA FACTURA PARA SU MODIFICACION' : ' LAS FACTURAS NUEVAS'}`,
        {
          method: 'get',
          url: `/facturas/nuevas/${poolName}/${stdAdmin.orclUsuario}/${stdAdmin.noUsuario}/${stdAdmin.noEmpresa}/${serie}/${modificando ? 'SI' : 'NO'}`
        }
        )
        if (respuestaAPI.status === 200) {
          // setDatFact(respuestaAPI.data)
          if (respuestaAPI.data.length) {
            let indexFact
            // let claveFact
            if (modificando) {
              // Se coloca en la factura que desea modificar (por si acaso haya mas registros para modificar)
              indexFact = respuestaAPI.data.findIndex(registro => {
                return (registro.NO_REG === stdTransacc.clave)
              })
              if (indexFact === -1) indexFact = 0
              // claveFact = respuestaAPI.data[indexFact].NO_REG
              dispatchTransacc({
                doc: gcTiposDoc.fact,
                tipo: tiposAccionTransacc.cargaRegs,
                tarea: tiposTarea.modificando,
                regsCab: respuestaAPI.data,
                index: indexFact
              })
            } else {
              if (creando) {
                // Para conservar el index del registro creado que se tiene actualmente en el estado
                indexFact = respuestaAPI.data.findIndex(registro => {
                  return (registro.NO_REG === stdTransacc.clave)
                })
              } else {
                // Verifica que el índice de sesión sea válido
                indexFact = respuestaAPI.data.length - 1 < stdTransacc.index ? 0 : stdTransacc.index
                // claveFact = respuestaAPI.data[indexFact].NO_REG
              }
              if (indexFact === -1) indexFact = 0
              dispatchTransacc({
                doc: gcTiposDoc.fact,
                tipo: tiposAccionTransacc.cargaRegs,
                tarea: tiposTarea.creando,
                regsCab: respuestaAPI.data,
                index: indexFact
              })
            }
            seteaVarState(respuestaAPI.data[indexFact])
          } else {
            dispatchTransacc({
              doc: gcTiposDoc.fact,
              tipo: tiposAccionTransacc.cambiaTarea,
              nuevaTarea: tiposTarea.filtrando
            })
            Swal.fire(
              'NO EXISTEN FACTURAS EN PROCESO DE MODIFICACION',
              'Seleccione una factura para su modificación',
              'info'
            )
            navigate('/facturas/existentes')
          }

          // En caso de que la API devuelva error
        } else {
          navigate('/bienvenida')
        }
      }

      function setBuscarExistentes () {
        if (datExistentes.current.length > 0) {
          // setDatFact(datExistentes.current)
          seteaVarState(datExistentes.current[0])
          dispatchTransacc({
            doc: gcTiposDoc.fact,
            tipo: tiposAccionTransacc.cargaRegs,
            tarea: tiposTarea.consultando,
            regsCab: datExistentes.current
          })
        } else {
          const regVacio = {
            CLI_NOMBRE: '',
            NO_PTOVENTA: 0,
            NO_BODEGA: 0,
            NO_CLIENTE: '',
            NO_VENDEDOR: '',
            OBS: '',
            NO_FACTURA: 'A000-000-',
            NO_PROFORMA: '',
            NO_FACTURA_NC: '',
            FECHA: '',
            F_VCMTO: '',
            F_KARDEX: '',
            NO_FACTURA_MOD: '',
            SRI_AUTORIZACION: null,
            SRI_CLAVE: null,
            SRI_MENSAJE: null
          }
          // setDatFact([regVacio])
          seteaVarState(regVacio)
          dispatchTransacc({
            doc: gcTiposDoc.fact,
            tipo: tiposAccionTransacc.cargaRegs,
            tarea: tiposTarea.filtrando,
            regsCab: [regVacio]
          })
        }
      }
      // ** Se usa "existentes" solo para el caso de un relouded de la pantalla
      // ya que el relouded reinicializa stdTransacc a su estado original (todo vacio)
      if (existentes) setBuscarExistentes()
      else cargaFactNuevas()
    // ** Se requiere recargar para el caso de que no haya registros del usuario y empresa
    // primera ocación que entra el usuario a la empresa
    } else if (!stdTransacc.regsCab.length) {
      setRecargar(!recargar)
    }
  }, [recargar, poolName, stdAdmin.noEmpresa, stdAdmin.noUsuario, stdAdmin.orclUsuario,
    stdTransacc.tarea, lFactElectronica])
  // ** No hace falta que vaya userEmpConfig, ya que se cargan en su propio Custom Hook y solo se usan para pasar como parámetro

  // Efecto que sirve solamente para colocar el foco en un campo en particular del componente
  useEffect(() => {
    colocaFocoDirecto(stdTransacc.idFoco)
  }, [stdTransacc.idFoco])

  // FUNCIONES PRIVADAS DEL COMPONENTE
  // Valida los datos de la Factura
  async function factValida () {
    // Verifica que no grabe items con Valores de Descuentos Negativos e Incorrectos
    async function itemsConDsctosNegativos () {
      setEnEspera(true)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE VALIDAR ITEMS CON DSCTOS. NEGATIVOS EN LA VENTA ${stdTransacc.clave}`,
        {
          method: 'get',
          url: `/facturas/nueva/dsctosNegativos/${poolName}/${stdAdmin.orclUsuario}/${stdTransacc.clave}`
        }
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) return (respuestaAPI.data)
      else return -1
    }
    // Verifica que no grabe items con pctjes. de utilidad menores al mínimo requerido
    async function itemsConPUtilBajoPMin () {
      setEnEspera(true)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE VALIDAR ITEMS CON UTILIDAD MENOR AL MINIMO REQUERIDO EN LA VENTA ${stdTransacc.clave}`,
        {
          method: 'get',
          url: `/facturas/nueva/PUtilMenorPMin/${poolName}/${stdAdmin.orclUsuario}/${stdTransacc.clave}/${datTotales.pDsctoAdi}`
        }
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) return (respuestaAPI.data)
      else return -1
    }
    // Verifica el control de Número Máximo mensual de Ventas por Cliente con Dscto.
    async function getFactsXCliMenMayorA () {
      setEnEspera(true)
      const noCliente = stdTransacc.regsCab[stdTransacc.index].NO_CLIENTE
      const fecha = stdTransacc.regsCab[stdTransacc.index].FECHA
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE capturar las facturas con subtotal mayor a $65 para el cliente No. ${noCliente} del mes ${fecha.substring(0, 7)}`,
        {
          method: 'get',
          url: `/facturas/xCliMenMayorA/${poolName}/${stdAdmin.orclUsuario}/${stdAdmin.noEmpresa}/${noCliente}/${fecha}/${maxValXFactCliDscto}`
        }
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) return (respuestaAPI.data)
      else return -1
    }
    // Verifica el control de Número Máximo mensual de Ventas por Cliente con Dscto.
    async function getTieneMultFPs (noFactura) {
      setEnEspera(true)
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE DETERMINAR SI LA FACTURA TIENE O NO COBROS MULTIPLES',
        {
          method: 'get',
          url: `/facturas/tieneMultFPs/${poolName}/${stdAdmin.orclUsuario}/${stdAdmin.noEmpresa}/${noFactura}`
        }
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) {
        if (respuestaAPI.data.length > 0) return respuestaAPI.data[0].CUANTASFPS > 0
        else return false
      } else return -1
    }

    const noCliente = stdTransacc.regsCab[stdTransacc.index].NO_CLIENTE
    const noFactNC = stdTransacc.regsCab[stdTransacc.index].NO_FACTURA_NC
    const vTotal = datTotales.totNeto
    const pDsctoOrg = Math.round(stdTransacc.regsCab[stdTransacc.index].P_DSCTO * 100, 4)

    // VERIFICACIONES GENERALES
    // Verifica la FECHA DE CIERRE para FECHA y F_KARDEX
    if (fCerradoHasta >= stdTransacc.regsCab[stdTransacc.index].FECHA.substring(0, 10)) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} ${esNC ? 'NOTAS DE CREDITO' : 'FACTURAS'} ANTERIORES AL ` +
          fechaFormat(fCerradoHasta, 'MEDIA'),
        `Cambie la fecha de la ${esNC ? 'nta.credito' : 'factura'} por una superior a la indicada`,
        'info'
      )
      return false
    }
    if (fCerradoHasta >= stdTransacc.regsCab[stdTransacc.index].F_KARDEX.substring(0, 10)) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} ${esNC ? 'NOTAS DE CREDITO' : 'FACTURAS'} ANTERIORES AL ` +
          fechaFormat(fCerradoHasta, 'MEDIA'),
        'Cambie la fecha de kardex por una superior a la indicada',
        'info'
      )
      return false
    }
    // Verifica que EXISTA EL CLIENTE
    if (noCliente === null || noCliente === undefined) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} ${esNC ? 'NOTAS DE CREDITO' : 'FACTURAS'} SIN ASIGNAR UN CLIENTE`,
        `Asigne un Cliente y vuelva a intentar grabar la ${esNC ? 'nota de crédito' : 'factura'}`,
        'info'
      )
      return false
    }
    // Verifica que EXISTAN ITEMS
    if (datDetalles.length === 0) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} ${esNC ? 'NOTAS DE CREDITO' : 'FACTURAS'} SIN ITEMS`,
        `Cree por lo menos un item en la ${esNC ? 'nota de crédito' : 'factura'} antes de grabarla`,
        'info'
      )
      return false
    }
    // Verifica que el %DSCTO no sea mayor al permitido en nuevas.
    // y en modificación, solo si se cambió el porcentaje
    if (modificando
      ? (datTotales.pDsctoAdi !== pDsctoOrg && datTotales.pDsctoAdi > pDsctoMax)
      : datTotales.pDsctoAdi > pDsctoMax) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} ${esNC ? 'NOTAS DE CREDITO' : 'FACTURAS'} CON % DSCTO. MAYOR A ${pDsctoMax}`,
        `Cambie el valor del descuento antes de grabar la ${esNC ? 'nota de crédito' : 'factura'}`,
        'info'
      )
      return false
    }
    // Verifica que no existan items con dsctos. negativos o incorrectos
    const res = await itemsConDsctosNegativos()
    // Hubo error en el API
    if (res === -1) return false
    // Existen registros con dsctos. negativos o incorrectos
    else if (res.length > 0) {
      Swal.fire(
        'NO PUEDEN EXISTIR ITEMS CON DSCTOS. NEGATIVOS',
                `el item No. ${res[0].NO_ITEM_USER} (${res[0].CODIGO}) ${res[0].DESCRIP} del registro No. ${res[0].NO_REG_FACT}
                    , no puede grabar valores con dscto. negativo, ni el precio de venta puede ser menor al valor unitario
                    , ni el %dscto. puede ser mayor al 100%`,
                'info'
      )
      return false
    }

    // VERIFICACIONES NC
    if (esNC) {
      // Verifica que haya un NO_FACTURA relacionado si es una NC
      if (noFactNC === null || noFactNC === '' || noFactNC.substring(0, 7) === '000-000') {
        Swal.fire(
          'DEBE COLOCAR UN NUMERO DE FACTURA RELACIONADO A LA NOTA DE CREDITO',
          '',
          'info'
        )
        return false
      }
      // Verifica que la NC tenga un valor total NEGATIVO
      if (datTotales.totNeto >= 0) {
        Swal.fire(
          'LA NOTA DE CREDITO DEBE TENER UN VALOR TOTAL NEGATIVO',
          'Solo las cantidades de cada item, deben estar en negativo',
          'info'
        )
        return false
      }

      // VERIFICACIONES FACTURA
    } else {
      // GENERALES
      // Verifica que la FACTURA tenga un valor total POSITIVO
      if (datTotales.totNeto < 0) {
        Swal.fire(
          'LA FACTURA DEBE TENER UN VALOR TOTAL POSITIVO',
          '',
          'info'
        )
        return false
      }
      // Verifica que la F_VCMTO sea mayor o igual a la FECHA
      if (stdTransacc.regsCab[stdTransacc.index].F_VCMTO.substring(0, 10) <
                stdTransacc.regsCab[stdTransacc.index].FECHA.substring(0, 10)) {
        Swal.fire(
          'LA FECHA DE VCMTO. DEBE SER MAYOR O IGUAL A LA FECHA DE LA FACTURA',
          '',
          'info'
        )
        return false
      }
      // SOLO MODIFICADAS
      if (modificando) {
        const noFacturaMod = stdTransacc.regsCab[filtrando ? 0 : stdTransacc.index].NO_FACTURA_MOD
        const noClienteMod = stdTransacc.regsCab[filtrando ? 0 : stdTransacc.index].NO_CLIENTE_MOD
        const vTotalMod = stdTransacc.regsCab[filtrando ? 0 : stdTransacc.index].V_TOTAL_MOD
        // Verifica que no modifique el valor total si tiene Cobros Múltiples
        if (vTotalMod !== vTotal) {
          Swal.fire(
            'NO PUEDE MODIFICAR (TODAVIA) EL VALOR TOTAL DE LA FACTURA EN LA APLICACION WEB',
            'Si requiere hacerlo, por ahora lo debe hacer en el aplicativo NO WEB',
            'info'
          )
          return false
          /*  Validación real (cuando ya este funcionando las FPs en Web)
                    const res = await getTieneMultFPs(noFacturaMod)
                    if (res === -1) return false
                    else if (res) {
                        Swal.fire(
                            `NO PUEDE MODIFICAR EL VALOR TOTAL DE LA FACTURA PORQUE TIENE COBROS MULTIPLES`,
                            `La factura ya esta cancelada y no puede modificar su valor total`,
                            'info',
                        );
                        return false
                    }
                    */
        }
        // Verifica que no modifique el cliente si tiene Cobros Múltiples
        if (noClienteMod !== noCliente) {
          const res = await getTieneMultFPs(noFacturaMod)
          if (res === -1) return false
          else if (res) {
            Swal.fire(
              'NO PUEDE MODIFICAR EL CLIENTE DE LA FACTURA PORQUE TIENE COBROS MULTIPLES',
              'Si no anula el cobro múltiple, no va a poder modificar el cliente del documento',
              'info'
            )
            return false
          }
        }
        // Capturo los datos de la proforma que se va a modificar para realizar varias validaciones
        const regFactMod = await existeNoFact(stdTransacc.regsCab[stdTransacc.index].NO_FACTURA_MOD, poolName, stdAdmin.orclUsuario, stdAdmin.noEmpresa)
        // Verifica que exista la factura
        if (!Object.keys(regFactMod.registro).length) {
          Swal.fire({
            title: `LA FACTURA No. ${stdTransacc.regsCab[stdTransacc.index].NO_FACTURA_MOD} QUE DESEA MODIFICAR YA NO EXISTE EN LA BASE DE DATOS`,
            text: 'El sistema cancelará automáticamente esta modificación',
            icon: 'info'
          }).then((result) => {
            if (result.value) clickCancelar(true)
          })
          return false
        }
        // Verifica que la factura no haya sido anulada
        if (regFactMod.registro.ESTADO === 'A') {
          Swal.fire(
            `LA FACTURA No. ${stdTransacc.regsCab[stdTransacc.index].NO_FACTURA_MOD} QUE DESEA MODIFICAR YA ESTA ANULADA`,
            'El sistema cancelará automáticamente esta modificación',
            'info'
          ).then((result) => {
            if (result.value) clickCancelar(true)
          })
          return false
        }
        // Verifica que la factura no haya sido autorizada por el SRI
        // if (sriDatosDoc(regFactMod.registro.SRI_AUTORIZACION).esValida && sriDatosDoc(regFactMod.registro.SRI_AUTORIZACION).esProducc) {
        if (sriDatosDoc(regFactMod.registro.SRI_AUTORIZACION).esValida) {
          Swal.fire({
            title: `LA FACTURA No. ${stdTransacc.regsCab[stdTransacc.index].NO_FACTURA_MOD} QUE DESEA MODIFICAR YA ESTA AUTORIZADA POR EL SRI`,
            text: 'El sistema cancelará automáticamente esta modificación',
            icon: 'info'
          }).then((result) => {
            if (result.value) clickCancelar(true)
          })
          return false
        }
        // Verifica que la factura no haya sido emitida con una clave válida para autorizarse en el SRIAutoriza
        if (sriDatosDoc(regFactMod.registro.SRI_CLAVE).esValida && sriDatosDoc(regFactMod.registro.SRI_CLAVE).esProducc) {
          Swal.fire({
            title: `LA FACTURA No. ${stdTransacc.regsCab[stdTransacc.index].NO_FACTURA_MOD} QUE DESEA MODIFICAR YA SE EMITIO CON UNA CLAVE PARA EL SRI VALIDA, Y ESTA LISTA PARA SER AUTORIZADA EN EL BATCH`,
            text: 'El sistema cancelará automáticamente esta modificación',
            icon: 'info'
          }).then((result) => {
            if (result.value) clickCancelar(true)
          })
          return false
        }

      // SOLO NUEVAS
      } else {
        // Verifica que no grabe items con stock negativo, en caso de que el usuario no tenga el nivel de acceso respectivo
        if (!accSinStock) {
          setEnEspera(true)
          const res = await factConStockNegativo(poolName, stdAdmin.orclUsuario, stdTransacc.clave, stdTransacc.otrosDat.noBodega)
          setEnEspera(false)
          // Hubo error en el API
          if (res === -1) return false
          // Existen registros con valor negativo
          else if (res.length > 0) {
            Swal.fire(
              'NO TIENE ACCESO A FACTURAR CON STOCK NEGATIVO',
              `El registro No. ${res[0].NO_REG_FACT} con el item No. ${res[0].NO_ITEM_USER} produce stock negativo`,
              'info'
            )
            return false
          }
        }
        // Verifica que la factura no grabe items con Utilidad menor al % Mínimo permitido,
        // en caso de que no tenga el nivel de acceso correcto
        if (paramsEmp.CTRL_MANEJO_P_MIN_UTIL_X_ITEM > 0 && !accCambiaPMinUtil) {
          const res = await itemsConPUtilBajoPMin()
          // Hubo error en el API
          if (res === -1) return false
          // Existen registros con valor negativo
          else if (res.length > 0) {
            Swal.fire(
              'NO TIENE ACCESO A FACTURAR ITEMS CON PCTJE. DE UTILIDAD MENOR AL MINIMO REQUERIDO',
              `el item No. ${res[0].NO_ITEM_USER} (${res[0].CODIGO}) ${res[0].DESCRIP} del registro No. ${res[0].NO_REG_FACT}
                , tiene una utilidad menor al valor mínimo permitido ${res[0].P_MIN_UTIL}%
                (costo+utilidad > PV-dsctos --> ${numberFormat(res[0].V_COST_PROM * (1 + (res[0].P_MIN_UTIL / 100)), 2)}
                > ${numberFormat(res[0].VALOR_UNIT * (1 - (datTotales.pDsctoAdi / 100)), 2)})`,
              'info'
            )
            return false
          }
        }
        // Verifica el control de Número Máximo mensual de Ventas por Cliente con Dscto.
        if (maxFactsMenXCliDscto > 0 && datTotales.subtotal > maxValXFactCliDscto &&
            (datTotales.pDsctoAdi > 0 || datTotales.vDsctoAdi > 0)) {
          const res = await getFactsXCliMenMayorA()
          // Hubo error en el API
          if (res === -1) return false
          else if (res.length > 0) {
            // Existen facturas con subtotal > maxValXFactCliDscto en el mes respectivo
            if (res[0].CUANTAS + 1 > maxFactsMenXCliDscto) {
              Swal.fire(
                'NO ES POSIBLE APLICAR DESCUENTO A ESTA FACTURA, PARA ESTE CLIENTE',
                `El cliente sobrepasa la cantidad mensual permitida de facturas con dscto. (${res[0].CUANTAS} >= ${maxFactsMenXCliDscto})
                . Ya no puede crear más facturas con dscto. este mes, con subtotal mayor o igual a $${maxValXFactCliDscto}`,
                'info'
              )
              return false
            }
          }
        }
        // Verifica que el Total sea igual al valor ingresado en Formas de Pago
        const totFPs = datosFPs.vEfectivo - datosFPs.vCambio + datosFPs.vCredito + datosFPs.vCheque + datosFPs.vTarjeta
        if (!datosFPs.sinAsignar && numberFormat(totFPs, 2, true) !== numberFormat(vTotal, 2, true)) {
          Swal.fire(
            'LA SUMA DE FORMAS DE PAGO ES DIFERENTE AL TOTAL DE LA FACTURA',
                        `El total debe ser ${numberFormat(vTotal, 2)}, en lugar de ${numberFormat(totFPs, 2)}. ${JSON.stringify(datosFPs)}`,
                        'info'
          )
          return false
        }
        // Verifica que haya escogido una Tarjeta de Crédito si colocó un valor en Tarjeta
        if (!datosFPs.sinAsignar && datosFPs.vTarjeta > 0 && datosFPs.tarjNoInstit === 0) {
          Swal.fire(
            'DEBE ESCOGER UNA TARJETA DE CREDITO PARA EL VALOR INGRESADO',
            '',
            'info'
          )
          return false
        }
      }
      // ** FALTAN RESERVAS DE PROFORMAS Y FACTURAS INDEPENDIENTES DE EGRESOS DE BODEGA
    }
    return true
  }
  async function cargaFactExistentes (where, orden, estaOrdenando) {
    // setCargando(true)
    setGrabando({ enProceso: true, msjPantalla: 'Cargando datos...' })
    const noFactTmp = stdTransacc.regsCab[stdTransacc.index].NO_FACTURA
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CARGAR LAS FACTURAS',
      {
        method: 'post',
        url: '/facturas/existentes',
        data: { poolName, orclUserVSAM: stdAdmin.orclUsuario, noEmp: stdAdmin.noEmpresa, where, orden }
      }
    )
    if (respuestaAPI.status === 200) {
      // No existen Facturas con esas condiciones.  Se queda en buscar
      if (respuestaAPI.data.length === 0) {
        Swal.fire(
          'NO EXISTEN FACTURAS CON ESAS CONDICIONES',
          'Cambie las condiciones e intente nuevamente',
          'info'
        )
      } else {
        datExistentes.current = respuestaAPI.data

        // Verifica que el índice de sesión sea válido
        const indexFactTmp = respuestaAPI.data.findIndex((reg) => reg.NO_FACTURA === noFactTmp)
        const indexFact = estaOrdenando ? (indexFactTmp === -1 ? 0 : indexFactTmp) : 0
        seteaVarState(respuestaAPI.data[indexFact])
        dispatchTransacc({
          doc: gcTiposDoc.fact,
          tipo: tiposAccionTransacc.cargaRegs,
          tarea: tiposTarea.consultando,
          regsCab: respuestaAPI.data,
          index: indexFact
        })
      }
    }
    // setCargando(false)
    setGrabando({ enProceso: false, msjPantalla: '' })
  }
  async function seteaVarState (registro) {
    // Verifica que existan datos en el registro para asignar las variables de estado
    if (registro) {
      const diasCreTmp = fechaRestaFechas(registro.F_VCMTO, registro.FECHA)
      setDiasCre(filtrando ? '' : diasCreTmp < 0 ? 0 : diasCreTmp)
      if (creando || modificando) {
        await existeProf(registro.NO_PROFORMA)
      }
      // Verifica que existan datos en los parametros para asignar las variables
      if (paramsEmp && Object.keys(paramsEmp).length) {
        // Es NC
        if (registro.NO_FACTURA.substr(0, 1) === 'B') {
          esElect.current = (paramsEmp.SRI_SERIES_NC_ELECT.indexOf(registro.NO_FACTURA.substr(1, 7)) >= 0)
        // Es Factura
        } else esElect.current = (paramsEmp.SRI_SERIES_FACT_ELECT.indexOf(registro.NO_FACTURA.substr(1, 7)) >= 0)
      }
    }
  }
  // Pinta con amarillo el No. de Proforma si no existe en la BD
  async function existeProf (noProf) {
    if (noProf) {
      const res = await existeNoProf(noProf, poolName, stdAdmin.orclUsuario, stdAdmin.noEmpresa)
      setFmtoWarningNoProf(!res.existe)
    } else setFmtoWarningNoProf(false)
  }

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  function ctrlClickConsultar (e) {
    function whereRangoNo (campoCond, soloNo, esNoFact) {
      soloNo = soloNo.toString()
      if (soloNo !== '') {
        const pos = soloNo.indexOf('-')
        if (pos === 0) {
          if (esNoFact) {
            return ` and ${campoCond} <= '${soloNo.substring(pos + 1).padStart(9, '0')}'`
          } else return ` and ${campoCond} <= ${soloNo.substring(pos + 1)}`
        } else if (pos === (soloNo.length - 1)) {
          if (esNoFact) {
            return ` and ${campoCond} >= '${soloNo.substring(0, pos).padStart(9, '0')}'`
          } else return ` and ${campoCond} >= ${soloNo.substring(0, pos)}`
        } else if (pos > 0) {
          if (soloNo.substring(pos).length > 1) {
            if (esNoFact) {
              return ` and ${campoCond} >= '${soloNo.substring(0, pos).padStart(9, '0')}'` +
                     ` and ${campoCond} <= '${soloNo.substring(pos + 1).padStart(9, '0')}'`
            } else {
              return ` and ${campoCond} >= ${soloNo.substring(0, pos)}` +
                     ` and ${campoCond} <= ${soloNo.substring(pos + 1)}`
            }
          } else
            if (esNoFact) {
              return ` and ${campoCond} >= '${soloNo.substring(0, pos).padStart(9, '0')}'`
            } else return ` and ${campoCond} >= ${soloNo.substring(0, pos)}`
        } else
          if (esNoFact) {
            return ` and ${campoCond} = '${soloNo.padStart(9, '0')}'`
          } else return ` and ${campoCond} = ${soloNo}`
      }
    }
    // Crea el WHERE con las condiciones establecidas por el usuario
    function condicion (datos) {
      const noFactura = datos.NO_FACTURA
      let where = ''
      if (tDocFiltrando === 'fact') where = where + ' and A.T_COMPROB = \'18\''
      if (tDocFiltrando === 'nc') where = where + ' and A.T_COMPROB = \'04\''
      if (noFactura.substr(1)) {
        // Solo No. sin Serie
        if (noFactura.substr(1, 7) === '000-000') {
          if (noFactura.substr(9)) {
            where = where + whereRangoNo('substr(A.NO_FACTURA, 10)', noFactura.substr(9), true)
          } else where = where + ''
        // Sin No. solo Serie
        } else if (noFactura.substr(9) === '') {
          where = where + ` and substr(A.NO_FACTURA, 2, 7) = '${noFactura.substr(1, 7)}'`
        } else {
          where = where + ` and substr(A.NO_FACTURA, 2, 7) = '${noFactura.substr(1, 7)}'` +
                            whereRangoNo('substr(A.NO_FACTURA, 10)', noFactura.substr(9), true)
        }
      }
      if (datos.NO_PTOVENTA > 0) where = where + ' and A.NO_PTOVENTA = ' + datos.NO_PTOVENTA
      if (stdTransacc.otrosDat.noBodega) where = where + ' and A.NO_BODEGA = ' + stdTransacc.otrosDat.noBodega
      if (datos.CLI_NOMBRE) {
        where = where + ` and B.NOMBRE like '${datos.CLI_NOMBRE.replaceAll('*', '%')}%'`
      }
      if (datos.NO_VENDEDOR) {
        where = where + ` and A.NO_VENDEDOR = ${datos.NO_VENDEDOR}`
      }
      if (datos.OBS) {
        if (datos.OBS === '*') {
          where = where + ' and not A.OBS is null'
          where = where + " and replace(replace(A.OBS, chr(13), '|'), chr(10), '|') <> '||'"
        } else where = where + ` and A.OBS like '${datos.OBS.replaceAll('*', '%')}%'`
      }
      if (datos.NO_PROFORMA) where = where + whereRangoNo('A.NO_PROFORMA', datos.NO_PROFORMA, false)
      if (datos.NO_FACTURA_NC) {
        // Sin Serie solo No.
        if (datos.NO_FACTURA_NC.substr(0, 7) === '000-000') {
          where = where + whereRangoNo('substr(EXTRA8_TXT, 9)', datos.NO_FACTURA_NC.substr(8), true)
        // Sin No. solo Serie
        } else if (datos.NO_FACTURA_NC.substr(8) === '') {
          where = where + ` and substr(EXTRA8_TXT, 2, 7) = '${datos.NO_FACTURA_NC.substr(0, 7)}'`
        } else where = where + whereRangoNo('substr(EXTRA8_TXT, 9)', datos.NO_FACTURA_NC.substr(8), true)
      }
      if (datos.FECHA) {
        where = where + ` and to_char(A.FECHA, 'yyyy-mm-dd') ${stdTransacc.fechaCond[0]} '${datos.FECHA}'`
      }
      if (datos.F_VCMTO) {
        where = where + ` and to_char(A.F_DEVENGA, 'yyyy-mm-dd') ${stdTransacc.fechaCond[1]} '${datos.F_VCMTO}'`
      }
      if (datos.F_KARDEX) {
        where = where + ` and to_char(A.F_KARDEX, 'yyyy-mm-dd') ${stdTransacc.fechaCond[2]} '${datos.F_KARDEX}'`
      }
      if (diasCre) {
        where = where + whereRangoNo('ceil(A.F_DEVENGA - A.FECHA)', diasCre, false)
      }
      return where
    }
    const whereCab = condicion(stdTransacc.regsCab[0])
    let where = whereCab
    // Asigna el where de items unico
    if (Object.keys(stdTransacc.itemFiltro.registro).length) {
      where = where + `and exists
        (select AB.NO_ITEM from ${stdAdmin.orclUsuario}.T_DET_FACTURA AB
         where AB.NO_INSTIT_SIST = A.NO_INSTIT_SIST and AB.NO_FACTURA = A.NO_FACTURA
          and AB.NO_ITEM = ${stdTransacc.itemFiltro.registro.KEY_TAB})`
    // Asigna el where de items utilizando el filtro
    } else if (stdTransacc.itemFiltro.filtro) {
      where = where + `and exists
        (select AB.NO_ITEM from ${stdAdmin.orclUsuario}.T_DET_FACTURA AB
          inner join ${stdAdmin.orclUsuario}.V_ITEMS_L_GRUPOS AC on AB.NO_ITEM = AC.NO_ITEM
         where AB.NO_INSTIT_SIST = A.NO_INSTIT_SIST and AB.NO_FACTURA = A.NO_FACTURA
          ${_sqlItemFiltroWhere(stdTransacc.itemFiltro.filtro,
            'AC.' + stdTransacc.itemFiltro.campoOrden).replaceAll('A.', 'AC.')})`
    }
    setWhereExistentes(where)
    cargaFactExistentes(where, ordenExistentes, false)
  }
  async function ctrlChangeNC (e) {
    // ** No usar e.preventDefault() porque causa efectos no deseados al presionar en el check
    // Hay que guardar el valor original, porque se pierde luego de enviar al API
    const nuevoEsNC = e.target.checked
    async function procesaCheckNC (serie) {
      setEnEspera(true)
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR LOS CAMPOS NECESARIOS EN LA BD',
        {
          method: 'patch',
          url: '/facturas/nueva/checkNC',
          data: {
            poolName,
            orclUserVSAM: stdAdmin.orclUsuario,
            noEmp: stdAdmin.noEmpresa,
            serie,
            noReg: stdTransacc.clave
          }
        }
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) {
        dispatchTransacc({
          tipo: tiposAccionTransacc.setOtrosDat,
          otrosDat: { esNC: nuevoEsNC }
        })
      }
    }
    const nuevasSeries = cargaSeries(nuevoEsNC ? gcTiposDoc.nc : gcTiposDoc.fact, paramsEmp, userEmpConfig.series)[0]
    await procesaCheckNC((nuevoEsNC ? 'B' : 'A') + nuevasSeries[0])
  }
  function ctrlDblClickDiasCre (e) {
    // e.preventDefault()
    dblClickOrdenar('DIAS_CRE')
  }

  // FUNCIONES DE CONTROL (EVENTOS) DEVUELTOS POR LOS COMPONENTES
  function ctrlCambioEmp () {
    datExistentes.current = []
    setRecargar(!recargar)
  }
  function clickCancelar (sinMsjeConfirma) {
    // e.preventDefault()
    async function cancela () {
      setGrabando({ enProceso: true, msjPantalla: `${modificando ? 'Cancelando la modificación...' : 'Eliminando detalle...'}` })
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE
         ${modificando ? ' CANCELAR LA MODIFICACION' : ' ELIMINAR LOS REGISTROS'}`,
      {
        method: 'post',
        url: `${modificando ? '/facturas/cancelaMod' : '/facturas/nueva/cancela'}`,
        data: modificando
          ? {
              poolName,
              orclUserVSAM: stdAdmin.orclUsuario,
              noReg: stdTransacc.clave,
              noFactura: regActual.NO_FACTURA_MOD
            }
          : {
              poolName,
              orclUserVSAM: stdAdmin.orclUsuario,
              noReg: stdTransacc.clave,
              noEmp: stdAdmin.noEmpresa,
              serie: regActual.NO_FACTURA.substr(0, 8),
              dias: diasCre
            }
      }
      )
      setGrabando({ enProceso: false, msjPantalla: '' })
      if (respuestaAPI.status === 200) {
        setRecargar(!recargar)
      }
    }
    if (sinMsjeConfirma) {
      cancela()
    } else {
      Swal.fire({
        title: 'CONFIRME',
        text: modificando
          ? 'Desea cancelar el proceso de modificación de la factura?'
          : 'Desea eliminar todos los registros de la factura?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO'
      }).then((result) => {
        if (result.isConfirmed) cancela()
      })
    }
  }

  function clickAnterior () {
    seteaVarState(stdTransacc.regsCab[stdTransacc.index - 1])
  }
  function click10Ant () {
    seteaVarState(stdTransacc.regsCab[stdTransacc.index - 10])
  }
  function clickPrimera () {
    seteaVarState(stdTransacc.regsCab[0])
  }

  function clickSiguiente () {
    seteaVarState(stdTransacc.regsCab[stdTransacc.index + 1])
  }
  function click10Sig () {
    seteaVarState(stdTransacc.regsCab[stdTransacc.index + 10])
  }
  function clickUltima () {
    seteaVarState(stdTransacc.regsCab[stdTransacc.regsCab.length - 1])
  }

  function clickListado (index) {
    seteaVarState(stdTransacc.regsCab[index])
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      index,
      listando: false
    })
  }
  function ctrlNuevoFiltroLista () {
    datExistentes.current = []
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaTarea,
      nuevaTarea: tiposTarea.filtrando
    })
    navigate('/facturas/existentes')
  }
  function dblClickOrdenar (campo) {
    if (consultando) {
      const orden = campo === undefined ? ordenExistentes : campo
      setOrdenExist(orden)
      cargaFactExistentes(whereExistentes, orden, true)
    }
  }
  function ctrlPtoVtaValor ({ nuevoVal, index, descrip }) {
    stdTransacc.regsCab[stdTransacc.index].NO_PTOVENTA = nuevoVal
  }
  function ctrlDiasCre (idName, valor) {
    async function actFechaVcmto (fVcmtoBD, fVcmto, dias) {
      setEnEspera(true)
      const infoTablaAct = setInfoCampoFactura('F_VCMTO', fVcmtoBD)
      const defProps = {
        poolName,
        tabla: infoTablaAct.tabla,
        campo: infoTablaAct.campo,
        valor: infoTablaAct.valor,
        cond: infoTablaAct.campoClave + '=' + stdTransacc.clave,
        ceroEsNulo: false,
        dias
      }
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR LA FECHA DE VENCIMIENTO EN LA BD',
        {
          method: 'patch',
          url: infoTablaAct.url,
          data: defProps
        }, true
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) {
        stdTransacc.regsCab[stdTransacc.index].F_VCMTO = fVcmto
        setDiasCre(valor)
        setRecargar(!recargar)
        setOcultaFP(true)
        setDatosFPs(datosFPVacia)
      }
    }
    switch (idName) {
      case 'fechaFact':
        stdTransacc.regsCab[stdTransacc.index].FECHA = valor
        if (creando || modificando) {
          const nuevaFVcmto = fechaSumaDias(valor, diasCre, '-')
          stdTransacc.regsCab[stdTransacc.index].F_KARDEX = valor
          stdTransacc.regsCab[stdTransacc.index].F_VCMTO = nuevaFVcmto[1]
          setRecargar(!recargar)
        }
        break
      case 'diasCre':
        // Actualiza F_VCMTO en la BD
        if (filtrando) setDiasCre(valor)
        else {
          const fFact = stdTransacc.regsCab[stdTransacc.index].FECHA
          const fVcmtoSinFmto = fechaSumaDias(fFact, (valor === '' ? 0 : valor), '-')
          const valFVcmto = `to_date('${fVcmtoSinFmto[1]}', 'yyyy-mm-dd')`
          stdTransacc.regsCab[stdTransacc.index].F_VCMTO = fVcmtoSinFmto[1]
          actFechaVcmto(valFVcmto, fVcmtoSinFmto[1], (valor === '' ? 0 : valor))
        }
        break
      case 'fVcmto':
        if (!consultando) {
          if (valor !== '' && valor < stdTransacc.regsCab[stdTransacc.index].FECHA.substr(0, 10)) {
            Swal.fire(
              'LA FECHA DE VENCIMIENTO NO PUEDE SER MENOR A LA FECHA DE LA FACTURA',
              'El sistema no cambiará la fecha de vencimiento',
              'info'
            )
            // Vuelve a colocar la fecha de vcmto. original
            const fVcmtoSinFmto = stdTransacc.regsCab[stdTransacc.index].F_VCMTO.substr(0, 10)
            const valFVcmto = `to_date('${fVcmtoSinFmto}', 'yyyy-mm-dd')`
            actFechaVcmto(valFVcmto, fVcmtoSinFmto, diasCre)
          } else if (valor !== '') {
            stdTransacc.regsCab[stdTransacc.index].F_VCMTO = valor
            const nuevoDiasCre = fechaRestaFechas(valor, stdTransacc.regsCab[stdTransacc.index].FECHA)
            setDiasCre(nuevoDiasCre)
          }
        }
        break
      default:
    }
  }
  function ctrlNuevoFiltroBotones () {
    // Solamente debe limpiar los datos existentes cuando ya este consultando y requiera
    // un nuevo filtro al último capturado
    if (stdTransacc.tarea === tiposTarea.consultando) {
      datExistentes.current = []
    }
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaTarea,
      nuevaTarea: tiposTarea.filtrando
    })
    navigate('/facturas/existentes')
  }
  async function ctrlSetNoProf (valor) {
    stdTransacc.regsCab[stdTransacc.index].NO_PROFORMA = (!filtrando && valor === '' ? null : valor)
    if (filtrando) return
    await existeProf(valor)
  }
  async function clickRepIndividual (formato, conEmail) {
    if (conEmail && !regActual.CLI_EMAIL) {
      Swal.fire(
        'EL CLIENTE NO TIENE NINGUN CORREO ELECTRONICO ASIGNADO PARA EL ENVIO DE LA FACTURA',
        'La factura no puede ser enviada por correo electrónico',
        'info'
      )
      return
    }
    if (conEmail) setGrabando({ enProceso: true, msjPantalla: 'Enviando el correo electrónico...' })

    let url = ''
    switch (formato) {
      case 'RODILLO': url = '/facturas/reportes/rodillo'
        break
      case 'CLIENTE': url = '/facturas/reportes/individual'
        break
      case 'INTERNO': url = '/facturas/reportes/interno'
        break
      default: url = '/facturas/reportes/individual'
        break
    }
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CREAR EL REPORTE SOLICITADO DE LA FACTURA',
      {
        method: 'post',
        url,
        data: {
          cabeceras: stdTransacc.regsCab[stdTransacc.index],
          detalles: datDetalles,
          rutaCarpetas: userEmpConfig.ruta_carpetas,
          saveFile: conEmail
        },
        responseType: 'blob'
      }
    )
    if (respuestaAPI.status === 200) {
      // Envía el Email con el attach del PDF
      if (conEmail) {
        let fileName = 'factura.pdf'
        if (respuestaAPI.headers['content-disposition']) {
          fileName = respuestaAPI.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
        }

        const resAPIEmail = await restAPIMySQL('ERROR AL ENVIAR AL CLIENTE LA FACTURA POR CORREO ELECTRONICO',
          {
            method: 'post',
            url: '/sendEmail',
            data: {
              emailConfig: stdAdmin.email_config,
              datos: {
                from: `${regActual.EMP_NOMBRE} - ${stdAdmin.userNombre}`,
                to: `${regActual.CLI_EMAIL}`,
                subject: `Factura No. ${regActual.NO_FACTURA.substring(1)}`,
                text: `Estimado ${regActual.CLI_NOMBRE}`,
                html: `<p style="text-align: center">${regActual.EMP_NOMBRE} le envía adjunto la <b>Factura No. ${regActual.NO_FACTURA.substring(1)}</b></p>`
              },
              configAttach: {
                filename: `Factura No. ${regActual.NO_FACTURA.substring(1)}.pdf`,
                path: rutaReps(userEmpConfig.ruta_carpetas, gcModulos.vtas) + fileName
              }
            }
          }
        )
        if (resAPIEmail.status === 200) {
          Swal.fire(
            `LA FACTURA SE ENVIO CON EXITO A "${regActual.CLI_NOMBRE}"`,
            `Email ${regActual.CLI_EMAIL}`,
            'info'
          )
        }
      } else {
        let pdf = new Blob([respuestaAPI.data], { type: 'application/pdf' })
        pdf = window.URL.createObjectURL(pdf)
        window.open(pdf)
      }
    }
    if (conEmail) setGrabando({ enProceso: false, msjPantalla: '' })
  }

  if (!stdAdmin.auth || !stdTransacc.regsCab.length) return null

  const pantallaNC = (filtrando && tDocFiltrando === 'nc') || (!filtrando && esNC)
  const fmtoOrden = consultando ? 'bg-primary text-white' : ''

  const regActual = stdTransacc.regsCab.length
    ? stdTransacc.regsCab[stdTransacc.index]
    : {}

  return (
    <Card className='border-light'>
      <Encabezado
        sinEncabezado={false} esHeader
        noDocMod={modificando ? regActual.NO_FACTURA_MOD : ''}
      />
      {listando
        ? <ListaExistentes
            registros={stdTransacc.regsCab} devuelveClickListado={clickListado}
            devuelveClickNuevoFiltro={ctrlNuevoFiltroLista} orden={ordenExistentes}
          />
        : grabando.enProceso
          ? <div className='mt-5 mb-5 pt-5 pb-5 h3 text-center'>
              <Spinner animation='grow' />{grabando.msjPantalla}
            </div>
          : <Card.Body className={'p-0' + (creando ? '' : ' bg-dark-subtle')}>
              {/* EMPRESAS Y BOTONES */}
              <Card.Title className='mb-1'>
                <Container className='p-0'>
                  <Row>
                    <EmpSel devuelveCambiaEmp={ctrlCambioEmp} esHeader />
                  </Row>
                  <Row>
                    {/* Botones Filtrando y Transacciones */}
                    {filtrando
                      ? <Container className=''>
                          <Row className='h6 p-0 m-0 text-secondary justify-content-center bg-dark-subtle'>
                            <span className='text-center p-1'>Si desea establezca valores en los campos (un filtro):&nbsp;&nbsp;</span>
                            <Button
                              className='' size='sm' style={{ width: 100 }}
                              onClick={ctrlClickConsultar}
                            >Consultar
                            </Button>
                          </Row>
                        </Container>
                      : <Botones
                          totalRegs={stdTransacc.regsCab.length}
                          factValida={factValida} diasCre={diasCre}
                          editando={editando} datosFPs={datosFPs}
                          esElect={esElect.current}
                          estaGrabando={(valor) => setGrabando(valor)}
                          recargarCab={() => setRecargar(!recargar)}
                          devuelveAnterior={clickAnterior}
                          devuelveSiguiente={clickSiguiente}
                          devuelve10Ant={click10Ant}
                          devuelve10Sig={click10Sig}
                          devuelvePrimera={clickPrimera}
                          devuelveUltima={clickUltima}
                          devuelveFP={() => setOcultaFP(!ocultaFP)}
                          devuelveCancelar={clickCancelar}
                          devuelveNuevoReg={(nuevoRegFact, nueva) => {
                            datExistentes.current = nuevoRegFact
                            if (nueva) setRecargar(!recargar)
                          }}
                          devuelveNuevoFiltro={ctrlNuevoFiltroBotones}
                          devuelveRepIndividual={clickRepIndividual}
                        >{regActual.NO_FACTURA}
                        </Botones>}
                  </Row>
                </Container>
              </Card.Title>

              {/* TRANSACCION */}
              {Object.keys(paramsEmp).length && stdTransacc.regsCab.length
                ? <>
                  {/* CABECERA SIN TOTALES */}
                  {/* Datos Autorización SRI o Datos Informativos */}
                  {consultando || modificando
                    ? <BarraSRI
                        sriAutoriz={regActual.SRI_AUTORIZACION}
                        sriClave={regActual.SRI_CLAVE}
                        sriMensaje={regActual.SRI_MENSAJE}
                      />
                    : creando
                      ? <BarraInfo mensaje={msjInfoCli(esNC, datCliente, maxFactsMenXCliDscto, maxValXFactCliDscto)} />
                      : null}

                  {/* Ptos.Venta, Bodega, Clientes, Estado, Tipo de Documento, No. Factura y Proforma o No.Factura NC */}
                  <Row className='ms-1 me-1 justify-content-between'>
                    {/* Ptos.Venta, Bodega y Clientes */}
                    <Col className='' xs={null} lg={10} xl={6} xxl={7}>
                      {/* Ptos.Venta y Bodega */}
                      <Row className='justify-content-between'>
                        <Col className=''>
                          <TablaOpcion
                            valTabla={regActual.NO_PTOVENTA}
                            infoTablaActualiza={consultando || filtrando
                              ? undefined
                              : setInfoCampoFactura('NO_PTOVENTA', regActual.NO_PTOVENTA)}
                            infoTablaLista={infoPtoVtaSel(['mb-1', ordenExistentes === 'PTO_VENTA' ? fmtoOrden : '', ''])}
                            devuelveDatos={ctrlPtoVtaValor}
                            deshabilitado={consultando}
                            devuelveDblClick={(campoOrden) => dblClickOrdenar('PTO_VENTA')}
                            devuelveEditando={(valor) => setEditando(valor)}
                          />
                        </Col>
                        <Col className=''>
                          <TablaOpcion
                            valTabla={stdTransacc.otrosDat.noBodega}
                            infoTablaActualiza={consultando || filtrando
                              ? undefined
                              : setInfoCampoFactura('NO_BODEGA', stdTransacc.otrosDat.noBodega)}
                            infoTablaLista={infoBodegaSel(['mb-1', ordenExistentes === 'BODEGA' ? fmtoOrden : '', ''])}
                            deshabilitado={consultando}
                            devuelveDblClick={(campoOrden) => dblClickOrdenar('BODEGA')}
                            devuelveEditando={(valor) => setEditando(valor)}
                          />
                        </Col>
                      </Row>
                      {/* Clientes */}
                      <Clientes
                        opcMenuCall={gcTablasLogicas.fact} updateTablaDoc
                        devuelveFiltro={(valor) => { stdTransacc.regsCab[stdTransacc.index].CLI_NOMBRE = valor }}
                        devuelveRegistro={(txtCampoTabla, filtro, regCliente) => setDatCliente(regCliente)}
                        esOrden={ordenExistentes === 'CLI_NOMBRE'}
                        devuelveDblClick={() => dblClickOrdenar('CLI_NOMBRE')}
                      >{hayNoCliente ? regActual.CLI_NOMBRE : regActual.N_CLIENTETMP}
                      </Clientes>
                    </Col>
                    {/* Estado y Tipo de Documento */}
                    <Col className='ps-1 me-1' xs={5} lg={4} xl={2} style={{ minWidth: 170 }}>
                      <div className={wAncho1200 ? 'd-flex' : ''}>
                        {/* Estado */}
                        <Row
                          className='rounded border border-primary-subtle bg-info-subtle mt-1 mb-2 ms-2 me-2'
                          hidden={!existentes || filtrando}
                        ><span className='text-center fw-bold'>{regActual.ESTADO === 'A'
                          ? 'ANULADA'
                          : regActual.ESTADO === 'S'
                            ? 'CON SALDO'
                            : regActual.ESTADO === 'C'
                              ? 'CANCELADA'
                              : 'PENDIENTE'}
                         </span>
                        </Row>
                        {/* Tipo Doc Nuevas */}
                        <Row className='mb-2' hidden={existentes || filtrando}>
                          <Form.Check
                            className='text-center'
                            style={{ width: 150 }}
                            type='checkbox'
                            label='Nota Crédito:'
                            reverse
                            checked={esNC}
                            onChange={ctrlChangeNC}
                          />
                          <Spinner animation='grow' hidden={!enEspera} />
                        </Row>
                        {/* Tipo Doc Filtrando */}
                        <Row className='' hidden={!filtrando}>
                          <Form.Select
                            className='text-center' style={{ maxWidth: 140 }}
                            size='sm'
                            value={tDocFiltrando}
                            onChange={(e) => {
                              setTDocFiltrando(e.target.value)
                              dispatchTransacc({
                                tipo: tiposAccionTransacc.setOtrosDat,
                                otrosDat: { esNC: e.target.value === 'nc' }
                              })
                            }}
                          >
                            <option value='todos'>Todos Docs.</option>
                            <option value='nc'>Solo Ntas.Cré.</option>
                            <option value='fact'>Solo Facturas</option>
                          </Form.Select>
                        </Row>
                        {/* Tipo Doc Existentes */}
                        <Row
                          className='ms-2 me-2 mb-1 pb-1'
                          hidden={!existentes || filtrando || !esNC}
                          style={{ minWidth: 150 }}
                        >
                          <span className='rounded border border-primary bg-primary-subtle text-center'>NOTA CREDITO
                          </span>
                        </Row>
                      </div>
                    </Col>
                    {/* No. Factura y Proforma o No.Factura NC */}
                    <Col className='p-0' xs={9} lg={7} xl={2} style={{ minWidth: 320 }}>
                      <div className={wAncho1200 ? 'd-flex' : ''}>
                        <NoFactura
                          infoCampo={
                                      {
                                        fmto: ['bg-primary-subtle fw-bold', ordenExistentes === 'NO_FACTURA' ? fmtoOrden : 'text-end', 'text-end bg-primary-subtle fw-bold'],
                                        id: 'noFact',
                                        label: 'No.',
                                        required: true,
                                        disabled: ((!accModNo || existentes) && !filtrando)
                                      }
                                  }
                          valTabla={regActual.NO_FACTURA}
                          infoTablaActualiza={existentes
                            ? undefined
                            : setInfoCampoFactura('NO_FACTURA', regActual.NO_FACTURA)}
                          devuelveVal={(valor) => { stdTransacc.regsCab[stdTransacc.index].NO_FACTURA = valor }}
                          devuelveDblClick={(campoOrden) => dblClickOrdenar('NO_FACTURA')}
                          devuelveEditando={(valor) => setEditando(valor)}
                        />
                        <TablaCampo
                          infoCampo={
                                      {
                                        fmto: ['mb-1', ordenExistentes === 'NO_PROFORMA, NO_FACTURA_NC' ? fmtoOrden : '',
                                          (existentes && filtrando ? 'text-end' : 'text-end') + (fmtoWarningNoProf && !filtrando ? gcFmtosColorFondo.noExiste : '')],
                                        type: existentes ? 'text' : 'number',
                                        hidden: pantallaNC,
                                        id: 'noProf',
                                        label: 'No. Proforma:',
                                        txtDespues: '',
                                        min: 1,
                                        max: 999999999,
                                        required: true,
                                        disabled: (existentes && !filtrando),
                                        ceroEsNulo: false
                                      }
                                  }
                          valTabla={regActual.NO_PROFORMA}
                          infoTablaActualiza={existentes
                            ? undefined
                            : setInfoCampoFactura('NO_PROFORMA', regActual.NO_PROFORMA)}
                          devuelveVal={ctrlSetNoProf}
                          devuelveDblClick={(campoOrden) => dblClickOrdenar('NO_PROFORMA, NO_FACTURA_NC')}
                          devuelveEditando={(valor) => setEditando(valor)}
                        />
                        <NoFactura
                          infoCampo={
                                      {
                                        fmto: ['', ordenExistentes === 'NO_FACTURA_NC, NO_PROFORMA' ? fmtoOrden : 'text-end', 'text-end'],
                                        id: 'noFactNC',
                                        label: 'Factura:',
                                        required: true,
                                        hidden: (!pantallaNC),
                                        disabled: (existentes && !filtrando)
                                      }
                                    }
                          valTabla={regActual.NO_FACTURA_NC}
                          infoTablaActualiza={existentes
                            ? undefined
                            : setInfoCampoFactura('NO_FACTURA_NC', regActual.NO_FACTURA_NC)}
                          devuelveVal={(valor) => { stdTransacc.regsCab[stdTransacc.index].NO_FACTURA_NC = valor }}
                          devuelveDblClick={(campoOrden) => dblClickOrdenar('NO_FACTURA_NC, NO_PROFORMA')}
                          devuelveEditando={(valor) => setEditando(valor)}
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* Vendedor, Fecha, Días crédito y Vencimiento */}
                  <Row className='ms-1 me-1 justify-content-between'>
                    {/* Vendedor */}
                    <Col
                      xs={9}
                      lg={pantallaNC ? 5 : existentes || filtrando ? 4 : 5}
                      xl={pantallaNC ? 5 : existentes || filtrando ? 5 : 6}
                      xxl={pantallaNC ? 6 : existentes || filtrando ? 6 : 8}
                    >
                      <TablaOpcion
                        valTabla={regActual.NO_VENDEDOR}
                        infoTablaActualiza={existentes
                          ? undefined
                          : setInfoCampoFactura('NO_VENDEDOR',
                            regActual.NO_VENDEDOR
                          )}
                        infoTablaLista={infoVendedorSel(['mb-1', ordenExistentes === 'VENDEDOR' ? fmtoOrden : '', ''])}
                        devuelveDatos={({ nuevoVal, index, descrip }) => {
                          stdTransacc.regsCab[stdTransacc.index].NO_VENDEDOR = nuevoVal
                        }}
                        deshabilitado={existentes && !filtrando}
                        devuelveDblClick={(campoOrden) => dblClickOrdenar('VENDEDOR')}
                        devuelveEditando={(valor) => setEditando(valor)}
                      />
                    </Col>
                    {/* Fecha */}
                    <Col
                      xs={pantallaNC ? 7 : 4} lg={existentes || filtrando ? 3 : 2} xl={existentes || filtrando ? 3 : 2}
                      style={{ minWidth: existentes || filtrando ? 330 : 200 }}
                    >
                      <TablaCampoFecha
                        infoCampo={
                                      {
                                        fmto: ['mb-1 me-2', ordenExistentes === 'FECHA' ? fmtoOrden : '', ''],
                                        id: 'fechaFact',
                                        label: 'Fecha:',
                                        required: true,
                                        disabled: ((!accModFecha || existentes) && !filtrando),
                                        condFiltro: stdTransacc.fechaCond[0],
                                        showHora: existentes && !filtrando,
                                        dias: diasCre
                                      }
                                  }
                        valTabla={regActual.FECHA}
                        infoTablaActualiza={existentes
                          ? undefined
                          : setInfoCampoFactura('FECHA', regActual.FECHA)}
                        devuelveVal={(valor) => { ctrlDiasCre('fechaFact', valor) }}
                        devuelveDblClick={(campoOrden) => dblClickOrdenar('FECHA')}
                        devuelveEditando={(valor) => setEditando(valor)}
                        devuelveCond={(valor) => dispatchTransacc({
                          tipo: tiposAccionTransacc.setFechaCond,
                          fCondIndex: 0,
                          fCondVal: valor
                        })}
                      />
                    </Col>
                    {/* Días crédito */}
                    <Col xs={3} md={1} hidden={pantallaNC} style={{ minWidth: 120 }}>
                      <InputGroup
                        className=''
                        size='sm'
                      >
                        <InputGroup.Text
                          id='DIAS_CRE' className={'mb-1 ' + (ordenExistentes === 'DIAS_CRE' ? fmtoOrden : '')}
                          onDoubleClick={ctrlDblClickDiasCre}
                        >Días:
                        </InputGroup.Text>
                        <CampoNumerico
                          idName='diasCre' fmtoInput='mb-1'
                          devuelveVal={ctrlDiasCre}
                          decimales={0} obligatorio
                          deshabilitado={(existentes && !filtrando) || !accDiasCre}
                          soloPositivo={false} filtrando={filtrando}
                          style={{ minWidth: 40, maxWidth: 50 }}
                        >{diasCre}
                        </CampoNumerico>
                      </InputGroup>
                    </Col>
                    {/* Fecha Vencimiento */}
                    <Col
                      xs='auto' hidden={pantallaNC}
                      style={{ minWidth: existentes || filtrando ? 230 : 200 }}
                    ><TablaCampoFecha
                      infoCampo={
                                  {
                                    fmto: ['mb-1', ordenExistentes === 'F_VCMTO' ? fmtoOrden : '', ''],
                                    id: 'fVcmto',
                                    label: 'Vence:',
                                    required: true,
                                    disabled: ((!accDiasCre || existentes) && !filtrando),
                                    hidden: ((filtrando && tDocFiltrando === 'nc') || (!filtrando && esNC)),
                                    condFiltro: stdTransacc.fechaCond[1],
                                    showHora: false,
                                    dias: diasCre
                                  }
                                }
                      valTabla={regActual.F_VCMTO}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoFactura('F_VCMTO', regActual.F_VCMTO)}
                      devuelveVal={(valor) => { ctrlDiasCre('fVcmto', valor) }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('F_VCMTO')}
                      devuelveEditando={(valor) => setEditando(valor)}
                      devuelveCond={(valor) => dispatchTransacc({
                        tipo: tiposAccionTransacc.setFechaCond,
                        fCondIndex: 1,
                        fCondVal: valor
                      })}
                     />
                    </Col>
                    {/* Fecha Kardex */}
                    <Col xs='auto' hidden={!pantallaNC}>
                      <TablaCampoFecha
                        infoCampo={
                                    {
                                      fmto: ['mb-1', ordenExistentes === 'F_KARDEX' ? fmtoOrden : '', ''],
                                      id: 'fKardex',
                                      label: 'F. Kardex:',
                                      required: true,
                                      disabled: ((!accModFKardex || existentes) && !filtrando),
                                      condFiltro: stdTransacc.fechaCond[2],
                                      showHora: false,
                                      dias: diasCre
                                    }
                                  }
                        valTabla={regActual.F_KARDEX}
                        infoTablaActualiza={existentes
                          ? undefined
                          : setInfoCampoFactura('F_KARDEX', regActual.F_KARDEX)}
                        devuelveVal={(valor) => { stdTransacc.regsCab[stdTransacc.index].F_KARDEX = valor }}
                        devuelveDblClick={(campoOrden) => dblClickOrdenar('F_KARDEX')}
                        devuelveEditando={(valor) => setEditando(valor)}
                        devuelveCond={(valor) => dispatchTransacc({
                          tipo: tiposAccionTransacc.setFechaCond,
                          fCondIndex: 2,
                          fCondVal: valor
                        })}
                      />
                    </Col>
                  </Row>

                  {/* Observación y Fecha Kardex */}
                  <Row className='ms-1 me-1 mb-1 justify-content-between'>
                    {/* Observación */}
                    <Col
                      xs={null} md={pantallaNC ? null : 7} lg={pantallaNC ? null : 8} xl={pantallaNC ? null : 9}
                      xxl={pantallaNC ? null : filtrando ? 9 : 10}
                    >
                      <TablaCampo
                        infoCampo={
                                    {
                                      fmto: ['', ordenExistentes === 'OBS' ? fmtoOrden : '', ''],
                                      type: 'text',
                                      id: 'obsFact',
                                      label: 'Observaciones:',
                                      txtDespues: '',
                                      min: null,
                                      max: 250,
                                      required: false,
                                      disabled: (existentes && !filtrando),
                                      ceroEsNulo: true
                                    }
                                  }
                        valTabla={regActual.OBS}
                        infoTablaActualiza={existentes
                          ? undefined
                          : setInfoCampoFactura('OBS', regActual.OBS)}
                        devuelveVal={(valor) => { stdTransacc.regsCab[stdTransacc.index].OBS = valor }}
                        devuelveDblClick={(campoOrden) => dblClickOrdenar('OBS')}
                        devuelveEditando={(valor) => setEditando(valor)}
                      />
                    </Col>
                    {/* Fecha Kardex */}
                    <Col xs='auto' hidden={pantallaNC}>
                      <TablaCampoFecha
                        infoCampo={
                                    {
                                      fmto: ['mb-1', ordenExistentes === 'F_KARDEX' ? fmtoOrden : '', ''],
                                      id: 'fKardex',
                                      label: 'F. Kardex:',
                                      required: true,
                                      disabled: ((!accModFKardex || existentes) && !filtrando),
                                      condFiltro: stdTransacc.fechaCond[2],
                                      showHora: false,
                                      dias: diasCre
                                    }
                                  }
                        valTabla={regActual.F_KARDEX}
                        infoTablaActualiza={existentes
                          ? undefined
                          : setInfoCampoFactura('F_KARDEX', regActual.F_KARDEX)}
                        devuelveVal={(valor) => { stdTransacc.regsCab[stdTransacc.index].F_KARDEX = valor }}
                        devuelveDblClick={(campoOrden) => dblClickOrdenar('F_KARDEX')}
                        devuelveEditando={(valor) => setEditando(valor)}
                        devuelveCond={(valor) => dispatchTransacc({
                          tipo: tiposAccionTransacc.setFechaCond,
                          fCondIndex: 2,
                          fCondVal: valor
                        })}
                      />
                    </Col>
                  </Row>

                  {/* FORMAS DE PAGO */}
                  {filtrando || !accFormasPago || ocultaFP || esNC
                    ? null
                    : <FPsFactSimple
                        devuelveOcultaFP={() => setOcultaFP(true)}
                        vTotal={datTotales.totNeto} diasCre={diasCre}
                        datos={datosFPs}
                        devuelveValores={(valores) => setDatosFPs(valores)}
                      />}

                  {filtrando
                    ? <ItemFiltro
                        datosAPI={{ urlBase: '/facturas', msjErrorDet: ' DE LA FACTURA' }}
                        esNC={esNC} sigReg={datDetalles.length + 1}
                      />
                    : <TransaccDet
                        orden={ordenExistentes}
                        datosAPI={{ urlBase: '/facturas', msjErrorDet: ' DE LA FACTURA' }}
                        noCliente={regActual.NO_CLIENTE} fechaDoc={regActual.FECHA}
                        esNC={esNC}
                        devuelveDets={(detalles) => { setDatDetalles(detalles); setDatosFPs(datosFPVacia); setOcultaFP(true) }}
                        devuelveTots={(totales) => { setDatTotales(totales); setDatosFPs(datosFPVacia); setOcultaFP(true) }}
                        devuelveDblClick={(campoOrden) => dblClickOrdenar(campoOrden)}
                      />}
                  {/* BARRA DE AUDITORIA */}
                  {existentes && !filtrando
                    ? <BarraAuditoria
                        userNo={stdTransacc.regsCab[stdTransacc.index].NO_USUARIO}
                        userNombre={stdTransacc.regsCab[stdTransacc.index].USER_NOMBRE}
                        fModifica={stdTransacc.regsCab[stdTransacc.index].F_MODIFICA}
                      />
                    : null}
                  </>
                : null}

              {/* EMPRESAS Y BOTONES */}
              {filtrando || grabando.enProceso
                ? null
                : <Card.Footer className='mt-1 pb-0 pt-0 border-0'>
                    <Container className='p-0'>
                      <Row>
                        <Botones
                          totalRegs={stdTransacc.regsCab.length}
                          factValida={factValida} diasCre={diasCre}
                          editando={editando} datosFPs={datosFPs}
                          esElect={esElect.current}
                          estaGrabando={(valor) => setGrabando(valor)}
                          recargarCab={() => setRecargar(!recargar)}
                          devuelveAnterior={clickAnterior}
                          devuelveSiguiente={clickSiguiente}
                          devuelve10Ant={click10Ant}
                          devuelve10Sig={click10Sig}
                          devuelvePrimera={clickPrimera}
                          devuelveUltima={clickUltima}
                          devuelveFP={() => setOcultaFP(!ocultaFP)}
                          devuelveCancelar={clickCancelar}
                          devuelveNuevoReg={(nuevoRegFact, nueva) => {
                            datExistentes.current = nuevoRegFact
                            if (nueva) setRecargar(!recargar)
                          }}
                          devuelveNuevoFiltro={ctrlNuevoFiltroBotones}
                          devuelveRepIndividual={clickRepIndividual}
                        >{regActual.NO_FACTURA}
                        </Botones>
                      </Row>
                      <Row>
                        <EmpSel devuelveCambiaEmp={ctrlCambioEmp} esHeader={false} />
                      </Row>
                    </Container>
                  </Card.Footer>}
            </Card.Body>}
      <Encabezado
        sinEncabezado={filtrando} esHeader={false}
        noDocMod={modificando ? regActual.NO_FACTURA_MOD : ''}
      />
    </Card>
  )
}

export default Facturas
