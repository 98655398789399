import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { Dropdown, Form, InputGroup, Row, Spinner } from 'react-bootstrap'

import restAPIAxios from '../../../utils/axios'
import encryptStorage from '../../../utils/encryptStorage'
import useAdmin from '../../../context/Admin'
import useTransacc from '../../../context/Transacc'

import {
  anchoPantDisponible, fmtosTablaFiltroCampo, gcTablasLogicas, gcFmtoMaxRows,
  gcTiposDoc, gcFmtosColorFondo
} from '../../../utils/generales'

function TablaFiltro (props) {
  const {
    url, maxRegs, txtTabla, tabCampos, txtLblTabla, txtCampos, campoFiltro, condFija,
    condBodega, condGrupo, obligatorio, deshabilitado, sinPlaceHolder, fmto
  } = props.infoTablaLista
  const style = props.style === undefined ? {} : props.style

  const [stdAdmin] = useAdmin()
  const [stdTransacc, , , tiposTarea] = useTransacc()

  const [datosTabla, setDatosTabla] = useState([])
  const [filtro, setFiltro] = useState('')
  const [registroSel, setRegistroSel] = useState({})
  const [cargando, setCargando] = useState(false)

  const [fmtoNoExiste, setFmtoNoExiste] = useState('')
  const FMTO_NO_EXISTE = gcFmtosColorFondo.noExiste

  const infoTActualiza = props.infoTablaActualiza
  const noActualiceTabla = (infoTActualiza === undefined)

  // Extrae los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')
  const poolName = stdAdmin.orclHost

  const idRowFiltro = props.idRowFiltro || ('tabla-' + txtTabla + '-' + campoFiltro.enTabla)

  useEffect(() => {
    // Para que oculte la tabla en lugar de tener abierta y para obligar a volver a cargar datos
    setDatosTabla([])
    // Verifica si el filtro no es numérico y esta en NO_ITEM_USER de Items
    if (txtTabla === gcTablasLogicas.item && campoFiltro.enTabla === 'NO_ITEM_USER' && isNaN(props.children)) {
      setFmtoNoExiste(FMTO_NO_EXISTE)
    // Para que borre el FMTO_NO_EXISTE en caso de haberlo tenido antes
    } else setFmtoNoExiste('')

    // Para que no vaya UNDEFINED al valor del combo (sale una advertencia en herramientas)
    setFiltro(props.children ?? '')
  }, [props.children])

  // FUNCIONES PRIVADAS DEL COMPONENTE
  const condClaveUnica = () => {
    let condTxt = ''
    let condNoTxt = ''
    // Evita que se envíe al API el AND sin valor
    if (filtro) {
      condTxt = `and ${campoFiltro.enSQL} = '${filtro}'`
      condNoTxt = `and ${campoFiltro.enSQL} = ${filtro}`
    }
    let respuesta = ''
    switch (txtTabla) {
      case gcTablasLogicas.cliente:
        if (registroSel.KEY_TAB) respuesta = `and NO_CLIENTE = ${registroSel.KEY_TAB}`
        // Evita que se envíe al API el AND sin valor
        respuesta = ''
        break
      case gcTablasLogicas.grupoItem:
        respuesta = condTxt
        break
      case gcTablasLogicas.item:
        respuesta = campoFiltro.enTabla === 'NO_ITEM_USER' ? condNoTxt : condTxt
        break
      default:
        respuesta = ''
        break
    }
    return respuesta
  }
  // Verifica si el filtro es una clave única válida
  function esFiltroClaveUnica () {
    switch (txtTabla) {
      case gcTablasLogicas.item: return (campoFiltro.enTabla === 'NO_ITEM_USER' || campoFiltro.enTabla === 'CODIGO')
      case gcTablasLogicas.grupoItem: return (campoFiltro.enTabla === 'CODIGO')
      default: return false
    }
  }
  // Asigna el body que se envía en el RestAPI que captura el registro único
  const bodyAPIBuscaFiltro = (conClaveUnica) => {
    let noPV = 1
    if (stdTransacc.doc === gcTiposDoc.prof || stdTransacc.doc === gcTiposDoc.fact) {
      noPV = stdTransacc.otrosDat.noPVBod || stdTransacc.otrosDat.noPVCli || 1
    }
    return {
      method: 'post',
      url,
      data: txtTabla === gcTablasLogicas.item
        ? {
            poolName,
            orclUserVSAM: stdAdmin.orclUsuario,
            noEmp: stdAdmin.noEmpresa,
            campoFiltro,
            filtro,
            condFija,
            condBodega,
            condGrupo,
            condClaveUnica: conClaveUnica ? condClaveUnica() : '',
            noPV
          }
        : {
            poolName,
            orclUserVSAM: stdAdmin.orclUsuario,
            noEmp: stdAdmin.noEmpresa,
            campoFiltro,
            filtro,
            condFija,
            condClaveUnica: conClaveUnica ? condClaveUnica() : ''
          }
    }
  }

  async function actualizaCampo (valor) {
    setCargando(true)
    const infoTablaCampo = {
      poolName,
      tabla: infoTActualiza.tabla,
      campo: infoTActualiza.campo,
      valor,
      cond: infoTActualiza.campoClave + '=' + stdTransacc.clave,
      ceroEsNulo: false
    }
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR EL CAMPO EN LA BD',
      {
        method: 'patch',
        url: infoTActualiza.url,
        data: infoTablaCampo
      }
    )
    if (respuestaAPI.status === 200) {
      if (respuestaAPI.data[0] === 0) {
        Swal.fire({
          title: 'EL VALOR NO SE ACTUALIZO EN LA BD',
          text: 'consulte a su técnico',
          footer: 'La respuesta de la API fue 0',
          icon: 'info'
        })
        setCargando(false)
        return false
      } else {
        setCargando(false)
        return true
      }
    } else {
      setCargando(false)
      return false
    }
  }

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  // Carga los registros de la Tabla para selección
  async function ctrlClickToggle (e) {
    // e.preventDefault()
    if (!cargando && datosTabla.length > 0) {
      setCargando(false)
      setDatosTabla([])
    } else {
      setCargando(true)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE CARGAR ${txtTabla}`,
        bodyAPIBuscaFiltro(false)
      )
      if (respuestaAPI.status === 200) {
        if (respuestaAPI.data.length > maxRegs) {
          Swal.fire(
            `EL FILTRO SELECCIONADO CARGA MAS DE ${maxRegs} ${txtTabla} (${respuestaAPI.data.length})`,
            'Elija un filtro que reduzca la cantidad de registros para seleccionar',
            'info'
          )
        } else {
          setDatosTabla(respuestaAPI.data)
        }
      }
      setCargando(false)
    }
  }
  function ctrlChangeFiltro (e) {
    setFiltro(e.target.value)
    setDatosTabla([])
    if (props.devuelveEditando) props.devuelveEditando(true)
  }
  async function ctrlBlurFiltro (e) {
    // e.preventDefault()
    if (props.devuelveEditando) props.devuelveEditando(false)
    // No hubo cambios en el campo y no viene de <ItemFiltro>
    // ** cuando esta filtrando props.children siempre es igual a e.target.value
    // ** y por lo tanto nunca va a buscar el número o codigo del item.
    // ** Es necesario que busque el item cuando viene de <ItemFiltro>
    if (e.target.value === props.children && idRowFiltro !== 'itemFiltro-SelRapida') return
    // (e.target.value = filtro) ==> ya que se cambia el filtro en ctrlChangeFiltro
    // Actualiza el campo a NULL
    if (e.target.value === '' && props.children !== null) {
      setRegistroSel({})
      setDatosTabla([])
      if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, '', {})
      if (noActualiceTabla) {
        setFiltro('')
        if (props.devuelveFiltro) props.devuelveFiltro('')
      } else
        if (await actualizaCampo(null)) {
          setFiltro('')
          if (props.devuelveFiltro) props.devuelveFiltro('')
        } else {
          !props.children ? setFiltro('') : setFiltro(props.children)
          if (props.devuelveFiltro) props.devuelveFiltro(!props.children ? '' : props.children)
        }
    // Busca el dato en la BD cuando es un filtro con clave única y con valor
    // y siempre que aún no se haya elegido un registro en la tabla respectiva
    // y también siempre que haya cambiado el valor del registro seleccionado
    } else if (esFiltroClaveUnica() && e.target.value && (!registroSel[campoFiltro.enTabla] || registroSel[campoFiltro.enTabla].toString() !== e.target.value)) {
      setDatosTabla([])
      if (props.devuelveFiltro) props.devuelveFiltro(e.target.value)

      // Verifico si el filtro es numérico y que este en NO_ITEM_USER de Items
      // para que no busque el item innecesariamente ** no debe hacer nada **
      // Como si no hubiera encuentrado el registro para el valor (e.target.value) digitado
      if (txtTabla === gcTablasLogicas.item && campoFiltro.enTabla === 'NO_ITEM_USER' && isNaN(e.target.value)) {
        setRegistroSel({})
        if (props.devuelveRegistro && !['', '*', '%'].includes(e.target.value)) props.devuelveRegistro(campoFiltro.enTabla, e.target.value, {})
        setFmtoNoExiste(e.target.value ? FMTO_NO_EXISTE : '')
      // Busca el item
      } else {
        console.log('.... entro ...........')
        setCargando(true)
        const respuestaAPI = await restAPIAxios('', bodyAPIBuscaFiltro(true))
        // Encontro el registro
        if (respuestaAPI.status === 200) {
          if (noActualiceTabla) {
            setRegistroSel(respuestaAPI.data[0])
            if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, e.target.value, respuestaAPI.data[0])
          } else {
            if (await actualizaCampo(respuestaAPI.data[0].KEY_TAB)) {
              setRegistroSel(respuestaAPI.data[0])
              if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, e.target.value, respuestaAPI.data[0])
            } else {
              setRegistroSel({})
              if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, e.target.value, {})
            }
          }
          setFmtoNoExiste('')
        // ** no debe hacer nada **
        // Hubo un error o no encuentra el registro para el valor (e.target.value) digitado
        } else {
          setRegistroSel({})
          if (props.devuelveRegistro && !['', '*', '%'].includes(e.target.value)) props.devuelveRegistro(campoFiltro.enTabla, e.target.value, {})
          setFmtoNoExiste(e.target.value ? FMTO_NO_EXISTE : '')
        }
        setCargando(false)
      }
    } else if (props.devuelveFiltro) props.devuelveFiltro(e.target.value)
  }
  async function ctrlClickLista (e) {
    const index = e.target.getAttribute('index')
    // En caso de que presione en la cabecera u otro lugar donde index es null
    if (index) {
      const nuevoFiltro = datosTabla[index][campoFiltro.enTabla]
      if (noActualiceTabla) {
        setRegistroSel(datosTabla[index])
        setFiltro(nuevoFiltro)
        setDatosTabla([])
        if (props.devuelveFiltro) props.devuelveFiltro(nuevoFiltro)
        if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, nuevoFiltro, datosTabla[index])
      } else {
        // actualiza el Campo en la Tabla
        if (await actualizaCampo(datosTabla[index].KEY_TAB)) {
          setFiltro(datosTabla[index][campoFiltro.enTabla])
          setRegistroSel(datosTabla[index])
          setDatosTabla([])
          if (props.devuelveFiltro) props.devuelveFiltro(nuevoFiltro)
          if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, nuevoFiltro, datosTabla[index])
        } else {
          !props.children ? setFiltro('') : setFiltro(props.children)
          setRegistroSel({})
          setDatosTabla([])
          if (props.devuelveFiltro) props.devuelveFiltro(!props.children ? '' : props.children)
          if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, '', {})
        }
      }
    }
  }
  function ctrlDblClick (e) {
    // e.preventDefault()
    if (!props.devuelveDblClick) {
      if (stdTransacc.tarea === tiposTarea.consultando) {
        Swal.fire(
          'NO ESTA ACTIVA LA OPCION DE ORDENADO PARA ESTE CAMPO',
          '',
          'info'
        )
      }
    } else props.devuelveDblClick()
  }

  if (!stdAdmin.auth) return null
  return (
    <>
      <Row className='' id={idRowFiltro}>
        <InputGroup className={fmto[0]} size='sm'>
          <InputGroup.Text
            id={'lblTabla-' + txtTabla + '-' + campoFiltro.enTabla} className={fmto[1]}
            onDoubleClick={ctrlDblClick}
          >{txtLblTabla}
          </InputGroup.Text>
          <Spinner animation='grow' hidden={!cargando} />
          <Form.Control
            id={'tablaFiltro-' + txtTabla + '-' + campoFiltro.enTabla}
            className={fmto[2] + (filtro === '' ? '' : fmtoNoExiste)}
            style={style}
            type='text'
            value={filtro}
            placeholder={sinPlaceHolder ? '' : `Elija un filtro para obtener ${txtTabla.toLowerCase()}`}
            aria-describedby={'lblTabla-' + txtTabla + '-' + campoFiltro.enTabla}
            required={obligatorio}
            disabled={deshabilitado}
            onChange={ctrlChangeFiltro}
            onBlur={ctrlBlurFiltro}
          />
          <Dropdown.Toggle
            split
            variant='outline-secondary'
            id='tablaCtrl'
            disabled={!filtro}
            hidden={deshabilitado}
            onClick={ctrlClickToggle}
          />
        </InputGroup>
      </Row>
      <div
        id='tablaDiv'
        className='overflow-scroll border border-3'
        style={{ maxHeight: 400, minWidth: anchoPantDisponible(idRowFiltro) }}
        hidden={datosTabla.length === 0}
      >
        <table
          className='table table-bordered table-sm table-hover table-responsive-md'
          style={{ fontSize: '10px' }}
          onClick={ctrlClickLista}
        >
          <thead>
            <tr className='table-primary sticky-top'>
              <th
                key={0}
                className={gcFmtoMaxRows(datosTabla.length, maxRegs).fmto}
              >{datosTabla.length}
              </th>
              {txtCampos.map((txtCampo, index) => (
                <th
                  key={index + 1}
                  className={datosTabla.length === 0
                    ? ''
                    : fmtosTablaFiltroCampo(txtTabla, tabCampos[index], datosTabla[0][tabCampos[index]], userEmpConfig.decimales).fmto}
                  hidden={txtCampo === ''}
                  style={{
                    minWidth: datosTabla.length === 0
                      ? 0
                      : fmtosTablaFiltroCampo(txtTabla, tabCampos[index], datosTabla[0][tabCampos[index]], userEmpConfig.decimales).ancho
                  }}
                >{txtCampo}
                </th>
              ))}
            </tr>
          </thead>
          <tbody
            className='table-light'
          >
            {datosTabla.map((registro, indexReg) => (
              <tr key={registro.KEY_TAB}>
                <td
                  key='INDEX'
                  className='text-center'
                  id={registro.KEY_TAB}
                  index={indexReg}
                >{indexReg + 1}
                </td>
                {tabCampos.map((campo, indexCampo) => (
                  <td
                    key={campo}
                    className={fmtosTablaFiltroCampo(txtTabla, campo, registro[campo], userEmpConfig.decimales).fmto}
                    id={registro.KEY_TAB}
                    index={indexReg}
                    hidden={txtCampos[indexCampo] === ''}
                  >{fmtosTablaFiltroCampo(txtTabla, campo, registro[campo], userEmpConfig.decimales).texto}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TablaFiltro
