import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'

import restAPIAxios from '../../utils/axios'
import useAdmin from '../../context/Admin'

const MnuLogin = () => {
  const navigate = useNavigate()

  const [stdAdmin, dispatchAdmin, accionAdminTipo] = useAdmin()
  const poolName = stdAdmin.orclHost

  const cierraSesion = (e) => {
    // e.preventDefault()
    async function cierraBD () {
      const respuestaAPI = await restAPIAxios('',
        {
          method: 'post',
          url: '/logout',
          data: { poolName }
        }
      )
      // console.log(respuestaAPI)
      if (respuestaAPI.status !== 200) console.log(respuestaAPI)
    }

    Swal.fire({
      title: 'CONFIRME',
      text: 'Desea cerrar la sesión?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatchAdmin({ tipo: accionAdminTipo.logout })
        cierraBD()
        navigate('/login')
      }
    })
  }

  return (
    <Navbar className='justify-content-end me-3 p-'>
      <Button className='btn-sm' variant='outline-primary' onClick={cierraSesion}>
        Cerrar Sesión
      </Button>
    </Navbar>
  )
}

export default MnuLogin
