import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import encryptStorage from '../../../utils/encryptStorage.js'
import useTransacc from '../../../context/Transacc.jsx'

import { gcFmtosBotones } from '../../../utils/generales.js'

import imgGrabar from '../../../iconos/GRABAR.BMP'
import imgMas from '../../../iconos/MAS.ICO'
import imgMenos from '../../../iconos/MENOS.ICO'
import imgBuscar from '../../../iconos/BUSCAR.ICO'

function TransaccDetBotones (props) {
  const esNuevoItem = props.esNuevoItem
  const devuelveClickLimpiaItem = props.devuelveClickLimpiaItem
  const devuelveClickLimpiaVal = props.devuelveClickLimpiaVal
  const devuelveClickNuevoReg = props.devuelveClickNuevoReg
  const devuelveClickEliminaReg = props.devuelveClickEliminaReg
  const devuelveClickDatosItem = props.devuelveClickDatosItem

  const [stdTransacc, , , tiposTarea] = useTransacc()
  const consultando = stdTransacc.tarea === tiposTarea.consultando

  const userVSAM = encryptStorage.getItem('userVSAM')
  const accDatosItem = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_DATOS_ITEM) === -1

  const fmtoBots = 'me-1 border-0 fw-bold fst-italic'
  const fmtoBotsImg = gcFmtosBotones.imagen
  const widthBots = gcFmtosBotones.imgWidth
  return (
    <div className='d-flex justify-content-around bg-primary-subtle border border-primary rounded'>
      <OverlayTrigger overlay={<Tooltip>grabar</Tooltip>}>
        <Button
          className={fmtoBotsImg} id='GRABA' type='submit' size='sm' hidden={consultando}
        ><img src={imgGrabar} alt='grabar' width={widthBots} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip>limpia datos item</Tooltip>}>
        <Button
          className={fmtoBots} id='LIMPIA ITEM' size='sm'
          onClick={devuelveClickLimpiaItem} hidden={consultando}
        >X Item
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip>limpia valores</Tooltip>}>
        <Button
          className={fmtoBots} id='LIMPIA VALORES' size='sm'
          onClick={devuelveClickLimpiaVal} hidden={consultando}
        >X Val
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip>nuevo registro</Tooltip>}>
        <Button
          className={fmtoBotsImg} id='NUEVO' size='sm'
          onClick={devuelveClickNuevoReg} hidden={consultando || esNuevoItem}
        ><img src={imgMas} alt='nuevo registro' width={widthBots} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip>elimina registro</Tooltip>}>
        <Button
          className={fmtoBotsImg} id='ELIMINA' size='sm'
          onClick={devuelveClickEliminaReg} hidden={consultando || esNuevoItem}
        ><img src={imgMenos} alt='elimina registro' width={widthBots} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip>datos item</Tooltip>}>
        <Button
          className={fmtoBotsImg} id='DATOS ITEM' size='sm'
          hidden={!accDatosItem}
          onClick={devuelveClickDatosItem}
        ><img src={imgBuscar} alt='datos item' width={widthBots} />
        </Button>
      </OverlayTrigger>
    </div>
  )
}

export default TransaccDetBotones
