// import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Dropdown, SplitButton } from 'react-bootstrap'

import restAPIAxios from '../../utils/axios'
import encryptStorage from '../../utils/encryptStorage'

import useAdmin from '../../context/Admin'
import useTransacc from '../../context/Transacc'

import { gcTiposDoc } from '../../utils/generales'

const MnuFacturas = () => {
  const [stdAdmin, dispatchAdmin, accionAdminTipo] = useAdmin()
  const [stdTransacc, dispatchTransacc, tiposAccionTransacc, tiposTarea] = useTransacc()
  const navigate = useNavigate()

  const userVSAM = encryptStorage.getItem('userVSAM')
  if (!userVSAM) return null
  // Asigna si tiene acceso a todo el botón (menú Facturación y opción Ventas)
  const accMnuFact = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_FACTURACION) === -1
  const accMnuVtas = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_VENTAS) === -1
  const accCrear = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_CREA) === -1
  const accModificar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_MOD) === -1

  const ctrlClickNuevas = async (e) => {
    if (accCrear) {
      let ok = true
      // Para que se ejecute una sola vez al presionar la opción de menú
      if (!stdAdmin.cargoDatosHoy[gcTiposDoc.fact]) {
        // Actualiza las tablas temporales con datos actuales (de hoy)
        async function temporalesHoy () {
          const respuestaAPI = await restAPIAxios('ERROR AL ACTUALIZAR REGISTROS TEMPORALES',
            {
              method: 'patch',
              url: '/facturas/nuevas/setDatosHoy',
              data: {
                poolName: stdAdmin.orclHost,
                orclUserVSAM: stdAdmin.orclUsuario,
                noUser: stdAdmin.noUsuario
              }
            }
            , false)
          return (respuestaAPI.status === 200)
        }
        ok = await temporalesHoy()
        if (ok) {
          dispatchAdmin({
            tipo: accionAdminTipo.setCargoDatosHoy,
            cargoDatosHoy: { ...stdAdmin.cargoDatosHoy, [gcTiposDoc.fact]: true }
          })
        }
      }
      if (ok) {
        // Por si acaso este modificando una Factura o ya este en CREANDO
        // Se conservan los mismos regsCab e index en caso de no cambiar la tarea
        dispatchTransacc({
          doc: gcTiposDoc.fact,
          tipo: tiposAccionTransacc.cambiaTarea,
          nuevaTarea: tiposTarea.creando,
          regsCab: stdTransacc.doc === gcTiposDoc.fact && stdTransacc.tarea === tiposTarea.creando
            ? stdTransacc.regsCab
            : undefined,
          index: stdTransacc.doc === gcTiposDoc.fact && stdTransacc.tarea === tiposTarea.creando
            ? stdTransacc.index
            : undefined
        })
        navigate('/facturas/nuevas')
      }
    } else {
      Swal.fire(
        'NO TIENE ACCESO A CREAR FACTURAS',
        'Elija otra opción de menú',
        'info'
      )
    }
  }

  function ctrlClickExistente (e) {
    // Por si acaso ya este en FILTRANDO
    // Se conservan los mismos regsCab e index en caso de no cambiar la tarea
    dispatchTransacc({
      doc: gcTiposDoc.fact,
      tipo: tiposAccionTransacc.cambiaTarea,
      nuevaTarea: tiposTarea.filtrando,
      regsCab: stdTransacc.tarea === tiposTarea.filtrando
        ? stdTransacc.regsCab
        : undefined,
      index: stdTransacc.tarea === tiposTarea.filtrando
        ? stdTransacc.index
        : undefined
    })
    navigate('/facturas/existentes')
  }

  function ctrlClickModificando (e) {
    // Por si acaso ya este en MODIFICANDO
    // Se conservan los mismos regsCab e index en caso de no cambiar la tarea
    dispatchTransacc({
      doc: gcTiposDoc.fact,
      tipo: tiposAccionTransacc.cambiaTarea,
      nuevaTarea: tiposTarea.modificando,
      regsCab: stdTransacc.tarea === tiposTarea.modificando
        ? stdTransacc.regsCab
        : undefined,
      index: stdTransacc.tarea === tiposTarea.modificando
        ? stdTransacc.index
        : undefined
    })
    navigate('/facturas/nuevas')
  }
  const oculto = stdAdmin.menuSinAcc && stdAdmin.menuSinAcc.factura
  return (
    <SplitButton
      className='bg-secondary-subtle me-3'
      size='sm'
      variant='outline-secondary text-black'
      title='Facturas'
      disabled={!accMnuFact}
      hidden={oculto}
      onClick={ctrlClickNuevas}
    >
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas || !accCrear}
      >
        <Link
          className={'dropdown-item' + ((accMnuVtas && accCrear) ? '' : ' disabled')}
          to='/facturas/nuevas' onClick={ctrlClickNuevas}
        >Nuevas
        </Link>
      </Dropdown.Item>
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas}
      >
        <Link
          className={'dropdown-item' + (accMnuVtas ? '' : ' disabled')}
          to='/facturas/existentes' onClick={ctrlClickExistente}
        >Existentes
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas || !accModificar}
      >
        <Link
          className={'dropdown-item' + (accMnuVtas && accModificar ? '' : ' disabled')}
          to='/facturas/nuevas' onClick={ctrlClickModificando}
        >Modificando
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        className='p-0'
        size='sm'
      >
        <Link
          className='dropdown-item'
          to='/facturas/reporte'
        >Reporte
        </Link>
      </Dropdown.Item>
    </SplitButton>
  )
}

export default MnuFacturas
