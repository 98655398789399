import { fechaFormat, gcFmtosColorFondo } from '../../../utils/generales'

function BarraAuditoria ({ userNo, userNombre, fModifica }) {
  return (
    <div
      className={'text-center rounded border border-primary ' + gcFmtosColorFondo.auditoria}
    >Actualizado por: ({userNo}) {userNombre} - {fechaFormat(fModifica, 'LARGA HORA')}
    </div>
  )
}
export default BarraAuditoria
