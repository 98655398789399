import { Button, Row } from 'react-bootstrap'

import {
  fechaFormat, numberFormat, gcFmtoMaxRows, gcFmtosListas, gcFmtosColorFondo
} from '../../../utils/generales'

function ListExistentes ({ registros, devuelveClickNuevoFiltro, devuelveClickListado, orden }) {
  function ctrlClickTabla (e) {
    // e.preventDefault()
    let index = e.target.getAttribute('index')
    index = parseInt(index || 0, 10)
    devuelveClickListado(index)
  }
  function ctrlClickNuevoFiltro (e) {
    // e.preventDefault()
    devuelveClickNuevoFiltro()
  }

  const fmtoOrden = 'bg-primary text-white'
  const fmtoMaxRows = gcFmtoMaxRows(registros.length)
  const fmtoListas = gcFmtosListas()
  const fmtoFijoHead = 'border border-primary text-center '

  return (
    <div className={fmtoListas.divLista}>
      <Row className={fmtoListas.rowBoton.row}>
        <Button
          className={fmtoListas.rowBoton.boton} size='sm' style={{ width: 130 }}
          onClick={ctrlClickNuevoFiltro}
        >Nuevo Filtro
        </Button>
      </Row>
      <div className={fmtoListas.tabla.div} style={{ maxHeight: 600, zIndex: '98' }}>
        <table
          className={fmtoListas.tabla.tabla}
          style={{ fontSize: '10px' }}
        >
          <caption
            className={fmtoListas.tabla.caption}
          >LISTADO DE FACTURAS SELECCIONADAS
          </caption>
          <thead className={fmtoListas.tabla.head} style={{ zIndex: '99' }}>
            {/* <tr>
              <th
                className={fmtoListas.tabla.caption} colSpan={13}
                style={{ position: 'sticky', left: 0 }}
              >LISTADO DE FACTURAS SELECCIONADAS
              </th>
            </tr> */}
            <tr>
              <th
                key={0}
                className={fmtoMaxRows.fmto}
                style={{ minWidth: fmtoMaxRows.ancho, position: 'sticky', left: 0 }}
              >{registros.length}
              </th>
              <th
                key={1} className={fmtoFijoHead + (orden === 'NO_FACTURA' ? fmtoOrden : '')}
                style={{ minWidth: 100, position: 'sticky', left: fmtoMaxRows.ancho }}
              >No. Factura
              </th>
              <th
                key={2} className={fmtoFijoHead + (orden === 'PTO_VENTA' ? fmtoOrden : '')}
                style={{ minWidth: 120 }}
              >Punto de Venta
              </th>
              <th
                key={3} className={fmtoFijoHead + (orden === 'CLI_NOMBRE' ? fmtoOrden : '')}
                style={{ minWidth: 250 }}
              >Cliente
              </th>
              <th key={4} className={fmtoFijoHead} style={{ minWidth: 40 }}>Es NC</th>
              <th key={5} className={fmtoFijoHead} style={{ minWidth: 50 }}>Anulada</th>
              <th
                key={7} className={fmtoFijoHead + (orden === 'V_TOTAL' ? fmtoOrden : '')}
                style={{ minWidth: 90 }}
              >Total
              </th>
              <th
                key={6} className={fmtoFijoHead + (orden === 'FECHA' ? fmtoOrden : '')}
                style={{ minWidth: 120 }}
              >Fecha
              </th>
              <th
                key={8} className={fmtoFijoHead + (orden === 'DIAS_CRE' ? fmtoOrden : '')}
              >Días
              </th>
              <th
                key={9} className={fmtoFijoHead + (orden === 'F_VCMTO' ? fmtoOrden : '')}
                style={{ minWidth: 60 }}
              >Vence
              </th>
              <th
                key={10} className={fmtoFijoHead + (orden === 'F_KARDEX' ? fmtoOrden : '')}
                style={{ minWidth: 60 }}
              >F. Kardex
              </th>
              <th
                key={11} className={fmtoFijoHead + (orden === 'VENDEDOR' ? fmtoOrden : '')}
                style={{ minWidth: 170 }}
              >Vendedor
              </th>
              <th
                key={12} className={fmtoFijoHead + (orden.indexOf('NO_PROFORMA') >= 0 ? fmtoOrden : '')}
                style={{ minWidth: 100 }}
              >No. Prof./Fact.
              </th>
              <th
                key={13} className={fmtoFijoHead + (orden === 'BODEGA' ? fmtoOrden : '')}
                style={{ minWidth: 120 }}
              >Bodega
              </th>
              <th
                key={14} className={fmtoFijoHead + (orden === 'OBS' ? fmtoOrden : '')}
                style={{ minWidth: 350 }}
              >Observaciones
              </th>
            </tr>
          </thead>
          <tbody
            className=''
            onClick={ctrlClickTabla}
          >
            {registros.map((registro, indexReg) => (
              <tr key={registro.NO_FACTURA}>
                <th
                  key='INDEX' id={indexReg} index={indexReg}
                  className='text-center table-primary border-bottom border-secondary-subtle'
                  style={{ position: 'sticky', left: 0 }}
                >{indexReg + 1}
                </th>
                <td
                  key='NO_FACTURA' id={registro.NO_FACTURA} index={indexReg}
                  className='text-center table-primary border-bottom border-secondary-subtle'
                  style={{ position: 'sticky', left: 40 }}
                >{registro.NO_FACTURA.substr(1)}
                </td>
                <td
                  key='PTO_VENTA' className='' id={registro.PTO_VENTA} index={indexReg}
                >{registro.PTO_VENTA}
                </td>
                <td
                  key='CLI_NOMBRE' className={registro.NO_CLIENTE === null ? gcFmtosColorFondo.noExiste : ''}
                  id={registro.CLI_NOMBRE} index={indexReg}
                >{registro.CLI_NOMBRE}
                </td>
                <td
                  key='ES_NC' className='text-center' id={registro.NO_FACTURA.substr(0, 1)} index={indexReg}
                >{registro.NO_FACTURA.substr(0, 1) === 'B' ? '✓' : ''}
                </td>
                <td
                  key='ESTADO' className='text-center' id={registro.ESTADO} index={indexReg}
                >{registro.ESTADO === 'A' ? '✓' : ''}
                </td>
                <td
                  key='TOTAL' className='text-end' id={registro.V_TOTAL} index={indexReg}
                >{numberFormat(registro.V_TOTAL, 2)}
                </td>
                <td
                  key='FECHA' className='text-center' id={registro.FECHA} index={indexReg}
                >{fechaFormat(registro.FECHA, 'MEDIA LARGA HORA')}
                </td>
                <td
                  key='DIAS_CRE' className='text-center' id={registro.DIAS_CRE} index={indexReg}
                >{registro.DIAS_CRE}
                </td>
                <td
                  key='F_VCMTO' className='text-center' id={registro.F_VCMTO} index={indexReg}
                >{fechaFormat(registro.F_VCMTO, 'AMD')}
                </td>
                <td
                  key='F_KARDEX' className='text-center' id={registro.F_KARDEX} index={indexReg}
                >{fechaFormat(registro.F_KARDEX, 'AMD')}
                </td>
                <td
                  key='VENDEDOR' className='' id={registro.VENDEDOR} index={indexReg}
                >{registro.VENDEDOR}
                </td>
                <td
                  key='NO_PROFORMA' index={indexReg}
                  className='text-center' id={registro.NO_PROFORMA}
                >{registro.NO_FACTURA.substring(0, 1) === 'B' ? registro.EXTRA8_TXT : registro.NO_PROFORMA}
                </td>
                <td
                  key='BODEGA' className='' id={registro.BODEGA} index={indexReg}
                >{registro.BODEGA}
                </td>
                <td
                  key='OBS' className='' id={registro.OBS} index={indexReg}
                >{registro.OBS}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ListExistentes
