import { memo } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import useAdmin from '../../context/Admin'
import MenuPrincipal from './MenuPrincipal'
import CierraSesion from './CierraSesion'

const Menu = () => {
  const [stdAdmin] = useAdmin()

  if (!stdAdmin.auth) return null

  return (
    <Container className=''>
      <Row className=''>
        <Col>
          <MenuPrincipal />
        </Col>
        <Col className=''>
          <CierraSesion />
        </Col>
      </Row>
    </Container>
  )
}

export default memo(Menu)
