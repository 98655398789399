import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Button, Row, Col, Form, OverlayTrigger, Tooltip, SplitButton, Dropdown } from 'react-bootstrap'

import restAPIAxios from '../../../utils/axios'
import encryptStorage from '../../../utils/encryptStorage'

import useAdmin from '../../../context/Admin'
import useTransacc from '../../../context/Transacc'
import { gcFmtosBotones } from '../../../utils/generales'

import imgGrabar from '../../../iconos/GRABAR.BMP'
import imgCancelar from '../../../iconos/CANCELAR.ICO'
import imgModificar from '../../../iconos/MODIFICAR.ICO'
import imgFiltrar from '../../../iconos/FILTRAR.ICO'
import imgListado from '../../../iconos/LISTADO.BMP'
import imgImprimir from '../../../iconos/IMPRIMIR.ICO'
import imgMail from '../../../iconos/MAIL.ICO'

import imgMas from '../../../iconos/MAS.ICO'
import imgMenos from '../../../iconos/MENOS.ICO'

import imgPrimero from '../../../iconos/PRIMERO.ICO'
import imgAnterior from '../../../iconos/ANTERIOR.PNG'
import imgAnterior2 from '../../../iconos/ANTERIOR2.ICO'
import imgSiguiente from '../../../iconos/SIGUIENTE.PNG'
import imgSiguiente2 from '../../../iconos/SIGUIENTE2.ICO'
import imgUltimo from '../../../iconos/ULTIMO.ICO'

function Botones (props) {
  const totalRegs = props.totalRegs
  const editando = props.editando
  const recargarCab = props.recargarCab

  const [stdAdmin] = useAdmin()
  const [stdTransacc, dispatchTransacc, tiposAccionTransacc, tiposTarea] = useTransacc()
  const navigate = useNavigate()

  const [grabando, setGrabando] = useState(false)

  const userVSAM = encryptStorage.getItem('userVSAM')
  let accCejaProf = false
  let accModificar = false
  let accEliminar = false
  if (userVSAM) {
    accCejaProf = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_CEJA) === -1
    accModificar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_MOD) === -1
    accEliminar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_ELI) === -1
  }
  const poolName = stdAdmin.orclHost

  // Extrae los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')

  function ctrlClickSiguiente (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      cantRegs: 1
    })
    props.devuelveSiguiente()
  }
  function ctrlClick10Sig (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      cantRegs: 10
    })
    props.devuelve10Sig()
  }
  function ctrlClickUltima (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      index: stdTransacc.regsCab.length - 1
    })
    props.devuelveUltima()
  }

  function ctrlClickAnterior (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      cantRegs: -1
    })
    props.devuelveAnterior()
  }
  function ctrlClick10Ant (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      cantRegs: -10
    })
    props.devuelve10Ant()
  }
  function ctrlClickPrimera (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      index: 0
    })
    props.devuelvePrimera()
  }

  function ctrlClickNuevoReg (e) {
    const maxNuevas = userEmpConfig.numeros_maximos ? userEmpConfig.numeros_maximos.profNuevas : 5
    if (totalRegs >= maxNuevas) {
      Swal.fire(
        'NO ES POSIBLE CREAR MAS PROFORMAS NUEVAS',
        `El número máximo de proformas nuevas permitido es ${maxNuevas}`,
        'info'
      )
      return
    }
    async function insNuevoReg () {
      setGrabando(true)
      props.estaGrabando({ enProceso: true, msjPantalla: 'Creando nuevo registro...' })
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CREAR UNA NUEVA PROFORMA',
        {
          method: 'post',
          url: '/proformas/nuevas',
          data: {
            poolName,
            orclUserVSAM: stdAdmin.orclUsuario,
            noEmp: stdAdmin.noEmpresa,
            noUser: stdAdmin.noUsuario
          }
        }
      )
      setGrabando(false)
      props.estaGrabando({ enProceso: false, msjPantalla: '' })
      if (respuestaAPI.status === 200) {
        dispatchTransacc({
          tipo: tiposAccionTransacc.nuevoReg,
          nuevaClave: respuestaAPI.data[0].NO_REG_CREADO
        })
        recargarCab()
      }
    }
    insNuevoReg()
  }
  function ctrlClickEliminaReg (e) {
    async function deleteReg () {
      setGrabando(true)
      props.estaGrabando({ enProceso: true, msjPantalla: 'Eliminando el registro...' })
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ELIMINAR EL REGISTRO',
        {
          method: 'delete',
          url: `/proformas/nuevas/${stdTransacc.clave}`,
          data: { poolName }
        }
      )
      setGrabando(false)
      props.estaGrabando({ enProceso: false, msjPantalla: '' })
      if (respuestaAPI.status === 200) {
        dispatchTransacc({
          tipo: tiposAccionTransacc.eliminaReg,
          index: stdTransacc.index
        })
      }
    }
    Swal.fire({
      title: 'CONFIRME',
      text: `Desea eliminar el registro ${stdTransacc.index + 1}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) deleteReg()
    })
  }
  function ctrlClickGrabar (e) {
    // e.preventDefault()
    if (props.profValida()) {
      async function grabaProforma () {
        // Se asigna el NO_PROFORMA final digitado por el usuario
        const noProforma = regActual.NO_PROFORMA
        setGrabando(true)
        props.estaGrabando({ enProceso: true, msjPantalla: 'Grabando la Proforma...' })
        const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE ${modificando ? 'MODIFICAR' : 'GRABAR'} LA PROFORMA`,
          {
            method: 'post',
            url: modificando ? '/proformas/modifica' : '/proformas/crea',
            data: {
              poolName,
              orclUserVSAM: stdAdmin.orclUsuario,
              noEmp: stdAdmin.noEmpresa,
              noUser: stdAdmin.noUsuario,
              noReg: stdTransacc.clave,
              noProforma: regActual.NO_PROFORMA,
              noProfMax: userEmpConfig.numeros_maximos.proforma
            }
          }
        )
        setGrabando(false)
        props.estaGrabando({ enProceso: false, msjPantalla: '' })
        if (respuestaAPI.status === 200) {
          // Se asigna el nuevo NO_PROFORMA creada
          // o el NO_PROFORMA_MOD (número original) si está modificando
          const noProfTmp = modificando ? stdTransacc.regsCab[stdTransacc.index].NO_PROFORMA_MOD : respuestaAPI.data[0].NO_PROFORMA
          Swal.fire(
            `LA PROFORMA SE ${modificando ? 'MODIFICO' : 'GRABO'} CON EXITO`,
            noProforma === noProfTmp
              ? ''
              : modificando
                ? `Cambió el número de proforma ${noProfTmp} por ${noProforma}`
                : `El sistema grabó la factura con el No. ${noProfTmp}`,
            'success'
          )
          console.log(respuestaAPI.data)
          if (modificando) {
            // Si se pudo recuperar la nueva proforma modificada y envía a existentes para verla
            if (respuestaAPI.data.length) {
              props.devuelveNuevoReg(respuestaAPI.data)
              dispatchTransacc({
                tipo: tiposAccionTransacc.cambiaTarea,
                nuevaTarea: tiposTarea.consultando,
                regsCab: respuestaAPI.data
              })
              navigate('/proformas/existentes')
            // No se pudo recuperar la nueva proforma modificada y se va a filtrando
            } else {
              dispatchTransacc({
                tipo: tiposAccionTransacc.cambiaTarea,
                nuevaTarea: tiposTarea.filtrando
              })
            }
          // Devuelve la proforma creada para que aparezca directamente en existentes
          } else {
            console.log(respuestaAPI.data)
            props.devuelveNuevoReg(respuestaAPI.data)
          }
        } else {
          if (respuestaAPI.response.status === 500 && respuestaAPI.response.data.yaExiste) {
            Swal.fire(
              'LA PROFORMA YA EXISTE',
              'Cambie el número de proforma y vuelva a intentarlo',
              'info'
            )
          }
        }
      }
      Swal.fire({
        title: 'CONFIRME',
        text: 'Los datos ingresados son correctos?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO'
      }).then((result) => {
        if (result.isConfirmed) grabaProforma()
      })
    }
  }
  async function ctrClickModificar (e) {
    // e.preventDefault()
    setGrabando(true)
    props.estaGrabando({ enProceso: true, msjPantalla: 'Preparando la modificación...' })
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE PREPARAR LA PROFORMA PARA SU MODIFICACION',
      {
        method: 'post',
        url: '/proformas/preModifica',
        data: {
          poolName,
          orclUserVSAM: stdAdmin.orclUsuario,
          noEmp: stdAdmin.noEmpresa,
          noUser: stdAdmin.noUsuario,
          noProforma: props.children
        }
      }
    )
    setGrabando(false)
    props.estaGrabando({ enProceso: false, msjPantalla: '' })
    if (respuestaAPI.status === 200) {
      // Coloca la clave devuelta (NO_REG) del registro creado para la modificación
      dispatchTransacc({
        tipo: tiposAccionTransacc.cambiaTarea,
        nuevaTarea: tiposTarea.modificando,
        regsCab: [{ NO_REG: respuestaAPI.data[0] }]
      })
      navigate('/proformas/nuevas')
    } else {
      if (respuestaAPI.response.status === 500 && respuestaAPI.response.data.yaExiste) {
        Swal.fire(
          'ESTA PROFORMA YA ESTA EN PROCESO DE MODIFICACION',
          'Primero cancele la modificación pendiente',
          'info'
        )
      }
    }
  }

  if (!stdAdmin.auth || !userVSAM || !stdTransacc.regsCab.length) return null

  const regActual = stdTransacc.regsCab.length
    ? stdTransacc.regsCab[stdTransacc.index]
    : []

  const modificando = stdTransacc.tarea === tiposTarea.modificando
  const creando = (stdTransacc.tarea === tiposTarea.creando)
  const consultando = (stdTransacc.tarea === tiposTarea.consultando)
  const fmtoBotsImg = gcFmtosBotones.imagen
  const widthBots = gcFmtosBotones.imgWidth

  return (
    <Row className='justify-content-around ms-0 bg-primary-subtle rounded'>
      {/* LADO IZQUIERDO DE LA FILA DE BOTONES */}
      <Col className='' xs='auto'>
        <OverlayTrigger overlay={<Tooltip>{consultando ? 'nuevo filtro' : 'existentes'}</Tooltip>}>
          <Button
            className={fmtoBotsImg} size='sm' disabled={grabando}
            onClick={(e) => props.devuelveNuevoFiltro()}
          ><img src={imgFiltrar} alt='Filtrar' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>modificar</Tooltip>}>
          <Button
            className={fmtoBotsImg} size='sm' disabled={grabando}
            hidden={!accModificar || creando || modificando || regActual.ESTADO === 'A'}
            onClick={ctrClickModificar}
          ><img src={imgModificar} alt='modificar' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>{modificando ? 'grabar modificación' : 'grabar'}</Tooltip>}>
          <Button
            className={fmtoBotsImg} size='sm' disabled={grabando}
            hidden={!accCejaProf || consultando}
            onClick={ctrlClickGrabar}
          ><img src={imgGrabar} alt='grabar' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>{modificando ? 'cancelar modificación' : 'cancelar'}</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando}
            hidden={consultando}
            onClick={(e) => props.devuelveCancelar()}
          ><img src={imgCancelar} alt='cancelar' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>nueva proforma</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando}
            hidden={!accCejaProf || consultando || modificando}
            onClick={ctrlClickNuevoReg}
          ><img src={imgMas} alt='crear' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>elimina proforma {consultando ? props.children : stdTransacc.index + 1}</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || totalRegs <= 1}
            hidden={modificando || (consultando && (!accEliminar || regActual.ESTADO !== 'A'))}
            onClick={ctrlClickEliminaReg}
          ><img src={imgMenos} alt='eliminar' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>listado</Tooltip>}>
          <Button
            className={fmtoBotsImg} size='sm' hidden={!consultando}
            onClick={(e) => dispatchTransacc({ tipo: tiposAccionTransacc.setListar })}
          ><img src={imgListado} alt='Listado' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>Email Cliente</Tooltip>}>
          <Button
            className={fmtoBotsImg} size='sm' hidden={!consultando}
            onClick={(e) => props.devuelveRepIndividual('CLIENTE', true)}
          ><img src={imgMail} alt='Email Cliente' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>imprimir</Tooltip>}>
          <SplitButton
            className={gcFmtosBotones.imgConMenu} variant='' size='sm'
            disabled={grabando} hidden={!consultando}
            title={(<img src={imgImprimir} alt='imprimir' width={widthBots} />)}
            onClick={(e) => props.devuelveRepIndividual('CLIENTE')}
          >
            <Dropdown.Item
              className={gcFmtosBotones.dropDownItem}
              onClick={(e) => props.devuelveRepIndividual('CLIENTE')}
            >Cliente
            </Dropdown.Item>
            <Dropdown.Item
              className={gcFmtosBotones.dropDownItem}
              onClick={(e) => props.devuelveRepIndividual('INTERNO')}
            >Interno
            </Dropdown.Item>
          </SplitButton>
        </OverlayTrigger>
      </Col>

      {/* TEXTO EN LA MITAD DE LA FILA DE BOTONES */}
      <Col xs='auto'>
        <Form.Label className='fs-6'>{stdTransacc.index + 1}/{totalRegs}</Form.Label>
      </Col>

      {/* LADO DERECHO DE LA FILA DE BOTONES */}
      <Col className='' xs='auto'>
        <OverlayTrigger overlay={<Tooltip>primera proforma</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index === 0}
            onClick={ctrlClickPrimera}
          ><img src={imgPrimero} alt='primero' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>10 anteriores</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index <= 9}
            onClick={ctrlClick10Ant}
          ><img src={imgAnterior2} alt='anterior' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>proforma anterior</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index === 0}
            onClick={ctrlClickAnterior}
          ><img src={imgAnterior} alt='anterior' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>proforma siguiente</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index === totalRegs - 1}
            onClick={ctrlClickSiguiente}
          ><img src={imgSiguiente} alt='siguiente' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>10 siguientes</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index > totalRegs - 11}
            onClick={ctrlClick10Sig}
          ><img src={imgSiguiente2} alt='siguiente' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>última proforma</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index === totalRegs - 1}
            onClick={ctrlClickUltima}
          ><img src={imgUltimo} alt='ultimo' width={widthBots} />
          </Button>
        </OverlayTrigger>
      </Col>
    </Row>
  )
}

export default Botones
