/* eslint-disable react/jsx-indent */
import { Button, Col, OverlayTrigger, Tooltip, Row } from 'react-bootstrap'

import useAdmin from '../../../context/Admin.jsx'

import { gcFmtosBotones, gcTipoEditTablas } from '../../../utils/generales.js'

import imgImprimir from '../../../iconos/IMPRIMIR.ICO'

import imgPrimero from '../../../iconos/PRIMERO.ICO'
import imgAnterior from '../../../iconos/ANTERIOR.PNG'
import imgAnterior2 from '../../../iconos/ANTERIOR2.ICO'
import imgSiguiente from '../../../iconos/SIGUIENTE.PNG'
import imgSiguiente2 from '../../../iconos/SIGUIENTE2.ICO'
import imgUltimo from '../../../iconos/ULTIMO.ICO'

function Botones ({ estado, dispatch, acciones }) {
  const totalRegs = estado.tabla.length
  const [stdAdmin] = useAdmin()

  if (!stdAdmin.auth) return null

  const estaCreando = (estado.tipoEdit === gcTipoEditTablas.creando)
  const fmtoBotsImg = gcFmtosBotones.imagen
  const widthBots = gcFmtosBotones.imgWidth
  return (
    <Row
      className='justify-content-around ms-0 bg-primary-subtle rounded'
    >
      {/* LADO IZQUIERDO (BOTONES DIFERENTES DE MOVIMIENTO) */}
      <Col className='' xs={estaCreando ? 2 : 'auto'}>
        <OverlayTrigger overlay={<Tooltip>reporte PDF</Tooltip>}>
          <button
            className={fmtoBotsImg}
            onClick={(e) => console.log('Imprime reporte...')}
            disabled={!estado.tabla.length}
          ><img src={imgImprimir} alt='Reporte' width={widthBots} />
          </button>
        </OverlayTrigger>
      </Col>
      {/* TEXTO EN LA MITAD (TITULO Y CONTEO) */}
      <Col className='' xs={estaCreando ? 10 : 'auto'}>
        <div className='d-flex justify-content-center pt-1'>
          <span hidden={estaCreando}>{estado.index + 1}/{totalRegs}</span>
        </div>
      </Col>
      {/* LADO DERECHO (BOTONES DE MOVIMIENTO) */}
      {estaCreando
        ? null
        : <Col className='' xs='auto'>
        <OverlayTrigger overlay={<Tooltip>primer registro</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={estado.index <= 0}
            onClick={(e) => dispatch({ tipo: acciones.cambiaReg, index: 0 })}
          ><img src={imgPrimero} alt='primero' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>10 anteriores</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={estado.index <= 9}
            onClick={(e) => dispatch({ tipo: acciones.cambiaReg, cantRegs: -10 })}
          ><img src={imgAnterior2} alt='anterior2' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>anterior registro</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={estado.index <= 0}
            onClick={(e) => dispatch({ tipo: acciones.cambiaReg, cantRegs: -1 })}
          ><img src={imgAnterior} alt='anterior' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>siguiente registro</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={estado.index === totalRegs - 1}
            onClick={(e) => dispatch({ tipo: acciones.cambiaReg, cantRegs: 1 })}
          ><img src={imgSiguiente} alt='siguiente' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>10 siguientes</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={estado.index > totalRegs - 11}
            onClick={(e) => dispatch({ tipo: acciones.cambiaReg, cantRegs: 10 })}
          ><img src={imgSiguiente2} alt='siguiente2' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>último registro</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={estado.index === totalRegs - 1}
            onClick={(e) => dispatch({ tipo: acciones.cambiaReg, index: estado.tabla.length - 1 })}
          ><img src={imgUltimo} alt='ultimo' width={widthBots} />
          </Button>
        </OverlayTrigger>
          </Col>}
    </Row>
  )
}

export default Botones
