import { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import Swal from 'sweetalert2'

import { numberFormat } from '../../utils/generales'

function CampoNumerico (props) {
  const {
    idName, devuelveVal, fmtoInput, decimales, obligatorio, deshabilitado, soloPositivo,
    min, max, filtrando, style, sinDivMiles, ocultar, autoFocus, children
  } = props
  const [editando, setEditando] = useState(false)
  const [valor, setValor] = useState(0)
  const valorFmto = useRef(0)
  const yaSeleccione = useRef(false)

  useEffect(() => {
    if (!editando) setValor(children)
    // Solo para los casos donde se necesita que vaya una palabra no editable, en lugar de un valor
    // Ej. Existencias: --servicio--
    if (isNaN(children) || children === '') {
      valorFmto.current = children
    } else {
      valorFmto.current = numberFormat(children === undefined ? 0 : children, decimales, sinDivMiles)
    }
  }, [decimales, editando, idName, children, sinDivMiles])

  function ctrlFocus (e) {
    // e.preventDefault()
    setEditando(true)
  }
  // Se usa solo para volver a seleccionar los datos al recibir el foco,
  // porque la selección se pierde cuando cambia el valor (sin Fmto.) al tomar el foco
  function ctrlSelect (e) {
    if (!yaSeleccione.current) {
      // e.preventDefault()
      e.target.select()
      yaSeleccione.current = true
    }
  }
  function ctrlChange (e) {
    // e.preventDefault()
    if (soloPositivo && e.target.value < 0) {
      e.target.value = -e.target.value
      Swal.fire(
        'EL VALOR NO PUEDE SER NEGATIVO',
        '',
        'info'
      )
    } else if (e.target.value < min) {
      e.target.value = min
      Swal.fire(
        'EL VALOR NO PUEDE SER MENOR A ' + min,
        '',
        'info'
      )
    } else if (e.target.value > max) {
      e.target.value = max
      Swal.fire(
        'EL VALOR NO PUEDE SER MAYOR A ' + max,
        '',
        'info'
      )
    }
    setValor(e.target.value)
    if (props.actualizaEnChange) {
      devuelveVal(idName, numberFormat(e.target.value.replaceAll(',', ''), decimales, true))
    }
  }
  function ctrlBlur (e) {
    // e.preventDefault()
    yaSeleccione.current = false
    setEditando(false)
    let valorSinFmto
    if (!filtrando && isNaN(e.target.value)) {
      Swal.fire(
        'EL VALOR DEBE SER NUMERICO',
        '',
        'info'
      )
      valorSinFmto = 0
      valorFmto.current = numberFormat(valorSinFmto, decimales, sinDivMiles)
    } else {
      if (e.target.value === '') {
        valorSinFmto = ''
        valorFmto.current = ''
      } else
        if (filtrando) {
          valorSinFmto = e.target.value
          valorFmto.current = valorSinFmto
        } else {
          valorSinFmto = numberFormat(e.target.value.replaceAll(',', ''), decimales, true)
          valorFmto.current = numberFormat(valorSinFmto, decimales, sinDivMiles)
        }
    }
    if (valorSinFmto !== children) {
      devuelveVal(idName, valorSinFmto === 0 && children === '' ? '' : valorSinFmto)
    }
  }

  return (
    <Form.Control
      id={idName}
      className={(isNaN(children) ? 'text-center ' : 'text-end ') + fmtoInput}
      style={style === undefined ? {} : style}
      type='text'
      autoFocus={autoFocus}
      value={editando ? valor : valorFmto.current}
      min={min}
      max={max}
      required={obligatorio}
      disabled={deshabilitado}
      hidden={ocultar}
      onFocus={ctrlFocus}
      onSelect={ctrlSelect}
      onChange={ctrlChange}
      onBlur={ctrlBlur}
    />
  )
}

export default CampoNumerico
