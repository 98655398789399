import { useEffect, useState } from 'react'
import { Container, InputGroup, Form, Button, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2'

import restAPIAxios from '../../../utils/axios'
import encryptStorage from '../../../utils/encryptStorage'
import useAdmin from '../../../context/Admin'

import {
  gcTiposCliente, gcCliCreIniciaDiaMes, gcCliCrePeriodoMensual, infoVendedorSel,
  gcTipoEditTablas, colocaFocoDirecto, gcFmtosColorFondo, gcFmtosBotones,
  anchoPantDisponible
} from '../../../utils/generales'
import '../../../styles/MiBoton.css'

import CampoNumerico from '../../generales/CampoNumerico'
import TablaCampoFecha from '../../generales/tablas/TablaCampoFecha'
import TablaOpcion from '../../generales/tablas/TablaOpcion'

import imgArriba from '../../../iconos/ARRIBA.ICO'

function Cliente ({
  opcMenuCall, idRowFiltro, disableBotMod, estado, dispatch, acciones,
  devuelveEsperando, devuelveCreaModCli, devuelveEliminaReg
}) {
  const [stdAdmin] = useAdmin()

  const poolName = stdAdmin.orclHost

  const [regActual, setRegActual] = useState({})

  // Extrae los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')

  // Variables de Acceso
  let accDsctoVtas = false
  let accDiasCre = false
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (userVSAM) {
    accDsctoVtas = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_VTAS_DSCTO_MOD) === -1
    accDiasCre = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_DIAS_CRE) === -1
  }

  const estaCreando = (estado.tipoEdit === gcTipoEditTablas.creando)
  useEffect(() => {
    setRegActual(estado.regEdit)
    colocaFocoDirecto('ID')
  }, [estado.regEdit])

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  function ctrlChangeCampo (e) {
    // e.preventDefault()
    setRegActual({ ...regActual, [e.target.id]: e.target.value })
  }
  function ctrlGrabar (e) {
    e.preventDefault()
    if (disableBotMod) return
    async function grabar () {
      devuelveEsperando(true)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE ${estaCreando ? 'CREAR' : 'MODIFICAR'} EL CLIENTE`,
        {
          method: estaCreando ? 'post' : 'patch',
          url: estaCreando ? '/cliente/crea' : '/cliente/modifica',
          data: {
            poolName,
            orclUserVSAM: stdAdmin.orclUsuario,
            noUser: stdAdmin.noUsuario,
            noEmp: stdAdmin.noEmpresa,
            datos: regActual
          }
        }
      )
      devuelveEsperando(false)
      if (respuestaAPI.status === 200) {
        devuelveCreaModCli(estaCreando ? respuestaAPI.data.registro : regActual, estaCreando)
        // Mensajes finales después de crear el cliente
        // Mensaje cuando el cliente ya estaba creado en T_CLIENTE
        if (estaCreando) {
          if (respuestaAPI.data.yaExiste.cliente) {
            Swal.fire(
              'EL CLIENTE YA EXISTIA',
              'El sistema seleccionó los datos del cliente existente',
              'info'
            )
          // Mensaje cuando el cliente ya estaba creado en T_INSTITUCION
          } else if (respuestaAPI.data.yaExiste.instit) {
            Swal.fire(
              'EL CLIENTE YA EXISTIA COMO INSTITUCION',
              'Se creó el cliente con los datos existentes de la institución más los otros digitados por el usuario',
              'info'
            )
          }
        }
      }
    }
    Swal.fire({
      title: 'CONFIRME',
      text: 'Los datos ingresados son correctos?',
      footer: estaCreando
        ? 'Va a crear el nuevo cliente'
        : `Va a modificar los datos del cliente (${estado.regEdit.ID} ${estado.regEdit.NOMBRE}) `,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) grabar()
    })
  }
  const fmtoBotsImg = gcFmtosBotones.imagen
  const widthBots = gcFmtosBotones.imgWidth
  return (
    <Row
      className={opcMenuCall ? 'ms-2 mb-3' : 'ms-3 me-3 mt-1 mb-3'}
      style={{ width: opcMenuCall ? anchoPantDisponible(idRowFiltro) - 20 : undefined }}
    >
      {/* DATOS DEL CLIENTE */}
      <Container
        className={'pb-2 shadow rounded border' + (estaCreando ? ' border-secondary' : ' border-success')}
      >
        <div
          className={'d-flex justify-content-center align-items-center ms-2 me-2 mb-2 rounded border border-secondary' +
            (estaCreando
              ? `${gcFmtosColorFondo.nuevoReg}`
              : disableBotMod
                ? `${gcFmtosColorFondo.msjeInfo}`
                : `${gcFmtosColorFondo.editandoReg}`)}
        >
          <OverlayTrigger overlay={<Tooltip>regresar</Tooltip>}>
            <button
              className={fmtoBotsImg + ' me-3'}
              onClick={(e) => dispatch({ tipo: acciones.vacia, filtro: estado.filtro })}
              disabled={false}
            ><img src={imgArriba} alt='Regresar' width={widthBots} />
            </button>
          </OverlayTrigger>
          <span className='text-bod fw-semibold'>{estaCreando
            ? 'DATOS DEL NUEVO CLIENTE'
            : disableBotMod
              ? 'DATOS DEL CLIENTE'
              : 'MODIFICANDO LOS DATOS DEL CLIENTE'}
          </span>
        </div>
        <Form onSubmit={ctrlGrabar}>
          {/* ID (ruc, ci, pasaporte), TIPO, CATEGORIA Y SIGLAS */}
          <Row className=''>
            {/* ID (ruc, ci, pasaporte) */}
            <Col>
              <InputGroup
                className='mb-1'
                size='sm'
                style={{ minWidth: 270, maxWidth: 300 }}
              >
                <InputGroup.Text
                  className='' size='sm' id='lblID'
                >ID (ruc, ci, pasaporte):
                </InputGroup.Text>
                <Form.Control
                  className=''
                  id='ID'
                  type='text'
                  autoFocus
                  required
                  disabled={disableBotMod}
                  maxLength={15}
                  aria-describedby='lblID'
                  value={regActual.ID}
                  onChange={ctrlChangeCampo}
                />
              </InputGroup>
            </Col>
            {/* TIPO */}
            <Col>
              <InputGroup
                className='mb-1'
                size='sm'
              >
                <InputGroup.Text
                  className='' size='sm' id='lblTIPO'
                >Tipo:
                </InputGroup.Text>
                <Form.Select
                  className='' size='sm'
                  id='TIPO'
                  disabled={disableBotMod}
                  style={{ minWidth: 160, maxWidth: 170 }}
                  value={regActual.TIPO}
                  onChange={ctrlChangeCampo}
                >{Object.keys(gcTiposCliente).map((valor, index) =>
                  (<option key={index} value={valor}>{gcTiposCliente[valor]}</option>)
                )}
                </Form.Select>
              </InputGroup>
            </Col>
            {/* CATEGORIA */}
            <Col>
              <InputGroup
                className='mb-1'
                size='sm'
                style={{ width: 180 }}
              >
                <InputGroup.Text
                  className='' size='sm' id='lblCATEGORIA'
                >Categoría:
                </InputGroup.Text>
                <Form.Control
                  className=''
                  id='CATEGORIA'
                  type='text'
                  disabled={disableBotMod}
                  maxLength={10}
                  aria-describedby='lblCATEGORIA'
                  value={regActual.CATEGORIA ?? ''}
                  onChange={ctrlChangeCampo}
                />
              </InputGroup>
            </Col>
            {/* SIGLAS */}
            <Col xs='auto'>
              <InputGroup
                className='mb-1'
                size='sm'
                style={{ width: 160 }}
              >
                <InputGroup.Text
                  className='' size='sm' id='lblSIGLAS'
                >Siglas:
                </InputGroup.Text>
                <Form.Control
                  className=''
                  id='SIGLAS'
                  type='text'
                  disabled={disableBotMod}
                  maxLength={10}
                  aria-describedby='lblSIGLAS'
                  value={regActual.SIGLAS ?? ''}
                  onChange={ctrlChangeCampo}
                />
              </InputGroup>
            </Col>
          </Row>
          {/* NOMBRE */}
          <InputGroup
            className='mb-1'
            size='sm'
          >
            <InputGroup.Text className='' size='sm' id='lblNOMBRE'>Nombre:</InputGroup.Text>
            <Form.Control
              className=''
              id='NOMBRE'
              type='text'
              required
              disabled={disableBotMod}
              maxLength={64}
              aria-describedby='lblNOMBRE'
              value={regActual.NOMBRE ?? ''}
              onChange={ctrlChangeCampo}
            />
          </InputGroup>
          {/* DIRECCION */}
          <InputGroup
            className='mb-1'
            size='sm'
          >
            <InputGroup.Text className='' size='sm' id='lblDIRECC'>Dirección:</InputGroup.Text>
            <Form.Control
              className=''
              id='DIRECC'
              type='text'
              disabled={disableBotMod}
              maxLength={64}
              aria-describedby='lblDIRECC'
              value={regActual.DIRECC ?? ''}
              onChange={ctrlChangeCampo}
            />
          </InputGroup>
          {/* TELEFONO Y FAX */}
          <Row className=''>
            <Col>
              <InputGroup
                className='mb-1'
                size='sm'
                style={{ minWidth: 300 }}
              >
                <InputGroup.Text
                  className='' size='sm' id='lblTELEF'
                >Teléfonos:
                </InputGroup.Text>
                <Form.Control
                  className=''
                  id='TELEF'
                  type='text'
                  disabled={disableBotMod}
                  maxLength={30}
                  aria-describedby='lblTELEF'
                  value={regActual.TELEF ?? ''}
                  onChange={ctrlChangeCampo}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup
                className='mb-1'
                size='sm'
                style={{ minWidth: 300 }}
              >
                <InputGroup.Text
                  className='' size='sm' id='lblFAX'
                >{userEmpConfig.textos_labels.cliFax || 'Fax'}:
                </InputGroup.Text>
                <Form.Control
                  className=''
                  id='FAX'
                  type='text'
                  disabled={disableBotMod}
                  maxLength={30}
                  aria-describedby='lblFAX'
                  value={regActual.FAX ?? ''}
                  onChange={ctrlChangeCampo}
                />
              </InputGroup>
            </Col>
          </Row>
          {/* DSCTO, NO_PV, DIAS CREDITO Y FECHA DESDE */}
          <Row className=''>
            {/* P_DSCTO */}
            <Col>
              <InputGroup className='' size='sm' style={{ width: 160 }}>
                <InputGroup.Text id='lblPDscto'>Dscto.:</InputGroup.Text>
                <CampoNumerico
                  idName='P_DSCTO' min={0} max={100}
                  devuelveVal={(campo, valor) => setRegActual({ ...regActual, [campo]: valor })}
                  fmtoInput='' decimales={userEmpConfig.decimales} soloPositivo
                  deshabilitado={disableBotMod || !accDsctoVtas}
                >{regActual.P_DSCTO}
                </CampoNumerico>
                <InputGroup.Text id='' className=''>%</InputGroup.Text>
              </InputGroup>
            </Col>
            {/* NO_PV */}
            <Col>
              <InputGroup className='' size='sm' style={{ width: 110 }}>
                <InputGroup.Text id='lblNO_PV'>No. PV:</InputGroup.Text>
                <CampoNumerico
                  idName='NO_PV' min={0} max={99}
                  devuelveVal={(campo, valor) => setRegActual({ ...regActual, [campo]: valor })}
                  fmtoInput='' decimales={0} soloPositivo
                  deshabilitado={disableBotMod}
                >{regActual.NO_PV}
                </CampoNumerico>
              </InputGroup>
            </Col>
            {/* V_INI_X_COB (Días crédito) */}
            <Col>
              <InputGroup className='' size='sm' style={{ width: 150 }}>
                <InputGroup.Text id='lblPDscto'>Días crédito:</InputGroup.Text>
                <CampoNumerico
                  idName='V_INI_X_COB' fmtoInput='' decimales={0}
                  devuelveVal={(campo, valor) => setRegActual({ ...regActual, [campo]: valor })}
                  deshabilitado={disableBotMod || !accDiasCre} soloPositivo
                  min={0}
                >{regActual.V_INI_X_COB}
                </CampoNumerico>
              </InputGroup>
            </Col>
            {/* F_DESDE */}
            <Col xs='auto'>
              <TablaCampoFecha
                infoCampo={
                            {
                              fmto: ['mb-1 me-2', '', ''],
                              id: 'F_DESDE',
                              label: 'Cliente desde:',
                              required: false,
                              disabled: disableBotMod,
                              condFiltro: '',
                              showHora: false,
                              dias: 0
                            }
                          }
                valTabla={regActual.F_DESDE}
                devuelveVal={(valor) => setRegActual({ ...regActual, F_DESDE: valor })}
                devuelveEditando={(valor) => { /* no debe hacer nada, pero es necesario enviar la funcion */ }}
                devuelveCond={(valor) => { /* no debe hacer nada, pero es necesario enviar la funcion */ }}
              />
            </Col>
          </Row>
          {/* EMAIL */}
          <InputGroup
            className='mb-1'
            size='sm'
          >
            <InputGroup.Text
              className='' size='sm' id='lblEMAIL'
            >{userEmpConfig.textos_labels.cliEmail || 'Email'}:
            </InputGroup.Text>
            <Form.Control
              className=''
              id='EMAIL'
              type='text'
              disabled={disableBotMod}
              maxLength={250}
              aria-describedby='lblEMAIL'
              value={regActual.EMAIL ?? ''}
              onChange={ctrlChangeCampo}
            />
          </InputGroup>
          {/* ATENCION PERSONA y VENDEDOR */}
          <Row>
            <Col>
              <InputGroup
                className='mb-1'
                size='sm'
              >
                <InputGroup.Text className='' size='sm' id='lblATTPERSONA'>Att. Persona:</InputGroup.Text>
                <Form.Control
                  className=''
                  id='ATTPERSONA'
                  type='text'
                  disabled={disableBotMod}
                  maxLength={64}
                  aria-describedby='lblATTPERSONA'
                  value={regActual.ATTPERSONA ?? ''}
                  onChange={ctrlChangeCampo}
                />
              </InputGroup>
            </Col>
            <Col>
              <TablaOpcion
                valTabla={regActual.NO_VENDEDOR}
                infoTablaLista={infoVendedorSel(['mb-1', '', ''], (userEmpConfig.textos_labels.cliVendedor || 'Vendedor'))}
                devuelveDatos={({ nuevoVal, index, descrip }) => {
                  setRegActual({ ...regActual, NO_VENDEDOR: nuevoVal, VEND_NOMBRE: descrip })
                }}
                deshabilitado={false}
                devuelveEditando={(valor) => { /* no debe hacer nada, pero es necesario enviar la funcion */ }}
              />
            </Col>
          </Row>
          {/* OTROS DATOS */}
          <InputGroup
            className='mb-1'
            size='sm'
          >
            <InputGroup.Text className='' size='sm' id='lblOTROS_DATOS'>Otros Datos:</InputGroup.Text>
            <Form.Control
              className=''
              id='OTROS_DATOS'
              type='text'
              disabled={disableBotMod}
              maxLength={250}
              aria-describedby='lblOTROS_DATOS'
              value={regActual.OTROS_DATOS ?? ''}
              onChange={ctrlChangeCampo}
            />
          </InputGroup>
          {/* DATO ADICIONAL */}
          <InputGroup
            className='mb-1'
            size='sm'
          >
            <InputGroup.Text
              className='' size='sm' id='lblEXTRA1_TXT'
            >{userEmpConfig.textos_labels.cliExtra1 || 'Dato adicional'}:
            </InputGroup.Text>
            <Form.Control
              className=''
              id='EXTRA1_TXT'
              type='text'
              disabled={disableBotMod}
              maxLength={250}
              aria-describedby='lblEXTRA1_TXT'
              value={regActual.EXTRA1_TXT ?? ''}
              onChange={ctrlChangeCampo}
            />
          </InputGroup>
          {/* DATOS PARA EL CONTROL DE CREDITO */}
          <Row
            className='ms-1 me-1 pt-1 pb-1 border border-primary-subtle align-items-center'
          >
            {/* L_CREDITO */}
            <Col>
              <Form.Check
                className='text-center'
                id='L_CREDITO'
                style={{ width: 200 }}
                type='checkbox'
                label='Control crédito máximo: '
                reverse
                checked={regActual.L_CREDITO === -1 || !accDiasCre}
                disabled={disableBotMod || !accDiasCre}
                onChange={(e) => {
                  // ** No usar e.preventDefault() porque causa efectos no deseados al presionar en el check
                  setRegActual({
                    ...regActual,
                    [e.target.id]: e.target.checked ? -1 : 0,
                    V_LIMITE_CRE: 0,
                    VCRE_PERIODO_MESES: null,
                    VCRE_DIA_INI: null
                  })
                }}
              />
            </Col>
            {/* V_LIMITE_CRE */}
            <Col>
              <InputGroup
                className='' size='sm' style={{ width: 210 }}
              >
                <InputGroup.Text id='lblPDscto'>Valor máximo:</InputGroup.Text>
                <CampoNumerico
                  idName='V_LIMITE_CRE' fmtoInput=''
                  decimales={userEmpConfig.decimales}
                  devuelveVal={(campo, valor) => setRegActual({ ...regActual, [campo]: valor })}
                  deshabilitado={disableBotMod || !accDiasCre || regActual.L_CREDITO === 0}
                  soloPositivo min={0}
                >{regActual.V_LIMITE_CRE}
                </CampoNumerico>
              </InputGroup>
            </Col>
            {/* VCRE_PERIODO_MESES */}
            <Col>
              <InputGroup
                className='mb-1' size='sm' style={{ width: 255 }}
              >
                <InputGroup.Text
                  className='' size='sm' id='lblVCRE_PERIODO_MESES'
                >Período ctrl. cada:
                </InputGroup.Text>
                <Form.Select
                  className='' size='sm'
                  id='VCRE_PERIODO_MESES'
                  disabled={disableBotMod || regActual.L_CREDITO === 0}
                  value={regActual.VCRE_PERIODO_MESES ?? ''}
                  onChange={ctrlChangeCampo}
                ><option key={0} value={null} />
                  {Object.keys(gcCliCrePeriodoMensual).map((valor, index) =>
                    (<option key={index + 1} value={valor}>{gcCliCrePeriodoMensual[valor]}</option>)
                  )}
                </Form.Select>
              </InputGroup>
            </Col>
            {/* VCRE_DIA_INI */}
            <Col xs='auto'>
              <InputGroup
                className='mb-1' size='sm' style={{ width: 225 }}
              >
                <InputGroup.Text
                  className='' size='sm' id='lblVCRE_DIA_INI'
                >Inicia día del mes:
                </InputGroup.Text>
                <Form.Select
                  className='' size='sm'
                  id='VCRE_DIA_INI'
                  disabled={disableBotMod || regActual.L_CREDITO === 0}
                  value={regActual.VCRE_DIA_INI ?? ''}
                  onChange={ctrlChangeCampo}
                ><option key={0} value={null} />
                  {Object.keys(gcCliCreIniciaDiaMes).map((valor, index) =>
                    (<option key={index + 1} value={valor}>{gcCliCreIniciaDiaMes[valor]}</option>)
                  )}
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>
          {/* BOTON PARA GRABAR O MODIFICAR */}
          <Row className='justify-content-evenly mt-2'>
            <Button
              className='me-3'
              type='submit'
              hidden={disableBotMod}
              style={{ width: 100 }}
            >{estaCreando ? 'Crear' : 'Modificar'}
            </Button>
            <Button
              className='ms-3 bg-danger border-danger'
              hidden={estaCreando || opcMenuCall}
              style={{ width: 100 }}
              onClick={(e) => devuelveEliminaReg(estado.index)}
            >Eliminar
            </Button>
          </Row>
        </Form>
      </Container>
    </Row>
  )
}
export default Cliente
