import React, { useContext, useReducer } from 'react'
import { gcTiposDoc } from '../utils/generales'

const Transacc = React.createContext()

function useTransacc () {
  return useContext(Transacc)
}
// ESTADO INICIAL
const tiposTarea = {
  creando: 'CREANDO',
  consultando: 'CONSULTANDO',
  modificando: 'MODIFICANDO',
  filtrando: 'FILTRANDO'
}
const estadoIni = {
  doc: '',
  tarea: tiposTarea.creando,
  regsCab: [],
  index: 0,
  clave: '',
  listando: false,
  fechaCond: ['=', '=', '='],
  itemFiltro: { filtro: '', registro: {}, orden: '0', campoOrden: 'NO_ITEM_USER' },
  idFoco: '',
  otrosDat: {}
}
// TIPOS DE ACCION
const tiposAccion = {
  cambiaEmp: 'CAMBIAR EMPRESA',
  cambiaTarea: 'CAMBIAR TAREA',
  cargaRegs: 'CARGAR REGISTROS',
  cambiaReg: 'CAMBIAR REGISTRO',
  nuevoReg: 'NUEVO REGISTRO',
  eliminaReg: 'ELIMINA REGISTRO',
  setListar: 'SET LISTANDO',
  setFechaCond: 'SET CONDICION FECHA',
  setItemFiltro: 'SET ITEM FILTRO',
  setIdFoco: 'SET ID FOCO',
  setOtrosDat: 'SET OTROS DATOS'
}
// FUNCIONES PRIVADAS
// Obtiene la clave de la transacción del arreglo de cabeceras
const getClave = (doc, tarea, registro) => {
  if (!registro) return ''
  let clave = ''
  switch (doc) {
    case gcTiposDoc.prof:
      clave = (tarea === tiposTarea.consultando || tarea === tiposTarea.filtrando)
        ? registro.NO_PROFORMA
        : registro.NO_REG
      break
    case gcTiposDoc.fact:
      clave = (tarea === tiposTarea.consultando || tarea === tiposTarea.filtrando)
        ? registro.NO_FACTURA
        : registro.NO_REG
      break
    default: clave = ''
  }
  return clave
}
// Asigna la propiedad otrosDat del Context y actualiza los campos del arreglo
const getOtrosDat = (doc, estado, valores) => {
  // valores son UNDEFINED cuando no hace falta cambiar los datos del arreglo

  let nOtrosDat = { ...estado.otrosDat }

  // Actualiza los datos del arreglo si hace falta
  if (estado.regsCab.length) {
    const regCab = estado.regsCab[estado.index]
    switch (doc) {
      case gcTiposDoc.prof:
        // Si vienen valores se actualiza el arreglo con los respectivos valores
        if (valores) {
          // Verifico si vienen datos del Cliente. El valor debe ser un arreglo de 3 campos
          if (valores.cliente) {
            regCab.NO_CLIENTE = valores.cliente[0]
            regCab.CLI_NOMBRE = valores.cliente[1]
            regCab.N_CLIENTETMP = valores.cliente[2]
          }
          // Verifico si vienen datos del Punto de Venta para cambiar un campo del arreglo
          if (valores.noBodega) regCab.NO_PTOVENTA = valores.noBodega
          // Asigna los valores recibidos
          nOtrosDat = { ...nOtrosDat, ...valores }
        // Se actualizan las respectivas propiedades con los valores del arreglo
        } else {
          nOtrosDat = {
            ...nOtrosDat,
            noBodega: regCab.NO_PTOVENTA,
            cliente: [regCab.NO_CLIENTE, regCab.CLI_NOMBRE, regCab.N_CLIENTETMP]
          }
        }
        break
      case gcTiposDoc.fact:
        // Si vienen valores se actualiza el arreglo con los respectivos valores
        if (valores) {
          // Verifico si vienen datos del Cliente. El valor debe ser un arreglo de 3 campos
          if (valores.cliente) {
            regCab.NO_CLIENTE = valores.cliente[0]
            regCab.CLI_NOMBRE = valores.cliente[1]
            regCab.N_CLIENTETMP = valores.cliente[2]
          }
          // Verifico si vienen datos del Punto de Venta para cambiar el campo del arreglo
          if (valores.noBodega) regCab.NO_BODEGA = valores.noBodega
          // Verifico si viene esNC para cambiar el campo del arreglo
          if (Object.keys(valores).includes('esNC')) {
            if (valores.esNC) {
              regCab.NO_FACTURA = 'B' + regCab.NO_FACTURA.substring(1)
            } else {
              regCab.NO_FACTURA = 'A' + regCab.NO_FACTURA.substring(1)
              regCab.NO_FACTURA_NC = null
            }
          }
          // Asigna los valores recibidos
          nOtrosDat = { ...nOtrosDat, ...valores }
        } else {
          nOtrosDat = {
            ...nOtrosDat,
            noBodega: regCab.NO_BODEGA,
            cliente: [regCab.NO_CLIENTE, regCab.CLI_NOMBRE, regCab.N_CLIENTETMP],
            esNC: regCab.NO_FACTURA.substring(0, 1) === 'B'
          }
        }
        break
      default: break
    }
  }
  return nOtrosDat
}
// Devuelve el nombre del campo (orden) de la tabla de items
const getCampoOrden = (orden) => {
  switch (orden) {
    case '0': return 'NO_ITEM_USER'
    case '1': return 'CODIGO'
    case '2': return 'DESCRIP'
    case '3': return 'DESCRIP_AUX'
    case '4': return 'OTROS_COD'
    default: return 'DESCRIP'
  }
}
// REDUCER
function reducer (estado, accion) {
  const noMinRegsListado = 10
  switch (accion.tipo) {
    case tiposAccion.cambiaEmp: {
      const nEstado = {
        ...estado,
        tarea: estado.tarea === tiposTarea.consultando
          ? tiposTarea.filtrando
          : estado.tarea,
        regsCab: [],
        index: 0,
        clave: '',
        listando: false,
        idFoco: '',
        itemFiltro: { ...estado.itemFiltro, filtro: '', registro: {} }
      }
      return { ...nEstado, otrosDat: getOtrosDat(nEstado.doc, nEstado) }
    }
    case tiposAccion.cambiaTarea: {
      // Cuando la tarea es CONSULTANDO, accion trae las propiedades regsCab e index
      // caso contrario estos valores no estaran definidos (undefined)
      const doc = accion.doc || estado.doc
      const regs = accion.regsCab || []
      const index = accion.index || 0
      const nEstado = {
        ...estado,
        doc,
        tarea: accion.nuevaTarea,
        regsCab: regs,
        index,
        clave: getClave(doc, accion.nuevaTarea, regs[index]),
        listando: estado.tarea === tiposTarea.consultando ? estado.listando : false
      }
      // Si está modificando conserva los valores de OtrosDat del estado actual
      if (accion.nuevaTarea === tiposTarea.modificando) {
        return { ...nEstado, otrosDat: estado.otrosDat }
      // Caso contrario obtiene los OtrosDat del nuevo estado
      } else return { ...nEstado, otrosDat: getOtrosDat(nEstado.doc, nEstado) }
    }
    case tiposAccion.cargaRegs: {
      const doc = accion.doc || estado.doc
      const index = accion.index || 0
      const listando = accion.tarea === tiposTarea.consultando && accion.regsCab.length > noMinRegsListado
      const nEstado = {
        ...estado,
        doc,
        tarea: accion.tarea,
        regsCab: accion.regsCab,
        index,
        clave: getClave(doc, accion.tarea, accion.regsCab[index]),
        listando,
        idFoco: ''
      }
      return { ...nEstado, otrosDat: getOtrosDat(nEstado.doc, nEstado) }
    }
    case tiposAccion.cambiaReg: {
      let index = estado.index
      // Cuando viene directamente el index en el cual se desea colocar
      if (accion.index >= 0) index = accion.index
      // Cuando viene una cantidad de regsCab que se desea mover a partir del actual
      else {
        index += accion.cantRegs
        // Verifica rangos y reasigna el valor si es necesario
        if (index > 0 && index > estado.regsCab.length - 1) {
          index = estado.regsCab.length - 1
        } else if (index < 0) index = 0
      }
      const nEstado = {
        ...estado,
        index,
        clave: getClave(estado.doc, estado.tarea, estado.regsCab[index]),
        listando: accion.listando ?? estado.listando
      }
      return { ...nEstado, otrosDat: getOtrosDat(nEstado.doc, nEstado) }
    }
    case tiposAccion.nuevoReg: {
      const nEstado = {
        ...estado,
        clave: accion.nuevaClave
      }
      return { ...nEstado, otrosDat: getOtrosDat(nEstado.doc, nEstado) }
    }
    case tiposAccion.eliminaReg: {
      // Si recibe index, elimina ese registro, caso contrario elimina el registro actual
      const index = accion.index || estado.index
      estado.regsCab.splice(index, 1)
      const nEstado = {
        ...estado,
        regsCab: estado.regsCab,
        index: index ? index - 1 : 0,
        clave: getClave(estado.doc, estado.tarea, estado.regsCab[index ? index - 1 : 0])
      }
      return { ...nEstado, otrosDat: getOtrosDat(nEstado.doc, nEstado) }
    }
    case tiposAccion.setListar: {
      const nEstado = { ...estado, listando: true }
      return nEstado
    }
    case tiposAccion.setFechaCond: {
      const nFechaCond = [...estado.fechaCond]
      nFechaCond[accion.fCondIndex] = accion.fCondVal
      const nEstado = { ...estado, fechaCond: nFechaCond }
      return nEstado
    }
    case tiposAccion.setItemFiltro: {
      // Por si acaso no venga la propiedad orden en accion
      // las propiedades filtro y registro vienen siempre
      const orden = accion.itemFiltro.orden || estado.itemFiltro.orden
      // Por si acaso venga la propiedad idFoco en accion
      const idFoco = accion.idFoco || estado.idFoco
      const nEstado = {
        ...estado,
        idFoco,
        itemFiltro: { ...accion.itemFiltro, orden, campoOrden: getCampoOrden(orden) }
      }
      return nEstado
    }
    case tiposAccion.setIdFoco: {
      const nEstado = { ...estado, idFoco: accion.idFoco }
      return nEstado
    }
    case tiposAccion.setOtrosDat: {
      const nEstado = { ...estado, otrosDat: getOtrosDat(estado.doc, estado, accion.otrosDat) }
      return nEstado
    }
    default: return estado
  }
}
// COMPONENTE PROVIDER
export const TransaccProvider = ({ children }) => {
  const [estado, dispatch] = useReducer(reducer, estadoIni)

  return (
    <Transacc.Provider value={[estado, dispatch, tiposAccion, tiposTarea]}>
      {children}
    </Transacc.Provider>
  )
}

export default useTransacc
