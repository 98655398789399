// CONSTANTES GLOBALES
export const gcModulos = {
  admin: 'ADMIN', vtas: 'VENTAS', cpras: 'COMPRAS', inv: 'INVENTARIOS', transp: 'TRANSPORTE', bcos: 'BANCOS', prods: 'PRODUCTOS', cont: 'CONTABILIDAD', gen: 'GENERALES'
}
export const gcTiposDoc = {
  prof: 'PROFORMA',
  fact: 'FACTURA',
  nc: 'NOTA CREDITO',
  guia: 'GUIA DE REMISION',
  ret: 'RETENCION',
  liqComp: 'LIQUIDACION COMPRA',
  ing: 'INGGRESO',
  egr: 'EGRESO',
  cpr: 'COMPRA',
  orden: 'ORDEN DE COMPRA'
}
export const gcDiasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
export const gcMesesAnio = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
export const gcTablasLogicas = {
  fact: 'FACTURAS',
  prof: 'PROFORMAS',
  ptoVta: 'PUNTOS DE VENTA',
  bodega: 'BODEGAS',
  vendedor: 'VENDEDORES',
  undMedXItem: 'UND.MED. X ITEM',
  tarjCre: 'TARJETAS DE CREDITO',
  cliente: 'CLIENTES',
  grupoItem: 'GRUPOS-ITEMS',
  item: 'ITEMS'
}
export const gcTipoEditTablas = {
  creando: 'CREANDO',
  creado: 'CREADO',
  modificando: 'MODIFICANDO',
  modificado: 'MODIFICADO',
  sinEditar: 'SIN EDITAR'
}
export const gcTiposCliente = {
  O: 'Otros', TC: 'T.Crédito'
}
export const gcCliCrePeriodoMensual = {
  0: 'Sin período', 1: '1 mes', 6: '6 meses', 12: '1 año'
}
export const gcCliCreIniciaDiaMes = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
  13: '13',
  14: '14',
  15: '15',
  16: '16',
  17: '17',
  18: '18',
  19: '19',
  20: '20',
  21: '21',
  22: '22',
  23: '23',
  24: '24',
  25: '25',
  26: '26',
  27: '27',
  28: '28',
  29: '29',
  30: 'Ult.día'
}
// Formatos
export const gcFmtosBotones = {
  imagen: 'me-1 bg-transparent border-0',
  imgConMenu: 'me-1 bg-primary-subtle border-0',
  imgConBorde: 'me-1 bg-transparent',
  dropDownItem: '',
  enTabla: 'd-flex align-items-center bg-success-subtle',
  styleTabla: { height: 20, maxWidth: 35 },
  imgTabla: 'mx-auto d-block',
  imgWidth: 22,
  imgWidthSM: 16,
  filtro: 'align-middle bg-secondary-subtle border border-secondary ps-1 pe-1'
}
export const gcFmtosColorFondo = {
  nuevoReg: ' bg-info-subtle',
  editandoReg: ' bg-success-subtle',
  msjeInfo: ' bg-secondary-subtle',
  auditoria: ' bg-secondary-subtle',
  noExiste: ' bg-warning-subtle',
  existMinMax: ' bg-warning-subtle',
  sinStock: ' bg-warning-subtle'
}
export const gcFmtosListas = () => {
  const divLista = 'bg-body-secondary'
  const rowBoton = { row: 'justify-content-center mt-2', boton: '' }
  const tabla = {
    div: 'overflow-scroll mt-2 ms-3 me-3 mb-2 border border-primary',
    tabla: 'table table-sm table-bordered table-hover caption-top',
    caption: 'bg-body-secondary pb-0 ps-3 text-black h6 border-end-0',
    head: 'table-primary sticky-top'
  }
  return { divLista, rowBoton, tabla }
}
export const gcFmtosTablas = (opcMenuCall, esOrden) => {
  return {
    row: opcMenuCall ? 'mb-1' : 'mt-2 mb-2 justify-content-center',
    inputGroup: '',
    inputGStyle: opcMenuCall ? {} : { maxWidth: 800 },
    labelFiltro: (opcMenuCall ? (esOrden ? 'bg-primary text-white' : '') : 'fw-bold'),
    filtro: '',
    rowEmp: 'ms-5 me-5'
  }
}
export const gcFmtoMaxRows = (rows, maxRows) => {
  const fijo = 'border border-primary text-center'
  if (!maxRows) maxRows = process.env.REACT_APP_MAX_REGS
  if (rows > 0) return { ancho: 40, fmto: rows >= maxRows ? fijo + ' bg-warning-subtle' : fijo }
  return { ancho: 40, fmto: '' }
}
export const gcFmtoBgVUnit = (registro, existentes) => {
  const fmto = registro.VALOR_UNIT < registro[!existentes ? 'V_COST_PROM' : 'V_COST_PROM_DET']
    ? ' bg-danger-subtle'
    : ''
  return fmto
}

// Obtiene el ancho disponible de la pantalla a partir del inicio de un elemento
export function anchoPantDisponible (idElemento) {
  const elemento = document.getElementById(idElemento)
  let posLeft = elemento === null ? 10 : elemento.getBoundingClientRect().left
  posLeft = posLeft < 0 ? 0 : posLeft
  return window.innerWidth - posLeft - 30
}
// Coloca el foco en el elemento con idName
export function colocaFocoDirecto (idName) {
  const campo = document.getElementById(idName)
  if (campo) campo.focus()
}
// Asigna las series dependiendo del tipo de Documento
export function cargaSeries (tipoDoc, paramsEmp, series) {
  // Por si acaso todavía no esté cargado paramsEmp
  if (!paramsEmp || !Object.keys(paramsEmp).length || !series) return [['001-001'], [], ['001-001']]

  let seriesEmp = ''
  let seriesUser = ''
  switch (tipoDoc) {
    case gcTiposDoc.fact: {
      seriesEmp = series.fact
      seriesUser = paramsEmp.SRI_SERIES_FACT_ELECT
      break
    } case gcTiposDoc.nc: {
      seriesEmp = series.ntaCre
      seriesUser = paramsEmp.SRI_SERIES_NC_ELECT
      break
    } case gcTiposDoc.guia: {
      seriesEmp = series.guia
      seriesUser = paramsEmp.SRI_SERIES_GUIA_ELECT
      break
    } case gcTiposDoc.ret: {
      seriesEmp = series.ret
      seriesUser = paramsEmp.SRI_SERIES_RET_ELECT
      break
    } case gcTiposDoc.liqComp: {
      seriesEmp = series.liqComp
      seriesUser = paramsEmp.SRI_SERIES_LIQCOMP_ELECT
      break
    } case gcTiposDoc.ing: {
      seriesEmp = series.ing
      seriesUser = ''
      break
    } case gcTiposDoc.egr: {
      seriesEmp = series.egr
      seriesUser = ''
      break
    } default:
  }
  const seriesEUser = seriesUser.split(',')
  const seriesElect = []
  if (paramsEmp.L_FACT_ELECTRONICA === -1) {
    // Incluye las series definidas en el usuario, que estén dentro del listado de series de la empresa
    seriesEUser.forEach(elemento => {
      if (seriesEmp.includes(elemento)) seriesElect.push(elemento)
    })
  }
  const seriesFisicas = ['001-001']
  return [seriesElect.concat(seriesFisicas), seriesElect, seriesFisicas]
}
// Determina si el Documento Electrónico esta en Produccion y es Valido (autorizado)
export function sriDatosDoc (pClaveDoc) {
  let esProducc = false
  const esValida = !/\D/.test(pClaveDoc) && pClaveDoc.length === 49
  if (pClaveDoc) {
    if (pClaveDoc.substring(23, 24) === '2') esProducc = true
  }
  return { esProducc, esValida }
}
export function rutaReps (rutaCarpetas, modulo) {
  let ruta = rutaCarpetas + `reportes/${modulo}/`
  if (rutaCarpetas.indexOf('\\') >= 0) {
    ruta = rutaCarpetas + `reportes\\${modulo}\\`
  }
  return ruta
}

// FORMATOS
export function fechaLocalNueva (valor) {
  let fecha = new Date()
  if (valor) fecha = new Date(valor)
  // getTimezoneOffset(): Obtiene la diferencia en minutos entre la hora UTC y la hora local.
  const offset = fecha.getTimezoneOffset() * 60000 // Offset en milisegundos
  const fechaLocal = new Date(fecha.getTime() - offset)
  return fechaLocal
}
export function numberFormat (valor, decimales, sinFmto) {
  let valorFmto = 0
  if (!sinFmto) {
    valorFmto = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimales,
      maximumFractionDigits: decimales
    }).format(valor)
  } else {
    valorFmto = parseFloat(new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimales,
      useGrouping: false,
      maximumFractionDigits: decimales
    }).format(valor))
  }
  return valorFmto
}
export function fechaFormat (fecha, tipo, separacion) {
  if (!fecha) return ''
  if (!separacion) separacion = '/'
  // No se porque aqui no hace falta usar fechaLocalNueva
  // aunque la fecha presenta la fecha erronea en el console.log (T05:00:00)
  // , las funciones de horas, minutos y segundos devuelven correctamente los valores
  const fResultado = typeof fecha === 'string' ? new Date(fecha) : fecha
  const dia = fResultado.getDate()
  const mes = fResultado.getMonth()
  const anio4 = fResultado.getFullYear()
  const horas = fResultado.getHours()
  const minutos = fResultado.getMinutes()
  const segundos = fResultado.getSeconds()
  const diaSemana = fResultado.getDay()
  let res
  switch (tipo) {
    case 'AMD': {
      // getMonth() devuelven 1 mes o día menos porque el cálculo es 0-indexado
      const amd = anio4 + separacion +
        (mes + 1).toString().padStart(2, '0') + separacion +
        dia.toString().padStart(2, '0')
      return amd
    }
    case 'DMA': {
      const dma = dia.toString().padStart(2, '0') + separacion +
        (mes + 1).toString().padStart(2, '0') + separacion + anio4
      return dma
    }
    case 'MEDIA':
      res = dia + ' ' + gcMesesAnio[mes].substring(0, 3) + '/' + anio4
      return res
    case 'MEDIA LARGA':
      res = gcDiasSemana[diaSemana].substring(0, 3)
      res += ', ' + dia
      res += ' ' + gcMesesAnio[mes].substring(0, 3)
      res += '/' + anio4
      return res
    case 'MEDIA LARGA HORA':
      res = gcDiasSemana[diaSemana].substring(0, 3)
      res += ', ' + dia
      res += ' ' + gcMesesAnio[mes].substring(0, 3)
      res += '/' + anio4
      res += ', ' + horas.toString().padStart(2, '0')
      res += ':' + minutos.toString().padStart(2, '0')
      return res
    case 'LARGA':
      res = gcDiasSemana[diaSemana]
      res += ', ' + dia
      res += ' de ' + gcMesesAnio[mes]
      res += ' de ' + anio4
      return res
    case 'LARGA HORA':
      res = gcDiasSemana[diaSemana]
      res += ', ' + dia
      res += ' de ' + gcMesesAnio[mes]
      res += ' de ' + anio4
      res += ', ' + horas.toString().padStart(2, '0')
      res += ':' + minutos.toString().padStart(2, '0')
      return res
    case 'SOLO HORA':
      res = horas.toString().padStart(2, '0')
      res += ':' + minutos.toString().padStart(2, '0')
      return res
    case 'SOLO HORA SEGUNDOS':
      res = horas.toString().padStart(2, '0')
      res += ':' + minutos.toString().padStart(2, '0')
      res += ':' + segundos.toString().padStart(2, '0')
      return res
    default:
  }
}
export function fechaSumaDias (fecha, dias, separacion) {
  if (dias === undefined || dias === '') dias = 0
  const fResultado = new Date(fecha)
  fResultado.setDate(fResultado.getDate() + dias)
  // Si no pasa un caracter de separación se envia con el default '-'
  const fmtoAMD = fechaFormat(fResultado, 'AMD', separacion || '-')
  return [fResultado, fmtoAMD]
}
export function fechaRestaFechas (f1, f2) {
  const unDiaEnMilisegundos = (1000 * 60 * 60 * 24)
  const f1Ok = new Date(fechaFormat(f1, 'AMD', '-'))
  const f2Ok = new Date(fechaFormat(f2, 'AMD', '-'))
  const dias = (f1Ok - f2Ok) / unDiaEnMilisegundos
  // const dias = Math.ceil((f1Ok - f2Ok) / unDiaEnMilisegundos)
  return dias
}
// Para obtener el formato que se presenta sobre una columna de cualquier listado de una tabla
export function fmtosTablaFiltroCampo (tabla, campo, valor, decimales) {
  let ancho = 'auto'
  let fmto
  let texto
  const decMoneda = 2
  switch (typeof valor) {
    case 'string':
      fmto = ''
      texto = valor
      switch (campo.toUpperCase()) {
        case 'ID': ancho = 80; break
        case 'NOMBRE': ancho = 200; break
        case 'DIRECC': ancho = 200; break
        case 'TELEF': ancho = 120; break
        case 'FAX': ancho = 100; break
        case 'CATEGORIA': fmto = 'text-center'; break
        case 'EMAIL': ancho = 270; break
        case 'GRUPO_COD': fmto = 'text-center'; ancho = 30; break
        case 'GRUPO_DESCRIP': ancho = 140; break
        case 'CODIGO': if (tabla === gcTablasLogicas.item) ancho = 100; break
        case 'DESCRIP': ancho = 180; break
        case 'DESCRIP_AUX': ancho = 180; break
        case 'OTROS_COD': ancho = 250; break
        case 'TIPO':
          if (tabla === gcTablasLogicas.cliente) {
            fmto = 'text-center'
            texto = gcTiposCliente[valor]
          }
          break
        case 'ATTPERSONA': ancho = 150; break
        case 'VEND_NOMBRE': ancho = 150; break
        case 'OTROS_DATOS': ancho = 270; break
        case 'USER_NOMBRE': ancho = 130; break
        case 'USER_ABREV': fmto = 'text-center'; break
        case 'F_DESDE':
          ancho = 90
          if (valor) texto = fechaFormat(valor, 'MEDIA LARGA')
          break
        case 'F_MODIFICA':
          fmto = 'text-center'
          ancho = 120
          if (valor) texto = fechaFormat(valor, 'MEDIA LARGA HORA')
          break
        default:
          if (campo.toUpperCase().substr(0, 6) === 'DIRECC') ancho = 200
          if (campo.toUpperCase().substr(0, 7) === 'DESCRIP') ancho = 200
      }

      break
    case 'number':
      ancho = 80
      fmto = 'text-end'
      switch (campo.toUpperCase()) {
        case 'NO_ITEM_USER':
          ancho = 60
          texto = valor
          break
        case 'V_UNIT': texto = numberFormat(valor, decimales); break
        case 'V_PVENTA': texto = numberFormat(valor, decimales); break
        case 'EXIST':
          texto = numberFormat(valor, decimales)
          break
        case 'GRUPO_L_SERVICIO':
          ancho = 'auto'
          fmto = 'text-center'
          texto = valor === -1 ? '✔' : ''
          break
        case 'P_DSCTO':
          ancho = 45
          fmto = 'text-center'
          texto = valor
          if (tabla !== gcTablasLogicas.cliente) texto = valor * 100
          texto = numberFormat(texto, decMoneda)
          break
        case 'NO_PV':
          ancho = 'auto'
          fmto = 'text-center'
          texto = valor
          break
        case 'V_INI_X_COB':
          if (tabla === gcTablasLogicas.cliente) {
            ancho = 'auto'
            fmto = 'text-center'
            texto = valor
          } else texto = numberFormat(valor, decMoneda)
          break
        case 'VCRE_PERIODO_MESES':
          if (tabla === gcTablasLogicas.cliente) {
            ancho = 70
            fmto = 'text-center'
            texto = valor === null ? '' : gcCliCrePeriodoMensual[valor]
          } else texto = numberFormat(valor, decMoneda)
          break
        case 'VCRE_DIA_INI':
          if (tabla === gcTablasLogicas.cliente) {
            ancho = 70
            fmto = 'text-center'
            texto = valor === null ? '' : gcCliCreIniciaDiaMes[valor]
          } else texto = numberFormat(valor, decMoneda)
          break
        default:
          texto = valor
          if (campo.toUpperCase().substr(0, 2) === 'V_') {
            texto = numberFormat(valor, decMoneda)
          }
          if (campo.toUpperCase().substr(0, 5) === 'EXIST') {
            texto = numberFormat(valor, decimales)
          }
          if (campo.toUpperCase().substr(0, 2) === 'L_') {
            ancho = 'auto'
            fmto = 'text-center'
            texto = valor === -1 ? '✔' : ''
          }
      }
      break
    // case 'bigint':
    // case 'boolean':
    // case 'symbol':
    // case 'undefined':
    case 'object':
      fmto = ''
      texto = valor
      switch (campo.toUpperCase()) {
        case 'OTROS_COD' : ancho = 200; break
        case 'VCRE_PERIODO_MESES':
          if (tabla === gcTablasLogicas.cliente) {
            ancho = 70
            fmto = 'text-center'
            texto = valor === null ? '' : gcCliCrePeriodoMensual[valor]
          }
          break
        case 'VCRE_DIA_INI':
          if (tabla === gcTablasLogicas.cliente) {
            ancho = 70
            fmto = 'text-center'
            texto = valor === null ? '' : gcCliCreIniciaDiaMes[valor]
          }
          break
        default:
      }
      break
    default:
      fmto = ''
      texto = valor
  }
  return ({ ancho, fmto, texto })
}

// Información de BD para paso de propiedades en componentes que actualizan un campo de NUEVAS PROFORMAS
export function setInfoCampoProforma (campo, valor) {
  return {
    url: '/proformas/nuevas',
    tabla: 'T_PROFORMA_TMP',
    campo,
    valor,
    campoClave: 'NO_REG'
  }
}
export function setInfoCampoFactura (campo, valor) {
  return {
    url: '/facturas/nuevas',
    tabla: 'T_FACTURA_TMP',
    campo,
    valor,
    campoClave: 'NO_REG'
  }
}
// Información de BD para el paso de propiedades a componentes SELECT-OPTION
// PUNTOS DE VENTA7
export const infoPtoVtaSel = (fmto) => {
  return {
    url: '/ptosventa',
    maxRegs: 100,
    txtTabla: gcTablasLogicas.ptoVta,
    tabCampos: ['KEY_TAB', 'DESCRIP', 'TXT_TIPO', 'DIRECC', 'TELEF', 'EMAIL', 'NO_PV'],
    txtLblTabla: 'Pto. Venta:',
    txtCampos: ['No.', 'Descripción', 'Tipo', 'Dirección', 'Teléfono', 'Email', 'PV'],
    campoAdicional: 'KEY_TAB',
    ceroEsNulo: false,
    ancho: 0,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// BODEGAS
export const infoBodegaSel = (fmto) => {
  return {
    url: '/bodegas',
    maxRegs: 100,
    txtTabla: gcTablasLogicas.bodega,
    tabCampos: ['KEY_TAB', 'DESCRIP', 'TXT_TIPO', 'DIRECC', 'TELEF', 'EMAIL', 'NO_PV'],
    txtLblTabla: 'Bodega:',
    txtCampos: ['No.', 'Descripción', 'Tipo', 'Dirección', 'Teléfono', 'Email', 'PV'],
    campoAdicional: 'KEY_TAB',
    ceroEsNulo: false,
    ancho: 0,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// VENDEDORES
export const infoVendedorSel = (fmto, label) => {
  return {
    url: '/vendedores',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.vendedor,
    tabCampos: ['CI', 'NOMBRE', 'DIRECC', 'TELEF', 'EMAIL'],
    txtLblTabla: (label ?? 'Vendedor') + ':',
    txtCampos: ['CI', 'Nombre', 'Dirección', 'Teléfono', 'Email'],
    campoAdicional: '',
    ceroEsNulo: true,
    ancho: 0,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// UNIDADES DE MEDIDA X ITEM
export const infoUndMedXItemSel = (fmto) => {
  return {
    url: '/undmedxitem',
    maxRegs: 100,
    txtTabla: gcTablasLogicas.undMedXItem,
    tabCampos: ['KEY_TAB', 'ABREV', 'DESCRIP', 'NO_PVENTA', 'V_PVENTA', 'L_UNDMED_1', 'FACTOR_REL'],
    txtLblTabla: '',
    txtCampos: ['No.', 'Abrev.', 'Descripción', '#PV', 'P.V.', 'Básica', 'Factor Rel.'],
    campoAdicional: '',
    ceroEsNulo: false,
    ancho: 100,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// TARJETAS DE CREDITO
export const infoTarjsCreSel = (fmto) => {
  return {
    url: '/tarjetasCre',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.tarjCre,
    tabCampos: ['RUC', 'DESCRIP', 'DIRECC', 'TELEF', 'EMAIL'],
    txtLblTabla: 'Tarjetas Crédito:',
    txtCampos: ['RUC', 'Descripción', 'Dirección', 'Teléfono', 'Email'],
    campoAdicional: '',
    ceroEsNulo: true,
    ancho: 350,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}

// Información de BD para el paso de propiedades a componentes TABLA-FILTRO
// GRUPOS DE ITEM     tipos de grupos 'CM','CV','SC','SM','SV','T','VM'
export const infoGItemFiltro = (doc, campo, deshabilitado, fmto) => {
  let label = 'Descripción:'
  if (campo === 'CODIGO') label = 'Código:'
  const campoFiltro = { enTabla: campo, enSQL: campo }
  let condFija = ''
  switch (doc) {
    case gcTiposDoc.prof:
      condFija = 'and TIPO in (\'CV\',\'SV\',\'T\',\'VM\')'
      break
    case gcTiposDoc.fact:
      condFija = 'and TIPO in (\'CV\',\'SV\',\'T\',\'VM\')'
      break
    case gcTiposDoc.orden:
      condFija = 'and TIPO in (\'CV\',\'SC\',\'T\',\'CM\')'
      break
    case gcTiposDoc.cpr:
      condFija = 'and TIPO in (\'CV\',\'SC\',\'T\',\'CM\')'
      break
    default:
      break
  }
  return {
    url: '/grupos-items',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.grupoItem,
    tabCampos: ['CODIGO', 'DESCRIP'],
    txtCampos: ['Código', 'Descripción'],
    txtLblTabla: label,
    campoFiltro,
    condFija,
    obligatorio: false,
    deshabilitado,
    sinPlaceHolder: true,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// ITEMS
const itemsCamposTabla = ['NO_ITEM_USER', 'CODIGO', 'DESCRIP', 'V_PVENTA', 'EXIST', 'DESCRIP_AUX', 'OTROS_COD',
  'GRUPO_COD', 'GRUPO_DESCRIP', 'ABREV', 'GRUPO_L_SERVICIO', 'UBICACION', 'NO_UND_MED']
const itemsTxtColsTabla = ['No.', 'Código', 'Descripción', 'PV 1', 'Exist.', 'Auxiliar', 'Otros códigos',
  'Cod.', 'Grupo', 'UM', 'Servicio', 'Ubicación', '']

export const infoItemFiltro = (
  doc, campo, deshabilitado, condBodega, condGrupo, fmto, labelFijo
) => {
  let label = ''
  const sinPlaceHolder = (campo === 'NO_ITEM_USER' || campo === 'CODIGO')
  if (labelFijo) label = labelFijo
  else {
    switch (campo) {
      case 'NO_ITEM_USER': label = 'No.:'
        break
      case 'CODIGO': label = 'Código:'
        break
      case 'DESCRIP': label = 'Descripción:'
        break
      case 'DESCRIP_AUX': label = 'Auxiliar:'
        break
      case 'OTROS_COD': label = 'Otros códigos:'
        break
      default: break
    }
  }
  const campoFiltro = { enTabla: campo, enSQL: 'A.' + campo }
  let condFija = ''
  switch (doc) {
    case gcTiposDoc.prof:
      condFija = 'and NVL(A.TIPO, \'CV\') in (\'CV\',\'SV\',\'T\',\'VM\')'
      break
    case gcTiposDoc.fact:
      condFija = 'and NVL(A.TIPO, \'CV\') in (\'CV\',\'SV\',\'T\',\'VM\')'
      break
    case gcTiposDoc.orden:
      condFija = 'and NVL(A.TIPO, \'CV\') in (\'CV\',\'SC\',\'T\',\'CM\')'
      break
    case gcTiposDoc.cpr:
      condFija = 'and NVL(A.TIPO, \'CV\') in (\'CV\',\'SC\',\'T\',\'CM\')'
      break
    default:
      break
  }
  let obligatorio = true
  if (campo === 'DESCRIP_AUX' || campo === 'OTROS_COD') obligatorio = false
  return {
    url: '/items',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.item,
    txtLblTabla: label,
    tabCampos: itemsCamposTabla,
    txtCampos: itemsTxtColsTabla,
    campoFiltro,
    condFija,
    condBodega,
    condGrupo,
    obligatorio,
    deshabilitado,
    sinPlaceHolder,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}

export const getTime = () => {
  const ahora = new Date()
  const horas = ahora.getHours().toString().padStart(2, '0')
  const minutos = ahora.getMinutes().toString().padStart(2, '0')
  const segundos = ahora.getSeconds().toString().padStart(2, '0')
  const milisegundos = ahora.getMilliseconds().toString().padStart(3, '0')

  return `${horas}:${minutos}:${segundos}:${milisegundos}`
}
