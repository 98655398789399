import { useEffect, useState } from 'react'
import { Row, Col, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'

import TablaOpcion from '../generales/tablas/TablaOpcion'
import CampoNumerico from '../generales/CampoNumerico'
import { gcFmtosBotones, infoTarjsCreSel } from '../../utils/generales'
import FPsSRI from './FPsSRI'
import '../../styles/MiBoton.css'

import imgArriba from '../../iconos/ARRIBA.ICO'

function FPsFactSimple (props) {
  const { vTotal, diasCre } = props

  const [vEfectivo, setVEfectivo] = useState(props.datos.vEfectivo)
  const [vCambio, setVCambio] = useState(props.datos.vCambio)
  const [vCredito, setVCredito] = useState(props.datos.vCredito)

  const [vCheque, setVCheque] = useState(props.datos.vCheque)
  const [chNo, setChNo] = useState(props.datos.chNo)
  const [chBco, setChBco] = useState(props.datos.chBco)
  const [chCta, setChCta] = useState(props.datos.chCta)

  const [vTarjeta, setVTarjeta] = useState(props.datos.vTarjeta)
  const [tarjNoInstit, setTarjNoInstit] = useState(props.datos.tarjNoInstit)
  const [tarjDescrip, setTarjDescrip] = useState(props.datos.tarjDescrip)
  const [tarjNo, setTarjNo] = useState(props.datos.tarjNo)

  const [sriFP, setSriFP] = useState(props.datos.sriFP)

  // Private Sub ucFPCredito_LostFocus(Index As Integer)
  //   If fbEstoyEnMoveComplite Then Exit Sub
  //   If ucFPCredito(0).ValorSinFmto > 0 And ucDiasCre.ValorSinFmto = 0 Or ucDiasCre.ValorSinFmto = "" Then
  //     MsgBox "El valor del cr�dito debe ser 0", vbInformation, "El cliente no tiene asignado un valor en d�as de cr�dito"
  //     ucFPCredito(0).ValorSinFmto = 0
  //     ucFPEfectivo.ValorSinFmto = txtFactVTotal(0)
  //     If ucFPEfectivo.Editable And ucFPEfectivo.Visible Then
  //       ucFPEfectivo.SetFocus
  //     End If
  //   End If
  // End Sub

  useEffect(() => {
    if (props.datos.sinAsignar) {
      if (diasCre > 0) ctrlValores('vCredito', vTotal)
      else ctrlValores('vEfectivo', vTotal)
    }
  }, [diasCre, props.datos, vTotal])

  // FUNCIONES DE CONTROL (EVENTOS) DEVUELTOS POR EL COMPONENTE
  function ctrlValores (idName, valor, valor2) {
    let vNuevo, vNuevoCambio
    switch (idName) {
      case 'vEfectivo':
        if (valor > (vTotal - vCheque - vTarjeta)) {
          vNuevo = 0
        } else vNuevo = Number((vTotal - valor - vCheque - vTarjeta).toFixed(2))
        setVEfectivo(valor)
        vNuevoCambio = Math.abs(-Number(vTotal - valor - vNuevo - vCheque - vTarjeta).toFixed(2))
        setVCambio(vNuevoCambio)
        if (diasCre === 0) {
          setVCheque(vNuevo)
          props.devuelveValores({
            vEfectivo: valor,
            vCambio: vNuevoCambio,
            vCredito,
            vCheque: vNuevo,
            chNo,
            chBco,
            chCta,
            vTarjeta,
            tarjNoInstit,
            tarjDescrip,
            tarjNo,
            sriFP,
            sinAsignar: false
          })
        } else {
          setVCredito(vNuevo)
          props.devuelveValores({
            vEfectivo: valor,
            vCambio: vNuevoCambio,
            vCredito: vNuevo,
            vCheque,
            chNo,
            chBco,
            chCta,
            vTarjeta,
            tarjNoInstit,
            tarjDescrip,
            tarjNo,
            sriFP,
            sinAsignar: false
          })
        }
        break
      case 'vCredito':
        vNuevo = Number((vTotal - valor - vCheque - vTarjeta).toFixed(2))
        setVEfectivo(vNuevo)
        setVCredito(valor)
        setVCambio(0)
        props.devuelveValores({
          vEfectivo: vNuevo,
          vCambio: 0,
          vCredito: valor,
          vCheque,
          chNo,
          chBco,
          chCta,
          vTarjeta,
          tarjNoInstit,
          tarjDescrip,
          tarjNo,
          sriFP,
          sinAsignar: false
        })
        break
      case 'vCheque':
        vNuevo = Number((vTotal - valor - vTarjeta).toFixed(2))
        setVCheque(valor)
        setVCambio(0)
        if (diasCre === 0) {
          setVEfectivo(vNuevo)
          setVCredito(0)
          props.devuelveValores({
            vEfectivo: vNuevo,
            vCambio: 0,
            vCredito: 0,
            vCheque: valor,
            chNo,
            chBco,
            chCta,
            vTarjeta,
            tarjNoInstit,
            tarjDescrip,
            tarjNo,
            sriFP,
            sinAsignar: false
          })
        } else {
          setVCredito(vNuevo)
          setVEfectivo(0)
          props.devuelveValores({
            vEfectivo: 0,
            vCambio: 0,
            vCredito: vNuevo,
            vCheque: valor,
            chNo,
            chBco,
            chCta,
            vTarjeta,
            tarjNoInstit,
            tarjDescrip,
            tarjNo,
            sriFP,
            sinAsignar: false
          })
        }
        break
      case 'chNo':
        setChNo(valor)
        props.devuelveValores({
          vEfectivo,
          vCambio,
          vCredito,
          vCheque,
          chNo: valor,
          chBco,
          chCta,
          vTarjeta,
          tarjNoInstit,
          tarjDescrip,
          tarjNo,
          sriFP,
          sinAsignar: false
        })
        break
      case 'chBco':
        props.devuelveValores({
          vEfectivo,
          vCambio,
          vCredito,
          vCheque,
          chNo,
          chBco,
          chCta,
          vTarjeta,
          tarjNoInstit,
          tarjDescrip,
          tarjNo,
          sriFP,
          sinAsignar: false
        })
        break
      case 'chCta':
        props.devuelveValores({
          vEfectivo,
          vCambio,
          vCredito,
          vCheque,
          chNo,
          chBco,
          chCta,
          vTarjeta,
          tarjNoInstit,
          tarjDescrip,
          tarjNo,
          sriFP,
          sinAsignar: false
        })
        break
      case 'vTarjeta':
        vNuevo = Number((vTotal - valor - vCheque).toFixed(2))
        setVTarjeta(valor)
        setVCambio(0)
        if (diasCre === 0) {
          setVEfectivo(vNuevo)
          setVCredito(0)
          props.devuelveValores({
            vEfectivo: vNuevo,
            vCambio: 0,
            vCredito: 0,
            vCheque,
            chNo,
            chBco,
            chCta,
            vTarjeta: valor,
            tarjNoInstit,
            tarjDescrip,
            tarjNo,
            sriFP,
            sinAsignar: false
          })
        } else {
          setVCredito(vNuevo)
          setVEfectivo(0)
          props.devuelveValores({
            vEfectivo: 0,
            vCambio: 0,
            vCredito: vNuevo,
            vCheque,
            chNo,
            chBco,
            chCta,
            vTarjeta: valor,
            tarjNoInstit,
            tarjDescrip,
            tarjNo,
            sriFP,
            sinAsignar: false
          })
        }
        break
      case 'tarjNoInstit': {
        let devuelve = {
          vEfectivo,
          vCambio,
          vCredito,
          vCheque,
          chNo,
          chBco,
          chCta,
          vTarjeta,
          tarjNoInstit: valor,
          tarjDescrip: valor2,
          tarjNo,
          sriFP,
          sinAsignar: false
        }
        // Escogió o cambió la tarjeta y hay que asignar el valor automáticamente
        if (vTarjeta === 0) {
          vNuevo = Number((vTotal - vCheque).toFixed(2))
          setVTarjeta(vNuevo)
          // Se limpian los valores asignados por default para no duplicar
          setVEfectivo(0)
          setVCambio(0)
          setVCredito(0)
          devuelve = {
            vEfectivo: 0,
            vCambio: 0,
            vCredito: 0,
            vCheque,
            chNo,
            chBco,
            chCta,
            vTarjeta: vNuevo,
            tarjNoInstit: valor,
            tarjDescrip: valor2,
            tarjNo,
            sriFP,
            sinAsignar: false
          }
        }
        setTarjNoInstit(valor)
        setTarjDescrip(valor2)
        props.devuelveValores(devuelve)
        break
      }
      case 'tarjNo':
        props.devuelveValores({
          vEfectivo,
          vCambio,
          vCredito,
          vCheque,
          chNo,
          chBco,
          chCta,
          vTarjeta,
          tarjNoInstit,
          tarjNo: valor,
          tarjDescrip,
          sriFP,
          sinAsignar: false
        })
        break
      case 'sriFP':
        setSriFP(valor)
        props.devuelveValores({
          vEfectivo,
          vCambio,
          vCredito,
          vCheque,
          chNo,
          chBco,
          chCta,
          vTarjeta,
          tarjNoInstit,
          tarjNo,
          tarjDescrip,
          sriFP: valor,
          sinAsignar: false
        })
        break
      default:
        break
    }
  }
  function ctrlClickArriba (e) {
    props.devuelveOcultaFP()
  }

  const widthBots = gcFmtosBotones.imgWidth

  return (
    <>
      {/* IMG ARRIBA, TITULO, VALORES EFECTIVO Y CREDITO */}
      <Row className='pt-2 justify-content-center border-2 border-top border-primary'>
        <Col className='' xs='auto'>
          <OverlayTrigger overlay={<Tooltip>ocultar</Tooltip>}>
            <button
              className='mi-boton'
              onClick={ctrlClickArriba}
            ><img src={imgArriba} alt='Arriba' width={widthBots} />
            </button>
          </OverlayTrigger>
        </Col>
        <Col className='' xs='auto'>
          <InputGroup.Text
            className='border border-primary text-primary fw-bold p-0 ps-2'
            style={{ width: 160 }}
          >
            FORMAS DE PAGO
          </InputGroup.Text>
        </Col>
        <Col xs='auto'>
          <InputGroup
            className=''
            size='sm'
            style={{ minWidth: 700, maxWidth: 800 }}
          >
            <InputGroup.Text
              id='Efectivo' className='mb-1'
            >Efectivo:
            </InputGroup.Text>
            <CampoNumerico
              idName='vEfectivo' fmtoInput='mb-1' decimales={2} obligatorio
              deshabilitado={false} soloPositivo filtrando={false} style={{ minWidth: 100, maxWidth: 130 }}
              devuelveVal={ctrlValores}
            >{vEfectivo}
            </CampoNumerico>
            <InputGroup.Text
              id='Cambio' className='ms-2 mb-1'
            >Cambio:
            </InputGroup.Text>
            <CampoNumerico
              idName='vCambio' fmtoInput='mb-1' decimales={2} obligatorio={false}
              deshabilitado soloPositivo filtrando={false} style={{ minWidth: 100, maxWidth: 130 }}
            >{vCambio}
            </CampoNumerico>
            <InputGroup.Text
              id='Cambio' className='ms-2 mb-1'
            >Credito:
            </InputGroup.Text>
            <CampoNumerico
              idName='vCredito' fmtoInput='mb-1' decimales={2} obligatorio
              deshabilitado={diasCre === 0} soloPositivo filtrando={false} style={{ minWidth: 100, maxWidth: 130 }}
              max={Number((vTotal - vCheque - vTarjeta).toFixed(2))} devuelveVal={ctrlValores}
            >{vCredito}
            </CampoNumerico>
          </InputGroup>
        </Col>
      </Row>
      {/* VALORES CHEQUE */}
      <Row className='pe-1 justify-content-center'>
        <InputGroup
          className=''
          size='sm'
          style={{ minWidth: 700, maxWidth: 800 }}
        >
          <InputGroup.Text
            id='Cheque' className='mb-1'
          >Cheque:
          </InputGroup.Text>
          <CampoNumerico
            idName='vCheque' fmtoInput='mb-1' decimales={2} obligatorio
            deshabilitado={false} soloPositivo filtrando={false} style={{ minWidth: 100, maxWidth: 130 }}
            max={Number((vTotal - vTarjeta).toFixed(2))} devuelveVal={ctrlValores}
          >{vCheque}
          </CampoNumerico>
          <InputGroup.Text
            id='chequeNo' className='ms-2 mb-1'
          >No.:
          </InputGroup.Text>
          <CampoNumerico
            idName='chNo' fmtoInput='mb-1' decimales={0} obligatorio
            deshabilitado={false} soloPositivo filtrando={false} style={{ minWidth: 50, maxWidth: 80 }}
            devuelveVal={ctrlValores}
          >{chNo}
          </CampoNumerico>
          <InputGroup.Text
            id='chequeBco' className='ms-2 mb-1'
          >Banco:
          </InputGroup.Text>
          <Form.Control
            className='mb-1'
            size='sm'
            id='chBanco'
            type='text'
            aria-describedby='chequeBco'
            maxLength={50}
            value={chBco}
            style={{ minWidth: 200, maxWidth: 200 }}
            disabled={false}
            onChange={(e) => setChBco(e.target.value)}
            onBlur={(e) => ctrlValores('chBco', chBco)}
          />
          <InputGroup.Text
            id='chequeCta' className='ms-2 mb-1'
          >Cuenta:
          </InputGroup.Text>
          <Form.Control
            className='mb-1'
            size='sm'
            id='chCuenta'
            type='text'
            aria-describedby='chequeCta'
            maxLength={15}
            value={chCta}
            style={{ minWidth: 120, maxWidth: 120 }}
            disabled={false}
            onChange={(e) => setChCta(e.target.value)}
            onBlur={(e) => ctrlValores('chCta', chCta)}
          />
        </InputGroup>
      </Row>
      {/* VALORES TARJETA DE CREDITO */}
      <Row className='pe-1 justify-content-center'>
        <InputGroup
          className=''
          size='sm'
          style={{ minWidth: 700, maxWidth: 800 }}
        >
          <InputGroup.Text
            id='Tarjeta' className='mb-1'
          >Tarjeta:
          </InputGroup.Text>
          <CampoNumerico
            idName='vTarjeta' fmtoInput='mb-1' decimales={2} obligatorio
            deshabilitado={false} soloPositivo filtrando={false} style={{ minWidth: 100, maxWidth: 130 }}
            max={Number((vTotal - vCheque).toFixed(2))} devuelveVal={ctrlValores}
          >{vTarjeta}
          </CampoNumerico>
          <TablaOpcion
            valTabla={tarjNoInstit}
            infoTablaLista={infoTarjsCreSel(['ms-1 mb-1', ''])}
            devuelveDatos={({ nuevoVal, index, descrip }) => ctrlValores('tarjNoInstit', nuevoVal, descrip)}
            deshabilitado={false}
          />
          <InputGroup.Text
            id='tarjetaNo' className='ms-2 mb-1'
          >No.:
          </InputGroup.Text>
          <Form.Control
            className='mb-1'
            size='sm'
            id='tarjNo'
            type='text'
            aria-describedby='tarjetaNo'
            maxLength={20}
            value={tarjNo}
            style={{ minWidth: 150, maxWidth: 150 }}
            disabled={false}
            onChange={(e) => setTarjNo(e.target.value)}
            onBlur={(e) => ctrlValores('tarjNo', tarjNo)}
          />
        </InputGroup>
      </Row>
      {/* VALORES SRI */}
      <Row className='pe-1 pt-1 justify-content-center border-top border-primary-subtle'>
        <InputGroup
          className=''
          size='sm'
          style={{ minWidth: 600, maxWidth: 700 }}
        >
          <InputGroup.Text
            id='SRI' className='ms-2 mb-1'
          >SRI:
          </InputGroup.Text>
          <CampoNumerico
            idName='vSRI' fmtoInput='mb-1' decimales={2} obligatorio
            deshabilitado soloPositivo filtrando={false} style={{ minWidth: 100, maxWidth: 130 }}
          >{vTotal}
          </CampoNumerico>
          {/* Tipos de FP SRI */}
          <FPsSRI devuelveDatos={({ nuevoVal, index, descrip }) => ctrlValores('sriFP', nuevoVal)}>
            {sriFP}
          </FPsSRI>
        </InputGroup>
      </Row>
    </>
  )
}

export default FPsFactSimple
