import restAPIAxios, { restAPIMySQL } from './axios'
import { numberFormat } from './generales'

// Ya no hace falta porque ahora esta en el Reducer de useAdmin
// // Obtiene los parámetros de la empresa
// export async function getParamsEmp (poolName, orclUserVSAM, noEmp) {
//   const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CARGAR LOS PARAMETROS DE LA EMPRESA',
//     {
//       method: 'get',
//       url: `/empresas/${poolName}/${orclUserVSAM}/${noEmp}`
//     }
//   )
//   if (respuestaAPI.status === 200) {
//     return { status: respuestaAPI.status, datos: respuestaAPI.data }
//   } else return { status: respuestaAPI.status, datos: [] }
// }

// Obtiene los parámetros de la empresa del usuario web respectivo (equivalente a los parámetros de la COMPU)
export async function getParamsUserEmp (noUser, noEmp) {
  const respuestaAPI = await restAPIMySQL(`NO FUE POSIBLE CARGAR LOS PARAMETROS DE LA EMPRESA ${noEmp} - USUARIO  ${noUser}`,
    {
      method: 'get',
      url: `/userEmpresa/${noUser}/${noEmp}`
    }
  )
  if (respuestaAPI.status === 200) {
    // Convierte a JSON los campos respectivos porque en MySQL están como texto
    if (respuestaAPI.data[0].textos_labels) {
      if (typeof respuestaAPI.data[0].textos_labels !== 'object') {
        respuestaAPI.data[0].textos_labels = JSON.parse(respuestaAPI.data[0].textos_labels)
      }
    }
    if (respuestaAPI.data[0].numeros_maximos) {
      if (typeof respuestaAPI.data[0].numeros_maximos !== 'object') {
        respuestaAPI.data[0].numeros_maximos = JSON.parse(respuestaAPI.data[0].numeros_maximos)
      }
    }
    if (respuestaAPI.data[0].numeros_maximos) {
      if (typeof respuestaAPI.data[0].series !== 'object') {
        respuestaAPI.data[0].series = JSON.parse(respuestaAPI.data[0].series)
      }
    }
    return { status: respuestaAPI.status, datos: respuestaAPI.data }
  } else return { status: respuestaAPI.status, datos: [] }
}
// Obtiene el siguiente número de Factura o NC dependiendo de la serie
export async function getSigNoFactXSerie (serie, poolName, orclUsuarioVSAM, noEmpresaVSAM) {
  const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE OBTENER EL SIGUIENTE NUMERO DE ${serie.substring(0, 1) === 'B' ? 'NTA.CREDITO' : 'FACTURA'}`,
    {
      method: 'get',
      url: `/facturas/sigNoXSerie/${poolName}/${orclUsuarioVSAM}/${noEmpresaVSAM}/${serie}`
    }
  )
  if (respuestaAPI.status === 200) {
    const sigNoFact = respuestaAPI.data[0].SIG_NO_FACT
    return sigNoFact
  } else return (serie + '-000000000')
}
// Verifica si existe o no el No. de Factura
export async function existeNoFact (noFactura, poolName, orclUsuarioVSAM, noEmpresaVSAM) {
  const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE DETERMINAR SI EXISTE EL NUMERO DE ${noFactura.substring(0, 1) === 'B' ? 'NTA.CREDITO' : 'FACTURA'} '${noFactura}'`,
    {
      method: 'get',
      url: `/facturas/existeNoFact/${poolName}/${orclUsuarioVSAM}/${noEmpresaVSAM}/${noFactura}`
    }
  )
  if (respuestaAPI.status === 200) {
    return { existe: respuestaAPI.data.length === 1, registro: respuestaAPI.data[0] }
  } else return { existe: false, registro: {} }
}
// Verifica si existe o no el No. de Factura
export async function existeNoProf (noProforma, poolName, orclUsuarioVSAM, noEmpresaVSAM) {
  const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE DETERMINAR SI EXISTE EL NUMERO DE PROFORMA '${noProforma}'`,
    {
      method: 'get',
      url: `/proformas/existeNoProf/${poolName}/${orclUsuarioVSAM}/${noEmpresaVSAM}/${noProforma}`
    }
  )
  if (respuestaAPI.status === 200) {
    return { existe: respuestaAPI.data.length === 1, registro: respuestaAPI.data[0] }
  } else return { existe: false, registro: {} }
}
// Verifica que no grabe items con stock negativo
export async function factConStockNegativo (poolName, orclUserVSAM, noRegFact, noBodega) {
  const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE VALIDAR EL STOCK NEGATIVO EN LA VENTA ${noRegFact}`,
    {
      method: 'get',
      url: `/facturas/nueva/stockNeg/${poolName}/${orclUserVSAM}/${noRegFact}/${noBodega}`
    }
  )
  if (respuestaAPI.status === 200) {
    return (respuestaAPI.data)
  } else return -1
}
// Procesa el texto que debe presentar en la línea de mensaje informativo de una transacción
export const msjInfoCli = (esNC, datCliente, maxFactsMenXCliDscto, maxValXFactCliDscto) => {
  let msje = ''
  if (datCliente.L_CREDITO) {
    const periodo = datCliente.VCRE_PERIODO_MESES
    msje = `El cliente tiene disponibles $${numberFormat(datCliente.V_LIMITE_CRE, 2)}
      para cada ${periodo ? (periodo === 1 ? 'mes' : periodo + ' meses') : '** sin período **'},
      como límite máximo de crédito`
  }
  if (!esNC && maxFactsMenXCliDscto && maxValXFactCliDscto) {
    msje += msje ? ' --- ' : ''
    msje += `Solo puede hacer ${maxFactsMenXCliDscto} factura(s) mensuales con dscto. y valor >= $${numberFormat(maxValXFactCliDscto, 2)} por cliente`
  }
  return msje
}

// CONSTANTES DE SQLS GENERALES
export const _sqlItemFiltroWhere = (filtro, campo) => {
  if (!filtro) return ''
  function extraeCodGrupo (valor) {
    // El filtro debe empezar con '-' para obtener el grupo
    if (valor.substring(0, 1) !== '-') return { codGrupo: '', restoTxt: valor }
    const piezas = valor.toString().split('-')
    // Si hay el "-" se asigna el grupo (primera pieza) y el resto se conserva como estaba
    if (piezas.length > 1) return { codGrupo: piezas[1], restoTxt: piezas.slice(2).join('-') }
    // No hay codigo de grupo
    return { codGrupo: '', restoTxt: valor }
  }
  const { codGrupo, restoTxt } = extraeCodGrupo(filtro)
  const filtroOK = !restoTxt || restoTxt === '*' || restoTxt === '%' ? '' : restoTxt.toString().replaceAll('*', '%')
  const condiciones = filtroOK.split('#')
  let sqlWhere = ''
  if (codGrupo) sqlWhere = sqlWhere + `and A.GRUPO_COD = '${codGrupo}'`
  if (condiciones.length > 1) {
    condiciones.forEach((cond, index) => {
      if (index === 0) sqlWhere = sqlWhere + ' and ('
      else if (cond !== '') {
        if (index > 1) sqlWhere = sqlWhere + ') and ('
        sqlWhere = sqlWhere + `A.GRUPO_DESCRIP like '%${cond}%' or A.CODIGO like '%${cond}%'`
        sqlWhere = sqlWhere + ` or A.DESCRIP like '%${cond}%' or A.DESCRIP_AUX like '%${cond}%'`
        sqlWhere = sqlWhere + ` or A.OTROS_COD like '%${cond}%'`
      }
    })
    sqlWhere = sqlWhere + ')'
  } else if (filtroOK) {
    sqlWhere = sqlWhere + ` and ${campo} like '${filtroOK}%'`
  }
  return sqlWhere
}
