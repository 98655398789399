/* eslint-disable react/jsx-indent */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Form, InputGroup, Spinner } from 'react-bootstrap'

// Importa el cliente Axios
import restAPIAxios from '../../../utils/axios'
import useAdmin from '../../../context/Admin'
import useTransacc from '../../../context/Transacc'
import { gcTablasLogicas, gcTiposDoc } from '../../../utils/generales'

function TablaOpcion (props) {
  // Las propiedades "maxRegs y txtCampos" no se utilizan, por ahora, en este componente
  const {
    url, txtTabla, tabCampos, txtLblTabla, campoAdicional, ceroEsNulo, ancho, fmto
  } = props.infoTablaLista
  const noActualiceTabla = (props.infoTablaActualiza === undefined)
  const deshabilitado = props.deshabilitado

  const [stdAdmin] = useAdmin()
  const [stdTransacc, dispatchTransacc, tiposAccionTransacc] = useTransacc()

  const navigate = useNavigate()

  // Trabajar con el STATE de REACT
  const [datosTabla, setDatosTabla] = useState([])
  const [cargando, setCargando] = useState(false)

  const [valorSel, setValorSel] = useState(0)

  const poolName = stdAdmin.orclHost

  useEffect(() => {
    const valInicial = parseInt(props.valTabla === null ? 0 : props.valTabla)
    setValorSel(valInicial)
    // Query a la API usando Axios
    async function getDatosTabla () {
      setCargando(true)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE CARGAR ${txtTabla}`,
        {
          method: 'post',
          url,
          data: {
            poolName,
            orclUserVSAM: stdAdmin.orclUsuario,
            noEmp: stdAdmin.noEmpresa,
            claveAdicional: props.claveAdicional
          }
        }
      )
      // console.log(respuestaAPI)
      if (respuestaAPI.status === 200) {
        // Actualiza el noPVBod de otrosDat en stdTransacc solo para Proformas y Facturas
        // Bodegas y Ptos de Venta
        if ((stdTransacc.doc === gcTiposDoc.prof && txtTabla === gcTablasLogicas.ptoVta) ||
          (stdTransacc.doc === gcTiposDoc.fact &&
            (txtTabla === gcTablasLogicas.bodega || txtTabla === gcTablasLogicas.bodega))) {
          const index = respuestaAPI.data.findIndex(registro => registro.KEY_TAB === valInicial)
          dispatchTransacc({
            tipo: tiposAccionTransacc.setOtrosDat,
            otrosDat: { noPVBod: respuestaAPI.data[index === -1 ? 0 : index].NO_PV }
          })
        }
        setCargando(false)
        setDatosTabla(respuestaAPI.data)
      } else {
        setCargando(false)
        navigate('/')
      }
    };
    getDatosTabla()
  }, [navigate, txtTabla, url, props.valTabla, props.claveAdicional, stdAdmin.orclUsuario,
    stdAdmin.noEmpresa, stdAdmin.token, poolName]
  )

  async function ctrlSetRegistro (e) {
    // e.preventDefault()
    const nuevoVal = e.target.value === '0' ? '' : e.target.value
    const index = e.target.options.selectedIndex
    const descrip = e.target.options[index].innerHTML
    // Actualiza el noPVBod de otrosDat en stdTransacc solo para Proformas y Facturas
    if ((stdTransacc.doc === gcTiposDoc.prof && txtTabla === gcTablasLogicas.ptoVta) ||
      (stdTransacc.doc === gcTiposDoc.fact && txtTabla === gcTablasLogicas.bodega)) {
      console.log(nuevoVal, datosTabla[index - 1], stdTransacc.doc, txtTabla, gcTablasLogicas)
      dispatchTransacc({
        tipo: tiposAccionTransacc.setOtrosDat,
        otrosDat: { noBodega: nuevoVal, noPVBod: datosTabla[index - 1].NO_PV }
      })
    }
    if (props.devuelveDatos !== undefined) props.devuelveDatos({ nuevoVal, index, descrip })
    if (props.devuelveEditando !== undefined) props.devuelveEditando(true)

    if (noActualiceTabla) {
      setValorSel(nuevoVal)
    } else {
      setCargando(true)
      // actualiza el Campo en la Tabla
      const defProps = {
        poolName,
        tabla: props.infoTablaActualiza.tabla,
        campo: props.infoTablaActualiza.campo,
        valor: nuevoVal,
        cond: props.infoTablaActualiza.campoClave + '=' + stdTransacc.clave,
        ceroEsNulo
      }
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR EL CAMPO EN LA BD',
        {
          method: 'patch',
          url: props.infoTablaActualiza.url,
          data: defProps
        }
      )
      // console.log(respuestaAPI)
      if (respuestaAPI.status === 200) {
        setCargando(false)
        if (respuestaAPI.data[0] === 0) {
          Swal.fire({
            title: 'EL VALOR NO SE ACTUALIZO EN LA BD',
            text: 'Consulte a su técnico',
            footer: 'La respuesta de la API fue 0',
            icon: 'info'
          })
          // Recupera el valor original
          setValorSel(props.valTabla === null ? 0 : props.valTabla)
        } else {
          // console.log(nuevoVal)
          setValorSel(nuevoVal)
        }
      } else {
        setCargando(false)
        // Recupera el valor original
        setValorSel(props.valTabla === null ? 0 : props.valTabla)
      }
    }
  }
  function ctrlDblClick (e) {
    // e.preventDefault()
    if (props.devuelveDblClick === undefined) {
      Swal.fire(
        'NO ESTA ACTIVA LA OPCION DE ORDENADO PARA ESTE CAMPO',
        '',
        'info'
      )
    } else props.devuelveDblClick()
  }

  if (!stdAdmin.auth || datosTabla.length === 0) {
    return (
      <InputGroup className={fmto[0]} size='sm' style={ancho ? { width: ancho } : {}}>
        {txtLblTabla === ''
          ? ''
          : <InputGroup.Text id='lblTabla' className={fmto[1]}>{txtLblTabla}</InputGroup.Text>}
        <Spinner animation='grow' size='sm' hidden={!cargando} />
        <Form.Select
          className={fmto[2]}
          id={'select_' + url.substring(1)}
          aria-describedby='lblTabla'
          disabled={deshabilitado}
        >
          <option>{`** Sin ${txtTabla.toLowerCase()} **`}</option>
        </Form.Select>
      </InputGroup>
    )
  }

  return (
    <InputGroup className={fmto[0]} size='sm' style={ancho ? { width: ancho } : {}}>
      {txtLblTabla === ''
        ? ''
        : <InputGroup.Text
            id='lblTabla' className={fmto[1]}
            onDoubleClick={ctrlDblClick}
          >{txtLblTabla}
          </InputGroup.Text>}
      <Form.Select
        className={fmto[2]}
        id={'select_' + url.substring(1)}
        value={valorSel}
        aria-describedby='lblTabla'
        disabled={deshabilitado}
        // onFocus={(e)=>{e.preventDefault(); if(props.devuelveEditando!==undefined) props.devuelveEditando(true)}}
        onChange={ctrlSetRegistro}
        onBlur={(e) => { if (props.devuelveEditando !== undefined) props.devuelveEditando(false) }}
      >
        <option
          value={0}
          key={0}
          index={0}
          hidden={!ceroEsNulo}
        >{`** Sin ${txtTabla.toLowerCase()} **`}
        </option>
        {datosTabla.map((registro, index) => (
          <option
            value={registro.KEY_TAB}
            key={registro.KEY_TAB}
            index={index + 1}
          >{campoAdicional
            ? registro[tabCampos[1]] + ' (' + registro[campoAdicional] + ')'
            : registro[tabCampos[1]]}
          </option>
        )
        )}
      </Form.Select>
    </InputGroup>
  )
}

export default TablaOpcion
