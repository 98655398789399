import { useEffect } from 'react'

// Captura el evento resize de la pantalla, para conocer si es menor a 1200
export function useWAncho1200 (setWAncho1200) {
  useEffect(() => {
    function ctrlWindowResize () {
      setWAncho1200(window.innerWidth < 1200)
    }
    window.addEventListener('resize', ctrlWindowResize)
    return () => window.removeEventListener('resize', ctrlWindowResize)
  }, [])
}
