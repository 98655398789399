import { memo } from 'react'
import { Card } from 'react-bootstrap'

function Encabezado ({ sinEncabezado }) {
  if (sinEncabezado) return null
  return (
    <Card.Header className='h5 bg-secondary-subtle text-center fw-bold p-0'>CLIENTES</Card.Header>
  )
}

export default memo(Encabezado)
