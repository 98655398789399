import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Button, Col, Form, OverlayTrigger, Tooltip, Row, SplitButton, Dropdown } from 'react-bootstrap'

import restAPIAxios from '../../../utils/axios.js'
import encryptStorage from '../../../utils/encryptStorage.js'
import useAdmin from '../../../context/Admin.jsx'
import useTransacc from '../../../context/Transacc.jsx'

import { sriDatosDoc, gcFmtosBotones, gcTiposDoc } from '../../../utils/generales.js'

import imgGrabar from '../../../iconos/GRABAR.BMP'
import imgCancelar from '../../../iconos/CANCELAR.ICO'
import imgModificar from '../../../iconos/MODIFICAR.ICO'
import imgFiltrar from '../../../iconos/FILTRAR.ICO'
import imgListado from '../../../iconos/LISTADO.BMP'
import imgImprimir from '../../../iconos/IMPRIMIR.ICO'
import imgMail from '../../../iconos/MAIL.ICO'

import imgMas from '../../../iconos/MAS.ICO'
import imgMenos from '../../../iconos/MENOS.ICO'

import imgPrimero from '../../../iconos/PRIMERO.ICO'
import imgAnterior from '../../../iconos/ANTERIOR.PNG'
import imgAnterior2 from '../../../iconos/ANTERIOR2.ICO'
import imgSiguiente from '../../../iconos/SIGUIENTE.PNG'
import imgSiguiente2 from '../../../iconos/SIGUIENTE2.ICO'
import imgUltimo from '../../../iconos/ULTIMO.ICO'

function Botones (props) {
  const totalRegs = props.totalRegs
  const editando = props.editando
  const diasCre = props.diasCre
  const esElect = props.esElect
  const datosFPs = props.datosFPs
  const recargarCab = props.recargarCab

  const [stdAdmin] = useAdmin()
  const [stdTransacc, dispatchTransacc, tiposAccionTransacc, tiposTarea] = useTransacc()
  const navigate = useNavigate()
  const paramsEmp = stdAdmin.paramsEmp

  const [grabando, setGrabando] = useState(false)

  const userVSAM = encryptStorage.getItem('userVSAM')
  const accCrear = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_CREA) === -1
  const accModificar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_MOD) === -1
  const accEliminar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_ELI) === -1
  const accSRIAutoriz = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_SRI_AUTORIZ) === -1
  // const accSRIAutorizMod = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_SRI_AUTORIZ_MOD) >= 0
  const accFormasPago = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_FORMAS_PAGO) === -1

  const userEmpConfig = encryptStorage.getItem('config')

  const poolName = stdAdmin.orclHost

  function ctrlClickSiguiente (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      cantRegs: 1
    })
    props.devuelveSiguiente()
  }
  function ctrlClick10Sig (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      cantRegs: 10
    })
    props.devuelve10Sig()
  }
  function ctrlClickUltima (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      index: stdTransacc.regsCab.length - 1
    })
    props.devuelveUltima()
  }

  function ctrlClickAnterior (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      cantRegs: -1
    })
    props.devuelveAnterior()
  }
  function ctrlClick10Ant (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      cantRegs: -10
    })
    props.devuelve10Ant()
  }
  function ctrlClickPrimera (e) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.cambiaReg,
      index: 0
    })
    props.devuelvePrimera()
  }

  function ctrlClickNuevoReg (e) {
    // e.preventDefault()
    const maxNuevas = userEmpConfig.numeros_maximos ? userEmpConfig.numeros_maximos.factNuevas : 5
    if (totalRegs >= maxNuevas) {
      Swal.fire(
        'NO ES POSIBLE CREAR MAS FACTURAS NUEVAS',
        `El número máximo de facturas nuevas permitido es ${maxNuevas}`,
        'info'
      )
      return
    }
    async function insNuevoReg () {
      setGrabando(true)
      props.estaGrabando({ enProceso: true, msjPantalla: 'Creando nuevo registro...' })
      const serie = props.children.substr(0, 8)
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CREAR UNA NUEVA FACTURA',
        {
          method: 'post',
          url: '/facturas/nuevas',
          data: {
            poolName,
            orclUserVSAM: stdAdmin.orclUsuario,
            noEmp: stdAdmin.noEmpresa,
            noUser: stdAdmin.noUsuario,
            serie
          }
        }
      )
      setGrabando(false)
      props.estaGrabando({ enProceso: false, msjPantalla: '' })
      if (respuestaAPI.status === 200) {
        dispatchTransacc({
          tipo: tiposAccionTransacc.nuevoReg,
          nuevaClave: respuestaAPI.data[0].NO_REG_CREADO
        })
        recargarCab()
      }
    }
    insNuevoReg()
  }
  function ctrlClickEliminaReg (e) {
    // e.preventDefault()
    async function deleteReg () {
      setGrabando(true)
      props.estaGrabando({ enProceso: true, msjPantalla: 'Eliminando el registro...' })
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ELIMINAR EL REGISTRO',
        {
          method: 'delete',
          url: `/facturas/nuevas/${stdTransacc.clave}`,
          data: { poolName }
        }
      )
      setGrabando(false)
      props.estaGrabando({ enProceso: false, msjPantalla: '' })
      if (respuestaAPI.status === 200) {
        dispatchTransacc({
          tipo: tiposAccionTransacc.eliminaReg,
          index: stdTransacc.index
        })
      }
    }
    Swal.fire({
      title: 'CONFIRME',
      text: `Desea eliminar el registro ${stdTransacc.index + 1}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) deleteReg()
    })
  }
  function ctrlClickGrabar (e) {
    // e.preventDefault()
    props.factValida().then((esValida) => {
      // Se asigna el NO_FACTURA final digitado por el usuario
      const noFactura = regActual.NO_FACTURA
      const esNC = (noFactura.substring(0, 1) === 'B')
      const fFactura = regActual.FECHA
      if (esValida) {
        async function grabaFactura () {
          setGrabando(true)
          props.estaGrabando({ enProceso: true, msjPantalla: 'Grabando...' })
          const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE ${modificando ? 'MODIFICAR' : 'GRABAR'} LA FACTURA`,
            {
              method: 'post',
              url: modificando ? '/facturas/modifica' : '/facturas/crea',
              data: modificando
                ? {
                    poolName,
                    orclUserVSAM: stdAdmin.orclUsuario,
                    noEmp: stdAdmin.noEmpresa,
                    noUser: stdAdmin.noUsuario,
                    noReg: stdTransacc.clave,
                    esElect,
                    rutaCarpeta: userEmpConfig.ruta_carpetas,
                    p12Nombre: userEmpConfig.p12_nombre,
                    p12Clave: userEmpConfig.p12_clave,
                    obsDatoAdic: userEmpConfig.obs_dato_adicional,
                    cualCodEnviaSRI: paramsEmp.CTRL_ITEMS_CODS_XML_ENVIA_SRI,
                    decimales: userEmpConfig.decimales,
                    accSRIAutoriz
                  }
                : {
                    poolName,
                    orclUserVSAM: stdAdmin.orclUsuario,
                    noEmp: stdAdmin.noEmpresa,
                    noUser: stdAdmin.noUsuario,
                    noReg: stdTransacc.clave,
                    noFactura,
                    fFactura,
                    diasCre,
                    esElect,
                    rutaCarpeta: userEmpConfig.ruta_carpetas,
                    p12Nombre: userEmpConfig.p12_nombre,
                    p12Clave: userEmpConfig.p12_clave,
                    obsDatoAdic: userEmpConfig.obs_dato_adicional,
                    decimales: userEmpConfig.decimales,
                    cualCodEnviaSRI: paramsEmp.CTRL_ITEMS_CODS_XML_ENVIA_SRI,
                    datosFPs,
                    seriesIng: userEmpConfig.series.ing,
                    accSRIAutoriz
                  }
            }
          )
          setGrabando(false)
          props.estaGrabando({ enProceso: false, msjPantalla: '' })
          if (respuestaAPI.status === 200) {
            const resultadoAPI = respuestaAPI.data.resultado
            // Se asigna el nuevo NO_FACTURA creada
            // o el NO_FACTURA_MOD (número original) si está modificando
            const noFactTmp = modificando ? stdTransacc.regsCab[stdTransacc.index].NO_FACTURA_MOD : resultadoAPI[0].NO_FACTURA
            if (respuestaAPI.data.msjAdic) {
              Swal.fire({
                title: `LA FACTURA SE ${modificando ? 'MODIFICO' : 'GRABO'} CON EXITO, PERO FALLO LA AUTORIZACION DEL SRI`,
                text: noFactura === noFactTmp
                  ? ''
                  : modificando
                    ? `Cambió el número de factura ${noFactTmp.substring(1)} por ${noFactura.substring(1)}`
                    : `El sistema grabó la factura con el No. ${noFactTmp.substring(1)}`,
                footer: respuestaAPI.data.respuesta + '\n' + respuestaAPI.data.msjAdic,
                icon: 'info'
              })
            } else {
              Swal.fire(
                `LA FACTURA SE ${modificando ? 'MODIFICO' : 'GRABO'} CON EXITO`,
                noFactura === noFactTmp
                  ? ''
                  : modificando
                    ? `Cambió el número de factura ${noFactTmp.substring(1)} por ${noFactura.substring(1)}`
                    : `El sistema grabó la factura con el No. ${noFactTmp.substring(1)}`,
                'success'
              )
            };
            if (modificando) {
              // Si se pudo recuperar la nueva factura modificada y envía a existentes para verla
              if (resultadoAPI.length) {
                props.devuelveNuevoReg(resultadoAPI)
                dispatchTransacc({
                  tipo: tiposAccionTransacc.cambiaTarea,
                  nuevaTarea: tiposTarea.consultando,
                  regsCab: resultadoAPI,
                  index: 0
                })
                navigate('/facturas/existentes')
              // No se pudo recuperar la nueva factura modificada y se va a filtrando
              } else {
                dispatchTransacc({
                  tipo: tiposAccionTransacc.cambiaTarea,
                  nuevaTarea: tiposTarea.filtrando
                })
              }
            // Devuelve la factura creada para que aparezca directamente en existentes
            } else props.devuelveNuevoReg(resultadoAPI, true)
          } else {
            if (respuestaAPI.response.status === 500 && respuestaAPI.response.data.yaExiste) {
              Swal.fire(
                'LA FACTURA YA EXISTE',
                'Cambie el número de factura y vuelva a intentarlo',
                'info'
              )
            }
          }
        }
        Swal.fire({
          title: 'CONFIRME',
          text: 'Los datos ingresados son correctos?',
          footer: !modificando && !esNC && datosFPs.sinAsignar
            ? '** La factura se grabará sin ninguna Forma de Pago'
            : '',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'SI',
          cancelButtonText: 'NO'
        }).then((result) => {
          if (result.isConfirmed) grabaFactura()
        })
      }
    })
  }
  function ctrClickModificar (e) {
    // e.preventDefault()
    async function modificaFactura () {
      setGrabando(true)
      props.estaGrabando({ enProceso: true, msjPantalla: 'Modificando...' })
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE PREPARAR LA FACTURA PARA SU MODIFICACION',
        {
          method: 'post',
          url: '/facturas/preModifica',
          data: {
            poolName,
            orclUserVSAM: stdAdmin.orclUsuario,
            noEmp: stdAdmin.noEmpresa,
            noUser: stdAdmin.noUsuario,
            noFactura: props.children
          }
        }
      )
      setGrabando(false)
      props.estaGrabando({ enProceso: false, msjPantalla: '' })
      if (respuestaAPI.status === 200) {
        // Coloca la clave devuelta (NO_REG) del registro creado para la modificación
        dispatchTransacc({
          tipo: tiposAccionTransacc.cambiaTarea,
          nuevaTarea: tiposTarea.modificando,
          regsCab: [{ NO_REG: respuestaAPI.data[0] }]
        })
        navigate('/facturas/nuevas')
      } else {
        if (respuestaAPI.response.status === 500 && respuestaAPI.response.data.yaExiste) {
          Swal.fire(
            'ESTA FACTURA YA ESTA EN PROCESO DE MODIFICACION',
            'Primero cancele la modificación pendiente',
            'info'
          )
        }
      }
    }
    modificaFactura()
  }
  function ctrlClickSRIAutoriz (e) {
    // e.preventDefault()
    async function sriAutoriza () {
      // const esNC = stdTransacc.clave.substr(0, 1) === 'B'
      const esNC = (stdTransacc.doc === gcTiposDoc.nc)
      setGrabando(true)
      props.estaGrabando({ enProceso: true, msjPantalla: 'Autorizando (SRI)...' })
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CONSEGUIR LA AUTORIZACION DEL SRI',
        {
          method: 'post',
          url: '/facturas/autorizaSRI',
          data: {
            poolName,
            orclUserVSAM: stdAdmin.orclUsuario,
            noEmp: stdAdmin.noEmpresa,
            noFactura: stdTransacc.clave,
            rutaCarpeta: userEmpConfig.ruta_carpetas,
            p12Nombre: userEmpConfig.p12_nombre,
            p12Clave: userEmpConfig.p12_clave,
            obsDatoAdic: userEmpConfig.obs_dato_adicional,
            cualCodEnviaSRI: paramsEmp.CTRL_ITEMS_CODS_XML_ENVIA_SRI,
            decimales: userEmpConfig.decimales,
            accSRIAutoriz
          }
        }
      )
      setGrabando(false)
      props.estaGrabando({ enProceso: false, msjPantalla: '' })
      if (respuestaAPI.status === 200) {
        if (respuestaAPI.data.respuesta) {
          Swal.fire({
            title: `LA ${esNC ? 'NOTA DE CREDITO' : 'FACTURA'} NO FUE AUTORIZADA. EL SRI RESPONDIO LO SIGUIENTE:`,
            text: respuestaAPI.data.respuesta,
            footer: respuestaAPI.data.msjAdic,
            icon: 'info'
          })
        } else {
          Swal.fire(
            `LA ${esNC ? 'NOTA DE CREDITO' : 'FACTURA'} SE AUTORIZO CON EXITO`,
            '** sin mensaje de respuesta SRI **',
            'success'
          )
        }
        props.devuelveNuevoReg(respuestaAPI.data.resultado)
        navigate('/facturas/existentes')
      }
    }

    Swal.fire({
      title: 'CONFIRME',
      text: 'Desea enviar el documento para ser autorizado por el SRI?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) sriAutoriza()
    })
  }

  if (!stdAdmin.auth || !userVSAM) return null

  const regActual = stdTransacc.regsCab.length
    ? stdTransacc.regsCab[stdTransacc.index]
    : []
  // Solo para cuando viene del botón modificar con el único campo NO_REG en regsCab
  if (!regActual.NO_FACTURA) return null

  const modificando = (stdTransacc.tarea === tiposTarea.modificando)
  const creando = (stdTransacc.tarea === tiposTarea.creando)
  const consultando = (stdTransacc.tarea === tiposTarea.consultando)
  const fmtoBotsImg = gcFmtosBotones.imagen
  const widthBots = gcFmtosBotones.imgWidth

  return (
    <Row className='justify-content-around ms-0 bg-primary-subtle rounded'>
      {/* LADO IZQUIERDO DE LA FILA DE BOTONES */}
      <Col className='' xs='auto'>
        <OverlayTrigger overlay={<Tooltip>{consultando ? 'nuevo filtro' : 'existentes'}</Tooltip>}>
          <Button
            className={fmtoBotsImg} size='sm' disabled={grabando}
            onClick={(e) => props.devuelveNuevoFiltro()}
          ><img src={imgFiltrar} alt='Filtrar' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>modificar</Tooltip>}>
          <Button
            className={fmtoBotsImg} size='sm' disabled={grabando}
            hidden={
              !accModificar || creando || modificando || regActual.ESTADO === 'A' ||
              (esElect && sriDatosDoc(regActual.SRI_AUTORIZACION).esValida && sriDatosDoc(regActual.SRI_AUTORIZACION).esProducc) ||
              (esElect && sriDatosDoc(regActual.SRI_CLAVE).esValida && sriDatosDoc(regActual.SRI_CLAVE).esProducc)
            }
            onClick={ctrClickModificar}
          ><img src={imgModificar} alt='modificar' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>{modificando ? 'grabar modificación' : 'grabar'}</Tooltip>}>
          <Button
            className={fmtoBotsImg} size='sm' disabled={grabando}
            hidden={!accCrear || consultando}
            onClick={ctrlClickGrabar}
          ><img src={imgGrabar} alt='grabar' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>{modificando ? 'cancelar modificación' : 'cancelar'}</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando}
            hidden={consultando}
            onClick={(e) => props.devuelveCancelar()}
          ><img src={imgCancelar} alt='cancelar' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>nueva factura</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando}
            hidden={!accCrear || consultando || modificando}
            onClick={ctrlClickNuevoReg}
          ><img src={imgMas} alt='crear' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>elimina factura {consultando ? props.children : stdTransacc.index + 1}</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || totalRegs <= 1}
            hidden={modificando || (consultando && (!accEliminar || regActual.ESTADO !== 'A'))}
            onClick={ctrlClickEliminaReg}
          ><img src={imgMenos} alt='eliminar' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>listado</Tooltip>}>
          <Button
            className={fmtoBotsImg} size='sm' hidden={!consultando}
            onClick={(e) => dispatchTransacc({ tipo: tiposAccionTransacc.setListar })}
          ><img src={imgListado} alt='Listado' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>Email Cliente</Tooltip>}>
          <Button
            className={fmtoBotsImg} size='sm' hidden={!consultando}
            onClick={(e) => props.devuelveRepIndividual('CLIENTE', true)}
          ><img src={imgMail} alt='Email Cliente' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>imprimir</Tooltip>}>
          <SplitButton
            className={gcFmtosBotones.imgConMenu} size='sm'
            variant=''
            disabled={grabando} hidden={!consultando}
            title={(<img src={imgImprimir} alt='imprimir' width={widthBots} />)}
            onClick={(e) => props.devuelveRepIndividual('RODILLO')}
          >
            <Dropdown.Item
              className={gcFmtosBotones.dropDownItem}
              onClick={(e) => props.devuelveRepIndividual('RODILLO')}
            >Rodillo
            </Dropdown.Item>
            <Dropdown.Item
              className={gcFmtosBotones.dropDownItem}
              onClick={(e) => props.devuelveRepIndividual('CLIENTE')}
            >Cliente
            </Dropdown.Item>
            <Dropdown.Item
              className={gcFmtosBotones.dropDownItem}
              onClick={(e) => props.devuelveRepIndividual('INTERNO')}
            >Interno
            </Dropdown.Item>
          </SplitButton>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>pide autorización SRI</Tooltip>}>
          <Button
            className='fw-bold fst-italic'
            size='sm'
            disabled={grabando}
            hidden={!consultando || !accSRIAutoriz || regActual.ESTADO === 'A' ||
              (esElect && sriDatosDoc(regActual.SRI_AUTORIZACION).esValida)}
            onClick={ctrlClickSRIAutoriz}
          >SRI
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>Formas de Pago</Tooltip>}>
          <Button
            className='fw-bold fst-italic'
            size='sm'
            disabled={grabando}
            hidden={!creando || !accFormasPago || regActual.NO_FACTURA.substring(0, 1) === 'B'}
            onClick={(e) => props.devuelveFP()}
          >FP
          </Button>
        </OverlayTrigger>
      </Col>

      {/* TEXTO EN LA MITAD DE LA FILA DE BOTONES */}
      <Col className='text-center' xs={1}>
        <Form.Label className='fs-6'>{stdTransacc.index + 1}/{totalRegs}</Form.Label>
      </Col>

      {/* LADO DERECHO DE LA FILA DE BOTONES */}
      <Col className='' xs='auto'>
        <OverlayTrigger overlay={<Tooltip>primera factura</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index === 0}
            onClick={ctrlClickPrimera}
          ><img src={imgPrimero} alt='primero' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>10 anteriores</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index <= 9}
            onClick={ctrlClick10Ant}
          ><img src={imgAnterior2} alt='anterior2' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>factura anterior</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index === 0}
            onClick={ctrlClickAnterior}
          ><img src={imgAnterior} alt='anterior' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>factura siguiente</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index === totalRegs - 1}
            onClick={ctrlClickSiguiente}
          ><img src={imgSiguiente} alt='siguiente' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>10 siguientes</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index > totalRegs - 11}
            onClick={ctrlClick10Sig}
          ><img src={imgSiguiente2} alt='siguiente2' width={widthBots} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>última factura</Tooltip>}>
          <Button
            className={fmtoBotsImg}
            size='sm'
            disabled={grabando || editando || stdTransacc.index === totalRegs - 1}
            onClick={ctrlClickUltima}
          ><img src={imgUltimo} alt='ultimo' width={widthBots} />
          </Button>
        </OverlayTrigger>
      </Col>
    </Row>
  )
}

export default Botones
