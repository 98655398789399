/* eslint-disable react/jsx-indent */
import { useEffect, useReducer, useState } from 'react'
import { Row, InputGroup, Spinner, Form, Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2'

import restAPIAxios from '../../../utils/axios'
import encryptStorage from '../../../utils/encryptStorage'
import useAdmin from '../../../context/Admin'
import useTransacc from '../../../context/Transacc'

import {
  gcFmtosBotones, gcFmtosTablas, gcTablasLogicas, setInfoCampoFactura,
  anchoPantDisponible, fmtosTablaFiltroCampo, setInfoCampoProforma, gcFmtoMaxRows,
  fechaFormat, colocaFocoDirecto, gcTipoEditTablas, gcFmtosColorFondo
} from '../../../utils/generales'

import EmpSel from '../../admin/EmpSel'
import Encabezado from './CliEncabezado'
import TablaBotones from '../../generales/tablas/TablaBotones'
import Cliente from './Cliente'

// import imgGrabar from '../../iconos/GRABAR.BMP'
import imgMas from '../../../iconos/MAS.ICO'
import imgMenos from '../../../iconos/MENOS.ICO'
import imgModificar from '../../../iconos/MODIFICAR.ICO'

const acciones = {
  asignaReg: 'ASIGNAR REGISTRO',
  vacia: 'VACIAR',
  crea: 'CREAR',
  creado: 'CREADO',
  modifica: 'MODIFICAR',
  modificado: 'MODIFICADO',
  cargaRegs: 'CARGA REGISTROS',
  cambiaReg: 'CAMBIAR REGISTRO'
}
const regVacio = {
  NO_CLIENTE: null,
  ID: '',
  NOMBRE: '',
  TIPO: 'O',
  SIGLAS: '',
  CATEGORIA: '',
  DIRECC: '',
  TELEF: '',
  FAX: '',
  EMAIL: '',
  ATTPERSONA: '',
  NO_VENDEDOR: null,
  OTROS_DATOS: '',
  EXTRA1_TXT: '',
  P_DSCTO: 0,
  NO_PV: 0,
  V_INI_X_COB: 0,
  F_DESDE: fechaFormat(new Date(), 'AMD', '-'),
  L_CREDITO: 0,
  V_LIMITE_CRE: 0,
  VCRE_PERIODO_MESES: null,
  VCRE_DIA_INI: null,
  VEND_NOMBRE: null
}

const estadoIni = {
  filtro: '', tipoEdit: gcTipoEditTablas.sinEditar, regEdit: regVacio, tabla: [], index: -1
}

const reducer = (estado, accion) => {
  switch (accion.tipo) {
    case acciones.asignaReg:
      return { ...estado, tipoEdit: gcTipoEditTablas.sinEditar, regEdit: estado.tabla[accion.index], tabla: [], index: -1 }
    case acciones.vacia:
      return { ...estadoIni, filtro: accion.filtro }
    case acciones.crea:
      return { ...estadoIni, filtro: estado.filtro, tipoEdit: gcTipoEditTablas.creando }
    case acciones.modifica:
      return { ...estado, tipoEdit: gcTipoEditTablas.modificando, regEdit: accion.regEdit, index: accion.index }
    case acciones.modificado:
      return {
        filtro: accion.regEdit.NOMBRE,
        tipoEdit: gcTipoEditTablas.modificado,
        regEdit: accion.regEdit,
        tabla: [accion.regEdit],
        index: 0
      }
    case acciones.creado:
      return {
        filtro: accion.regEdit.NOMBRE,
        tipoEdit: gcTipoEditTablas.creado,
        regEdit: accion.regEdit,
        tabla: [accion.regEdit],
        index: 0
      }
    case acciones.cargaRegs:
      return { ...estadoIni, filtro: estado.filtro, tabla: accion.tabla }
    case acciones.cambiaReg: {
      let nIndex = estado.index
      // Cuando viene directamente el index en el cual se desea colocar
      if (accion.index >= 0) nIndex = accion.index
      // Cuando viene una cantidad de regEdit que se desea mover a partir del actual
      else {
        nIndex += accion.cantRegs
        // Verifica rangos y reasigna el valor si es necesario
        if (nIndex > 0 && nIndex > estado.tabla.length - 1) {
          nIndex = estado.regsCab.length - 1
        } else if (nIndex < 0) nIndex = 0
      }
      return {
        ...estado,
        tipoEdit: gcTipoEditTablas.modificando,
        regEdit: estado.tabla[nIndex],
        index: nIndex
      }
    }
    default:
      return estado
  }
}

// COMPONENTE
function Clientes (
  {
    children,
    opcMenuCall,
    updateTablaDoc,
    esOrden,
    devuelveFiltro,
    devuelveRegistro,
    devuelveDblClick
  }
) {
  // Solo porque el ESLINT STANDARD no deja colocar el valor TRUE en la propiedad
  // son propiedades booleanas que podrían llegar con valor UNDEFINED
  if (updateTablaDoc === undefined) updateTablaDoc = false

  const [stdAdmin] = useAdmin()
  const [stdTransacc, dispatchTransacc, tiposAccionTransacc, tiposTarea] = useTransacc()
  const [estado, dispatch] = useReducer(reducer, estadoIni)

  const poolName = stdAdmin.orclHost

  const consultando = (stdTransacc.tarea === tiposTarea.consultando)
  const filtrando = (stdTransacc.tarea === tiposTarea.filtrando)
  const noCliTransacc = stdTransacc.otrosDat.cliente ? stdTransacc.otrosDat.cliente[0] : null

  // Extrae los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')

  const infoTablaLista = {
    url: '/clientes',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.cliente,
    tabCampos: ['ID', 'NOMBRE', 'TIPO', 'CATEGORIA', 'SIGLAS', 'DIRECC', 'P_DSCTO', 'NO_PV',
      'V_INI_X_COB', 'TELEF', 'EMAIL', 'ATTPERSONA', 'VEND_NOMBRE', 'FAX', 'OTROS_DATOS',
      'EXTRA1_TXT', 'F_DESDE', 'L_CREDITO', 'V_LIMITE_CRE', 'VCRE_PERIODO_MESES', 'VCRE_DIA_INI',
      'USER_NOMBRE', 'USER_ABREV', 'F_MODIFICA'],
    txtLblTabla: 'Cliente:',
    txtCampos: ['ID', 'Nombre', 'Tipo', 'Categoría', 'Siglas', 'Dirección', 'Dscto.%', 'No.PV',
      'Días cre.', 'Teléfono', userEmpConfig.textos_labels.cliEmail || 'Email',
      'Atención persona', userEmpConfig.textos_labels.cliVendedor || 'Vendedor',
      userEmpConfig.textos_labels.cliFax || 'Fax', 'Otros datos',
      userEmpConfig.textos_labels.cliExtra1 || 'Dato adicional',
      'Cliente desde', 'Controlar', 'Valor máximo', 'Periodo cada', 'Inicia día del mes',
      'Usuario', 'Abrev.', 'Fecha y hora'],
    campoFiltro: 'NOMBRE',
    condFija: 'and A.NO_PERSONA is null'
  }

  const [filtro, setFiltro] = useState('')
  const [esperando, setEsperando] = useState(false)
  const [recargar, setRecargar] = useState(false)
  const [ordenadoX, setOrdenadoX] = useState({ campo: infoTablaLista.campoFiltro, index: 1 })

  const [fmtoNoExiste, setFmtoNoExiste] = useState('')
  const [cambioFiltro, setCambioFiltro] = useState(false)

  let accMnuFact = false
  let accCejaProf = false
  let accMnuClientes = false
  let accCreaMod = false
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (userVSAM) {
    accMnuFact = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_FACTURACION) === -1
    accCejaProf = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_CEJA) === -1
    accMnuClientes = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_CLIENTES) === -1
    accCreaMod = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_CLI_CREA_MOD) === -1
  }

  const FMTO_NO_EXISTE = gcFmtosColorFondo.noExiste

  // FUNCIONES PARA EL useEffect()

  useEffect(() => {
    console.log('🚀 ~ file: Clientes.jsx:150 ~ useEffect ~ children, noCliTransacc, esOrden, recargar, opcMenuCall, estado, stdTransacc.index, stdTransacc.otrosDat:', children, noCliTransacc, esOrden, recargar, opcMenuCall, estado, stdTransacc.index, stdTransacc.otrosDat)
    setFiltro(children ?? '')
    setFmtoNoExiste(noCliTransacc || filtrando ? '' : FMTO_NO_EXISTE)

    // Para que cargue el cliente inicial y lo devuelva
    if (opcMenuCall) {
      // solo carga el cliente inicial si no ha salido de un CREADO o MODIFICADO
      // ** se requiere esta condición porque cambia el children y noCliTransacc
      // ** cuando regresa de un creado o modificado solo en opcMenuCall
      if (estado.tipoEdit !== gcTipoEditTablas.creado && estado.tipoEdit !== gcTipoEditTablas.modificado) {
        cargaClientes('', true)
      }
    // Solo cuando viene de la opción de Menú
    // Obligar al usuario a volver a elegir un filtro y coloca el foco en el filtro
    } else {
      dispatch({ tipo: acciones.vacia, filtro })
      colocaFocoDirecto('clientes-NOMBRE')
    }
  }, [children, noCliTransacc, esOrden, recargar])
  // ** No se puede poner el filtro como dependencia porque cada vez que cambie el filtro
  // ** (ej. ctrlClickChange) entraría al useEffect y volvería el filtro al original (children)

  // FUNCIONES PRIVADAS DEL COMPONENTE
  async function cargaClientes (nuevoOrden, soloNoCliente) {
    // Verificación para evitar errores. Inconsistente cargar un cliente si no hay stdTransacc.otrosDat.cliente[0]
    const claveDatCli = 'NO_CLIENTE'
    if (soloNoCliente && !noCliTransacc) {
      dispatchTransacc({
        tipo: tiposAccionTransacc.setOtrosDat,
        otrosDat: { noPVCli: null, pDsctoCli: null, asignarPDscto: false }
      })
      if (devuelveRegistro) devuelveRegistro(claveDatCli, '', {})
      return
    }
    setEsperando(true)
    // Para borrar los datos del cliente en caso de que este creando, modificando o listando
    // y que así se llame directamente a la captura de nuevos datos
    dispatch({ tipo: acciones.vacia, filtro })
    if (nuevoOrden) setOrdenadoX(nuevoOrden)
    const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE CARGAR ${infoTablaLista.txtTabla}`,
      {
        method: 'post',
        url: infoTablaLista.url,
        data: {
          poolName,
          orclUserVSAM: stdAdmin.orclUsuario,
          noEmp: stdAdmin.noEmpresa,
          campoFiltro: soloNoCliente ? claveDatCli : infoTablaLista.campoFiltro,
          filtro,
          condFija: soloNoCliente ? '' : infoTablaLista.condFija,
          condClaveUnica: soloNoCliente ? 'and A.' + claveDatCli + ' = ' + noCliTransacc : '',
          ordenadoX: nuevoOrden ? nuevoOrden.campo : ordenadoX.campo
        }
      }
    )
    if (respuestaAPI.status === 200) {
      if (respuestaAPI.data.length > infoTablaLista.maxRegs) {
        Swal.fire(
          `EL FILTRO SELECCIONADO CARGA MAS DE ${infoTablaLista.maxRegs} ${infoTablaLista.txtTabla} (${respuestaAPI.data.length})`,
          'Elija un filtro que reduzca la cantidad de registros para seleccionar',
          'info'
        )
      } else {
        dispatchTransacc({
          tipo: tiposAccionTransacc.setOtrosDat,
          otrosDat: {
            noPVCli: respuestaAPI.data[0].NO_PV,
            pDsctoCli: respuestaAPI.data[0].P_DSCTO,
            // No va True porque cargaClientes se utiliza para presentar el valor previo
            asignarPDscto: false
          }
        })
        if (soloNoCliente && devuelveRegistro) {
          devuelveRegistro(claveDatCli, filtro, respuestaAPI.data[0])
        } else dispatch({ tipo: acciones.cargaRegs, tabla: respuestaAPI.data })
      }
    }
    setEsperando(false)
  }
  async function actualizaCampo ({ campo, valor }) {
    setEsperando(true)
    // Establece los datos para actualizar la Tabla temporal
    let infoTablaActualiza
    if (opcMenuCall === gcTablasLogicas.fact) {
      infoTablaActualiza = setInfoCampoFactura(campo, valor)
    } else infoTablaActualiza = setInfoCampoProforma(campo, valor)
    // Establece la clave (valor) de la condición que va en la tabla temporal que actualiza
    const infoTablaCampo = {
      poolName,
      tabla: infoTablaActualiza.tabla,
      campo: infoTablaActualiza.campo,
      valor,
      cond: infoTablaActualiza.campoClave + '=' + stdTransacc.clave,
      ceroEsNulo: false
    }
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR EL CAMPO EN LA BD',
      {
        method: 'patch',
        url: infoTablaActualiza.url,
        data: infoTablaCampo
      }
    )
    setEsperando(false)
    if (respuestaAPI.status === 200) {
      if (respuestaAPI.data[0] === 0) {
        Swal.fire({
          title: 'EL VALOR NO SE ACTUALIZO EN LA BD',
          text: 'consulte a su técnico',
          footer: 'La respuesta de la API fue 0',
          icon: 'info'
        })
        return false
      } else return true
    } else {
      return false
    }
  }
  function eliminaReg (index) {
    async function eliminar () {
      setEsperando(true)
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ELIMINAR EL CLIENTE',
        {
          method: 'delete',
          url: '/cliente',
          data: {
            poolName,
            orclUserVSAM: stdAdmin.orclUsuario,
            noUser: stdAdmin.noUsuario,
            noEmp: stdAdmin.noEmpresa,
            noCliente: estado.tabla[index].NO_CLIENTE,
            noInstit: estado.tabla[index].NO_INSTIT
          }
        }
      )
      setEsperando(false)
      if (respuestaAPI.status === 200) {
        setRecargar(!recargar)
      }
    }
    Swal.fire({
      title: 'CONFIRME',
      text: `Desea eliminar el cliente (${estado.tabla[index].ID}) ${estado.tabla[index][infoTablaLista.campoFiltro]}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) eliminar()
    })
  }

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  function ctrlChangeFiltro (e) {
    setCambioFiltro(true)
    setFiltro(e.target.value)
    // No se debe devolver el filtro (SOLO SI NO ESTOY FILTRANDO) para que aparezca el color amarillo,
    // que muestra si el campo no es el mismo que el de la BD
    if (devuelveFiltro && filtrando) devuelveFiltro(e.target.value)
    if (estado.tabla.length > 0) dispatch({ tipo: acciones.vacia, filtro })
  }
  async function ctrlBlurFiltro (e) {
    setCambioFiltro(false)
    // Si no hubo cambios en el filtro, o no es parte de otra opción de menú, o esta Filtrando
    // no hace nada
    if (!opcMenuCall || !cambioFiltro || filtrando) {
      return
    }
    // Actualiza el campo a NULL
    if (e.target.value === '') {
      dispatch({ tipo: acciones.vacia, filtro: '' })
      setFmtoNoExiste(FMTO_NO_EXISTE)
      if (devuelveRegistro) devuelveRegistro(infoTablaLista.campoFiltro, '', {})
      if (updateTablaDoc) {
        if (await actualizaCampo({ campo: 'NO_CLIENTE', valor: null })) {
          setFiltro('')
          if (devuelveFiltro) devuelveFiltro('')
          dispatchTransacc({
            tipo: tiposAccionTransacc.setOtrosDat,
            otrosDat: {
              cliente: [null, null, null],
              noPVCli: null,
              pDsctoCli: null,
              asignarPDscto: false
            }
          })
        } else {
          setFiltro(children ?? '')
          if (devuelveFiltro) devuelveFiltro(children ?? '')
        }
      } else {
        setFiltro('')
        if (devuelveFiltro) devuelveFiltro('')
        dispatchTransacc({
          tipo: tiposAccionTransacc.setOtrosDat,
          otrosDat: {
            cliente: [null, null, null],
            noPVCli: null,
            pDsctoCli: null,
            asignarPDscto: false
          }
        })
      }
    // Actualiza el campo N_CLIENTETMP con el valor del filtro y NO_CLIENTE a null
    } else if (e.target.value.trim() !== children) {
      dispatch({ tipo: acciones.vacia, filtro: e.target.value })
      setFmtoNoExiste(FMTO_NO_EXISTE)
      if (devuelveRegistro) devuelveRegistro(infoTablaLista.campoFiltro, e.target.value, {})
      // Actualiza el campo N_CLIENTETMP en T_FACTURA con el valor del filtro
      if (updateTablaDoc) {
        if (await actualizaCampo({ campo: 'N_CLIENTETMP', valor: e.target.value })) {
          setFiltro(e.target.value)
          if (devuelveFiltro) devuelveFiltro(e.target.value)
          dispatchTransacc({
            tipo: tiposAccionTransacc.setOtrosDat,
            otrosDat: {
              cliente: [null, null, e.target.value],
              noPVCli: null,
              pDsctoCli: null,
              asignarPDscto: false
            }
          })
        // Falló la actualización y se regresa el filtro al valor original
        } else {
          setFiltro(children ?? '')
          if (devuelveFiltro) devuelveFiltro(children ?? '')
        }
      // No se requiere actualización y solo se reemplaza el filtro con el nuevo valor
      } else {
        setFiltro(e.target.value)
        if (devuelveFiltro) devuelveFiltro(e.target.value)
        dispatchTransacc({
          tipo: tiposAccionTransacc.setOtrosDat,
          otrosDat: {
            cliente: [null, null, e.target.value],
            noPVCli: null,
            pDsctoCli: null,
            asignarPDscto: false
          }
        })
      }
    // (e.target.value.trim() === children.trim()) --> El usuario volvió al nombre recibido
    // y por lo tanto recupera el valor de stdTransacc.otrosDat.cliente[0]
    } else if (noCliTransacc) {
      dispatch({ tipo: acciones.vacia, filtro: e.target.value })
      setFiltro(e.target.value)
      setFmtoNoExiste('')
      // Actualiza el campo NO_CLIENTE en T_FACTURA y poner en amarillo el campo
      if (updateTablaDoc) {
        await actualizaCampo({ campo: 'NO_CLIENTE', valor: stdTransacc.otrosDat.cliente[0] })
        // No importa si actualizaCampo devuelve error, igual se regresa al valor original
        // porque e.target.value.trim() === children.trim()
        if (devuelveFiltro) devuelveFiltro(e.target.value)
      } else {
        if (devuelveFiltro) devuelveFiltro(e.target.value)
      }
    // No existe stdTransacc.otrosDat.cliente[0]
    } else {
      setFiltro(e.target.value)
      setFmtoNoExiste(FMTO_NO_EXISTE)
    }
  }
  function ctrlLblDblClick (e) {
    // e.preventDefault()
    if (devuelveDblClick === undefined) {
      if (opcMenuCall) {
        Swal.fire(
          'NO ESTA ACTIVA LA OPCION DE ORDENADO PARA ESTE CAMPO',
          '',
          'info'
        )
      }
    } else devuelveDblClick()
  }
  function ctrlDblClickLista (e) {
    // e.preventDefault()
    ctrClickModificar(e)
  }
  async function ctrlClickToggle (e) {
    // Verifica si se quiere filtrar por ID (ruc, cédula o pasaporte)
    if (filtro.substring(0, 1) === '.') {
      const indexCol = infoTablaLista.txtCampos.indexOf('ID')
      if (indexCol >= 0) cargaClientes({ campo: infoTablaLista.tabCampos[indexCol], index: indexCol })
      // No encuentra la columna ID
      else cargaClientes()
    } else cargaClientes()
  }
  async function ctrlClickLista (e) {
    const fila = e.target.closest('tr')
    if (fila) {
      // Captura la columna donde presiona el click para ver si es el botón Modificar
      const columna = e.target.closest('td')
      // Capturo el atributo name para verificar en que columna estoy
      const colName = columna.getAttribute('name')
      // Captura el index de la fila
      const index = columna.getAttribute('index')

      // Si estoy en el botón Modifica no hace nada (tiene que dar paso a la modificación)
      // o en caso de que presione en la cabecera u otro lugar donde index es null
      if (colName === 'BOTON-MODIFICA' || !index) return

      const nuevoFiltro = estado.tabla[index][infoTablaLista.campoFiltro]
      if (updateTablaDoc && !filtrando) {
        // actualiza el Campo en la Tabla
        if (await actualizaCampo({ campo: 'NO_CLIENTE', valor: estado.tabla[index].NO_CLIENTE })) {
          setFiltro(nuevoFiltro)
          dispatch({ tipo: acciones.asignaReg, index: parseInt(index) })
          setFmtoNoExiste('')
          dispatchTransacc({
            tipo: tiposAccionTransacc.setOtrosDat,
            otrosDat: {
              cliente: [estado.tabla[index].NO_CLIENTE, nuevoFiltro, null],
              noPVCli: estado.tabla[index].NO_PV,
              pDsctoCli: estado.tabla[index].P_DSCTO,
              asignarPDscto: true
            }
          })
          if (devuelveFiltro) devuelveFiltro(nuevoFiltro)
          if (devuelveRegistro) devuelveRegistro(infoTablaLista.campoFiltro, nuevoFiltro, estado.tabla[index])
        } else {
          setFiltro(children ?? '')
          dispatch({ tipo: acciones.vacia, filtro: (children ?? '') })
          setFmtoNoExiste(noCliTransacc ? '' : FMTO_NO_EXISTE)
          dispatchTransacc({
            tipo: tiposAccionTransacc.setOtrosDat,
            otrosDat: { noPVCli: null, pDsctoCli: null, asignarPDscto: false }
          })
          if (devuelveFiltro) devuelveFiltro(children ?? '')
          if (devuelveRegistro) devuelveRegistro(infoTablaLista.campoFiltro, '', {})
        }
      } else {
        dispatch({ tipo: acciones.asignaReg, index: parseInt(index) })
        setFiltro(nuevoFiltro)
        setFmtoNoExiste('')
        dispatchTransacc({
          tipo: tiposAccionTransacc.setOtrosDat,
          otrosDat: {
            cliente: [estado.tabla[index].NO_CLIENTE, nuevoFiltro, null],
            noPVCli: estado.tabla[index].NO_PV,
            pDsctoCli: estado.tabla[index].P_DSCTO,
            asignarPDscto: true
          }
        })
        if (devuelveFiltro) devuelveFiltro(nuevoFiltro)
        if (devuelveRegistro) devuelveRegistro(infoTablaLista.campoFiltro, nuevoFiltro, estado.tabla[index])
      }
      colocaFocoDirecto('clientes-NOMBRE')
    }
  }
  function ctrlClickHeadLista (e) {
    // e.preventDefault()
    if (e.target.textContent !== '') {
      const indexCol = infoTablaLista.txtCampos.indexOf(e.target.textContent)
      if (indexCol > -1) {
        cargaClientes({ campo: infoTablaLista.tabCampos[indexCol], index: indexCol })
      }
    }
  }
  function ctrlClickNuevo (e) {
    // Para que cierre la edición, con el mismo botón, cuando esta abierta
    if (estado.tipoEdit === gcTipoEditTablas.creando) {
      dispatch({ tipo: acciones.vacia, filtro })
    } else dispatch({ tipo: acciones.crea })
  }
  function ctrClickModificar (e) {
    // e.preventDefault()
    const index = e.target.getAttribute('index')
    if (index) {
      dispatch({ tipo: acciones.modifica, regEdit: estado.tabla[index], index: parseInt(index) })
    }
  }
  function ctrClickEliminar (e) {
    // e.preventDefault()
    const index = e.target.getAttribute('index')
    if (index) {
      eliminaReg(index)
    }
  }
  // FUNCIONES DE CONTROL (EVENTOS) DEVUELTOS POR LOS COMPONENTES
  function ctrlCambioEmp () {
    setRecargar(!recargar)
  }
  function ctrlCreaModCli (nuevoRegistro, esNuevo) {
    const nuevoFiltro = nuevoRegistro[infoTablaLista.campoFiltro]
    setFiltro(nuevoFiltro)
    dispatch({ tipo: esNuevo ? acciones.creado : acciones.modificado, regEdit: nuevoRegistro })
    // No se asigna todavía a otrosDat cuando se crea el Cliente
    dispatchTransacc({
      tipo: tiposAccionTransacc.setOtrosDat,
      otrosDat: {
        cliente: [esNuevo ? null : nuevoRegistro.NO_CLIENTE,
          esNuevo ? null : nuevoRegistro.NOMBRE, esNuevo ? nuevoRegistro.NOMBRE : null],
        noPVCli: esNuevo ? null : nuevoRegistro.NO_PV,
        pDsctoCli: esNuevo ? null : nuevoRegistro.P_DSCTO,
        asignarPDscto: !esNuevo
      }
    })
    if (devuelveFiltro) devuelveFiltro(nuevoFiltro)
    if (devuelveRegistro) {
      devuelveRegistro(infoTablaLista.campoFiltro, nuevoFiltro, nuevoRegistro)
    }
  }

  const size = opcMenuCall ? 'sm' : ''
  const fmtoOrden = ' bg-primary text-white'
  const widthImg = gcFmtosBotones.imgWidthSM
  const idRowFiltro = 'tabla-' + infoTablaLista.txtTabla + '-' + infoTablaLista.campoFiltro
  const styleBotsTbl = gcFmtosBotones.styleTabla
  const disableBotMod = opcMenuCall === gcTablasLogicas.prof
    ? !accMnuFact || !accCejaProf || !accMnuClientes || !accCreaMod
    : opcMenuCall === gcTablasLogicas.fact
      ? !accMnuFact || !accMnuClientes || !accCreaMod
      : !accMnuFact || !accCreaMod
  const disableBotEli = opcMenuCall === gcTablasLogicas.prof
    ? !accMnuFact || !accCejaProf || !accMnuClientes
    : opcMenuCall === gcTablasLogicas.fact
      ? !accMnuFact || !accMnuClientes
      : !accMnuFact
  const posLCREDITO = infoTablaLista.tabCampos.indexOf('L_CREDITO')
  const anchoColBot = 44
  const fmtoMaxRows = gcFmtoMaxRows(estado.tabla.length, infoTablaLista.maxRegs)

  return (
    <>
      <Encabezado sinEncabezado={opcMenuCall} />
      <Row className={gcFmtosTablas(opcMenuCall).rowEmp} hidden={opcMenuCall}>
        <EmpSel devuelveCambiaEmp={ctrlCambioEmp} esHeader />
        <TablaBotones
          estado={estado} dispatch={dispatch} acciones={acciones}
        />
      </Row>
      {/* FILTRO */}
      <Row
        className={gcFmtosTablas(opcMenuCall).row}
        id={idRowFiltro} disabled={consultando}
      >
        <InputGroup
          className={gcFmtosTablas(opcMenuCall).inputGroup} size={size}
          style={gcFmtosTablas(opcMenuCall).inputGStyle}
        >
          <InputGroup.Text
            id='lblCliente'
            className={gcFmtosTablas(opcMenuCall, esOrden && consultando).labelFiltro}
            onDoubleClick={ctrlLblDblClick}
          >Cliente:
          </InputGroup.Text>
          <Spinner animation='grow' size={size} hidden={!esperando} />
          <Form.Control
            id='clientes-NOMBRE'
            className={gcFmtosTablas(opcMenuCall).filtro + (opcMenuCall ? fmtoNoExiste : '')}
            type='text'
            value={filtro}
            autoFocus
            disabled={opcMenuCall && consultando}
            placeholder='Elija un filtro para obtener clientes'
            aria-describedby='lblCliente'
            onChange={ctrlChangeFiltro}
            onBlur={ctrlBlurFiltro}
          />
          <Dropdown.Toggle
            className='bg-secondary-subtle'
            variant='outline-secondary'
            id='tablaCtrl'
            disabled={!filtro}
            hidden={opcMenuCall && consultando}
            onClick={ctrlClickToggle}
          />
          <OverlayTrigger overlay={<Tooltip>nuevo cliente</Tooltip>}>
            <Button
              className={gcFmtosBotones.filtro}
              id='NUEVO' type='submit' size={size} aria-label='Close'
              hidden={!accCreaMod || filtrando || consultando}
              onClick={ctrlClickNuevo}
            ><img src={imgMas} alt='nuevo' width={gcFmtosBotones.imgWidthSM} />
            </Button>
          </OverlayTrigger>
        </InputGroup>
      </Row>
      {/* DATOS CLIENTE O TABLA */}
      {estado.tipoEdit === gcTipoEditTablas.creando || estado.tipoEdit === gcTipoEditTablas.modificando
        ? <Cliente
            opcMenuCall={opcMenuCall} idRowFiltro={idRowFiltro}
            disableBotMod={disableBotMod}
            estado={estado} dispatch={dispatch} acciones={acciones}
            devuelveEsperando={(valor) => setEsperando(valor)}
            devuelveCreaModCli={ctrlCreaModCli}
            devuelveEliminaReg={(index) => eliminaReg(index)}
          />
        : <div
            id='tablaClientes'
            className='overflow-scroll border border-3 ms-1 me-1 mb-1'
            style={{ maxHeight: opcMenuCall ? 400 : 600, width: opcMenuCall ? anchoPantDisponible(idRowFiltro) - 15 : undefined }}
            hidden={opcMenuCall !== '' && !estado.tabla.length}
          >
            <table
              className='table table-bordered table-sm table-hover table-responsive-md'
              style={{ fontSize: '10px' }}
            >
              <thead className='sticky-top'>
                <tr className='table-primary text-center'>
                  <th
                    colSpan={posLCREDITO + ((opcMenuCall || disableBotEli) && disableBotMod ? 1 : opcMenuCall || disableBotMod || disableBotEli ? 2 : 3)}
                  >DATOS  DEL  CLIENTE
                  </th>
                  <th colSpan='4'>Datos para control del crédito</th>
                  <th colSpan='3'>Ultima actualización</th>
                </tr>
                <tr className='table-primary'>
                  <th
                    key={0} name='INDEX'
                    className={fmtoMaxRows.fmto}
                    style={{ minWidth: fmtoMaxRows.ancho, position: 'sticky', left: 0 }}
                  >{estado.tabla.length}
                  </th>
                  <th
                    key='BOTON-MODIFICA' name='BOTON-MODIFICA'
                    hidden={disableBotMod}
                    style={{ minWidth: anchoColBot, position: 'sticky', left: fmtoMaxRows.ancho }}
                  />
                  {opcMenuCall
                    ? null
                    : <th
                        key='BOTON-ELIMINA' name='BOTON-ELIMINA'
                        hidden={disableBotEli}
                        style={{
                          minWidth: anchoColBot,
                          position: 'sticky',
                          left: disableBotMod ? fmtoMaxRows.ancho : fmtoMaxRows.ancho + anchoColBot
                        }}
                      />}
                  {infoTablaLista.txtCampos.map((txtCampo, index) => (
                    <th
                      key={index + 1} name={infoTablaLista.tabCampos[index]}
                      className={estado.tabla.length
                        ? fmtosTablaFiltroCampo(
                          infoTablaLista.txtTabla,
                          infoTablaLista.tabCampos[index],
                          estado.tabla[0][infoTablaLista.tabCampos[index]],
                          userEmpConfig.decimales
                        ).fmto + (txtCampo === infoTablaLista.txtCampos[ordenadoX.index] ? fmtoOrden : '')
                        : ''}
                      hidden={txtCampo === ''}
                      style={estado.tabla.length
                        ? {
                            minWidth: fmtosTablaFiltroCampo(
                              infoTablaLista.txtTabla,
                              infoTablaLista.tabCampos[index],
                              estado.tabla[0][infoTablaLista.tabCampos[index]],
                              userEmpConfig.decimales).ancho,
                            position: (index < 2 ? 'sticky' : ''),
                            left: (index === 0
                              ? opcMenuCall
                                ? fmtoMaxRows.ancho + (disableBotMod ? 0 : anchoColBot)
                                : fmtoMaxRows.ancho + (disableBotMod ? anchoColBot : anchoColBot * 2)
                              : index === 1
                                ? (opcMenuCall
                                    ? fmtoMaxRows.ancho + (disableBotMod ? 0 : anchoColBot)
                                    : fmtoMaxRows.ancho + (disableBotMod ? anchoColBot : anchoColBot * 2)) +
                                  fmtosTablaFiltroCampo(
                                    infoTablaLista.txtTabla,
                                    infoTablaLista.tabCampos[0],
                                    estado.tabla[0][infoTablaLista.tabCampos[0]],
                                    userEmpConfig.decimales).ancho
                                : null)
                          }
                        : null}
                      onClick={ctrlClickHeadLista}
                    >{txtCampo}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody
                className='table-light'
                onClick={opcMenuCall ? ctrlClickLista : ctrlDblClickLista}
                onDoubleClick={opcMenuCall ? undefined : ctrlDblClickLista}
              >
                {estado.tabla.map((registro, indexReg) => (
                  <tr key={registro.NO_CLIENTE}>
                    <td
                      key='INDEX' name='INDEX'
                      className='text-center'
                      style={{ position: 'sticky', left: 0 }}
                      id={registro.NO_CLIENTE} index={indexReg}
                    >{indexReg + 1}
                    </td>
                    <td
                      key='BOTON-MODIFICA' name='BOTON-MODIFICA'
                      style={{ position: 'sticky', left: fmtoMaxRows.ancho }}
                      hidden={disableBotMod} index={indexReg}
                    >
                      <OverlayTrigger overlay={<Tooltip>modificar</Tooltip>}>
                        <Button
                          className={gcFmtosBotones.enTabla}
                          size='sm'
                          id={indexReg} index={indexReg}
                          style={styleBotsTbl}
                          onClick={ctrClickModificar}
                        ><img
                          className={gcFmtosBotones.imgTabla}
                          id={indexReg} index={indexReg}
                          src={imgModificar}
                          alt='modificar' width={widthImg}
                         />
                        </Button>
                      </OverlayTrigger>
                    </td>
                    {opcMenuCall
                      ? null
                      : <td
                          key='BOTON-ELIMINA' name='BOTON-ELIMINA'
                          hidden={disableBotEli} index={indexReg}
                          style={{ position: 'sticky', left: fmtoMaxRows.ancho + (disableBotMod ? 0 : anchoColBot) }}
                        >
                          <OverlayTrigger overlay={<Tooltip>eliminar</Tooltip>}>
                            <Button
                              className={gcFmtosBotones.enTabla}
                              size='sm'
                              id={indexReg} index={indexReg}
                              style={styleBotsTbl}
                              onClick={ctrClickEliminar}
                            ><img
                              className={gcFmtosBotones.imgTabla}
                              id={indexReg} index={indexReg}
                              src={imgMenos}
                              alt='eliminar' width={widthImg}
                             />
                            </Button>
                          </OverlayTrigger>
                        </td>}
                    {infoTablaLista.tabCampos.map((campo, indexCampo) => (
                      <td
                        key={campo} name={campo}
                        className={fmtosTablaFiltroCampo(infoTablaLista.txtTabla, campo, registro[campo], userEmpConfig.decimales).fmto}
                        style={indexCampo === 0
                          ? {
                              position: 'sticky',
                              left: (opcMenuCall
                                ? fmtoMaxRows.ancho + (disableBotMod ? 0 : anchoColBot)
                                : fmtoMaxRows.ancho + (disableBotMod ? anchoColBot : anchoColBot * 2))
                            }
                          : indexCampo === 1
                            ? {
                                position: 'sticky',
                                left: (opcMenuCall
                                  ? fmtoMaxRows.ancho + (disableBotMod ? 0 : anchoColBot)
                                  : fmtoMaxRows.ancho + (disableBotMod ? anchoColBot : anchoColBot * 2)) +
                                  fmtosTablaFiltroCampo(
                                    infoTablaLista.txtTabla,
                                    infoTablaLista.tabCampos[0],
                                    estado.tabla[0][infoTablaLista.tabCampos[0]],
                                    userEmpConfig.decimales).ancho
                              }
                            : null}
                        id={registro.NO_CLIENTE}
                        index={indexReg}
                        hidden={infoTablaLista.txtCampos[indexCampo] === ''}
                      >{fmtosTablaFiltroCampo(infoTablaLista.txtTabla, campo, registro[campo], userEmpConfig.decimales).texto}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>}
    </>
  )
}

export default Clientes
