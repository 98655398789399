import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Form, Spinner } from 'react-bootstrap'

// Importa el cliente Axios
import restAPIAxios from '../../utils/axios'
import encryptStorage from '../../utils/encryptStorage'

import useAdmin from '../../context/Admin'
import useTransacc from '../../context/Transacc'

import { getParamsUserEmp } from '../../utils/bd'
import { gcTiposDoc } from '../../utils/generales'

function EmpSel ({ devuelveCambiaEmp, esHeader }) {
  const [stdAdmin, dispatchAdmin, accionAdminTipo] = useAdmin()
  const [stdTransacc, dispatchTransacc, tiposAccionTransacc] = useTransacc()
  const navigate = useNavigate()

  // Trabajar con el STATE de REACT
  const [empresas, setEmpresas] = useState([])

  // Extrae los datos del usuario VSAM para capturar los niveles de acceso
  const userVSAM = encryptStorage.getItem('userVSAM')
  // Extrae los datos de la empresa (mysql). Parámetros de la empresa y usuario
  const userEmpConfig = encryptStorage.getItem('config')

  const accCambiarEmp = useRef(false)

  let poolName = ''
  if (stdAdmin) {
    poolName = stdAdmin.orclHost
  }

  const noUser = userEmpConfig.id

  useEffect(() => {
    console.log('🚀 ~ file: EmpSel.jsx ~ useEffect', 'Cargando Empresas ...', stdAdmin)
    // Query a la API usando Axios
    async function obtieneEmps () {
      const empList = await restAPIAxios('NO FUE POSIBLE CARGAR LAS EMPRESAS',
        {
          method: 'get',
          url: `/empresas/${poolName}/${stdAdmin.orclUsuario}`
        }
      )
      if (empList.status === 200) {
        setEmpresas(empList.data)
        // Asigna la empresa default del usuario VSAM
        const indexEmpUserVSAM = empList.data.findIndex(
          empresa => empresa.NO_INSTIT === stdAdmin.noEmpresa
        )
        // Si no tiene acceso a cambiar empresas y la empresa asignada al usuario VSAM no existe
        // -- INCONSISTENCIA
        if (!accCambiarEmp.current && indexEmpUserVSAM === -1) {
          dispatchAdmin({ tipo: accionAdminTipo.logout })
          Swal.fire({
            title: 'ERROR DE INCONSISTENCIA',
            text: 'Consulte a su técnico',
            footer: 'El usuario (VSAM) no tiene asignada correctamente una empresa disponible',
            icon: 'error'
          })
          navigate('/login')
        } else {
          dispatchAdmin({ tipo: accionAdminTipo.asignaEmp, noEmpresa: stdAdmin.noEmpresa, regEmpresa: empList.data[indexEmpUserVSAM] })
        }
      } else {
        dispatchAdmin({ tipo: accionAdminTipo.logout })
        navigate('/login')
      }
    };
    // Para que no llame a la función obtieneEmps() cuando todavía no hay un login válido
    // Y por si acaso todavía no se hayan asignado los datos en userVSAM
    if (stdAdmin.auth && userVSAM) {
      // Asigna si tiene acceso a cambiar empresas
      accCambiarEmp.current = (userVSAM.RESTRICC_ACC_PARAM.indexOf(process.env.REACT_APP_ACC_CAMBIAR_EMP) === -1)
      obtieneEmps()
    }
  }, [poolName, stdAdmin.orclUsuario, stdAdmin.token])
  // Solo van las dependencias que se usan para llamar a la RestAPI que obtiene las empresas

  async function temporalesHoy (doc, nuevaEmp, noMax) {
    let url = ''
    let noDocMax = ['', 0]
    switch (doc) {
      case gcTiposDoc.prof:
        url = '/proformas/nuevas/setDatosHoy'
        noDocMax = ['noProfMax', noMax]
        break
      default:
        break
    }
    const respuestaAPI = await restAPIAxios('ERROR AL ACTUALIZAR REGISTROS TEMPORALES',
      {
        method: 'patch',
        url,
        data: {
          poolName: stdAdmin.orclHost,
          orclUserVSAM: stdAdmin.orclUsuario,
          noUser: stdAdmin.noUsuario,
          noEmp: nuevaEmp,
          [noDocMax[0]]: noDocMax[1]
        }
      }
      , false)
    return (respuestaAPI.status === 200)
  }

  async function ctrlCambiaEmp (e) {
    // Es indispensable el parseInt para que no falle la comparación empresa.NO_INSTIT === stdAdmin.noEmpresa
    // que se hace para buscar el index con .findIndex
    const nuevoNoEmp = parseInt(e.target.value)
    const index = e.target.options.selectedIndex
    // Query a la API usando Axios
    const paramsUserEmp = await getParamsUserEmp(noUser, nuevoNoEmp)
    if (paramsUserEmp.status === 200) {
      if (paramsUserEmp.datos.length) {
        // Solo se actualizan tablas temporales al cambiar la empresa en documentos
        // que utilizan el parámetro userEmpConfig.numeros_maximos en dicha actualización
        let ok = true
        if (stdTransacc.doc === gcTiposDoc.prof) {
          ok = await temporalesHoy(stdTransacc.doc, nuevoNoEmp, paramsUserEmp.datos[0].numeros_maximos.proforma)
        // Caso contrario se deja en falso cargoDatosHoy de todas las transacciones que deben
        // actualizar las tablas temporales al cambiar la empresa (cuando se presione la opción de menú respectiva)
        } else {
          dispatchAdmin({
            tipo: accionAdminTipo.setCargoDatosHoy,
            cargoDatosHoy: { ...stdAdmin.cargoDatosHoy, [gcTiposDoc.prof]: false }
          })
        }
        if (ok) {
          dispatchAdmin({ tipo: accionAdminTipo.asignaEmp, noEmpresa: nuevoNoEmp, regEmpresa: empresas[index] })
          dispatchTransacc({ tipo: tiposAccionTransacc.cambiaEmp })
          encryptStorage.setItem('config', paramsUserEmp.datos[0])
          devuelveCambiaEmp()
        } else {
          dispatchAdmin({ tipo: accionAdminTipo.logout })
          navigate('/login')
        }
      } else {
        // Si no esta creado el registro de la empresa respectiva para el usuario activo
        // -- INCONSISTENCIA
        Swal.fire({
          title: 'ERROR DE INCONSISTENCIA',
          text: 'Consulte a su técnico',
          footer: 'No existen parámetros creados para la empresa seleccionada',
          icon: 'info'
        })
      }
    } else {
      dispatchAdmin({ tipo: accionAdminTipo.logout })
      navigate('/login')
    }
  }

  if (!stdAdmin.auth) return null

  const fmtoSelEmp = 'border border-primary bg-primary-subtle rounded text-uppercase text-center fw-bold mt-1' +
    (esHeader ? '' : ' mb-1')
  const fmtoSelEmpDisabled = 'border border-primary bg-primary-subtle rounded text-uppercase text-center fw-light' +
    (esHeader ? ' mt-1' : ' mb-1')
  if (empresas.length === 0) {
    return (
      <>
        <Spinner animation='border' size='sm' />
        <Form.Select
          size='sm'
          className='bg-secondary-subtle rounded'
          disabled
        >
          <option>Cargando empresas...</option>
        </Form.Select>
      </>
    )
  } else {
    // Asigna la empresa default del usuario web
    const indexEmpUserVSAM = empresas.findIndex(
      empresa => empresa.NO_INSTIT === stdAdmin.noEmpresa)
    // Si no tiene acceso a cambiar empresas y si existe la empresa del usuario VSAM en la lista
    if (!accCambiarEmp.current && indexEmpUserVSAM >= 0) {
      return (
        <Form.Select
          name='selEmpresa'
          size='sm'
          className={fmtoSelEmpDisabled}
          disabled
        >
          <option
            id={empresas[indexEmpUserVSAM].NO_INSTIT}
            key={empresas[indexEmpUserVSAM].NO_INSTIT}
          >
            {empresas[indexEmpUserVSAM].DESCRIP + ' (' + empresas[indexEmpUserVSAM].NO_INSTIT + ')'}
          </option>
        </Form.Select>
      )
    }

    // Si tiene acceso a cambiar empresas (carga todas las empresas)
    return (
      <Form.Select
        className={fmtoSelEmp + ' fw-bold'}
        name='selEmpresa'
        size='sm'
        value={`${stdAdmin.noEmpresa}`}
        onChange={ctrlCambiaEmp}
      >
        {empresas.map(
          (empresa, index) => (
            <option
              value={`${empresa.NO_INSTIT}`}
              key={empresa.NO_INSTIT}
              index={index}
            >
              {empresa.DESCRIP + ' (' + empresa.NO_INSTIT + ')'}
            </option>
          )
        )}
      </Form.Select>
    )
  }
}

export default EmpSel
