import { Button, Row } from 'react-bootstrap'

import { fechaFormat, numberFormat, gcFmtoMaxRows, gcFmtosListas, gcFmtosColorFondo } from '../../../utils/generales'

function ListExistentes ({ registros, devuelveClickNuevoFiltro, devuelveClickListado, orden }) {
  function ctrlClickTabla (e) {
    // e.preventDefault()
    let index = e.target.getAttribute('index')
    index = parseInt(index || 0, 10)
    devuelveClickListado(index)
  }
  function ctrlClickNuevoFiltro (e) {
    // e.preventDefault()
    devuelveClickNuevoFiltro()
  }

  const fmtoOrden = 'bg-primary text-white'
  const fmtoMaxRows = gcFmtoMaxRows(registros.length)
  const fmtoListas = gcFmtosListas()
  const fmtoFijoHead = 'border border-primary text-center '

  return (
    <div className={fmtoListas.divLista}>
      <Row className={fmtoListas.rowBoton.row}>
        <Button
          className={fmtoListas.rowBoton.boton} size='sm' style={{ width: 130 }}
          onClick={ctrlClickNuevoFiltro}
        >Nuevo Filtro
        </Button>
      </Row>
      <div className={fmtoListas.tabla.div} style={{ maxHeight: 600, zIndex: '98' }}>
        <table
          className={fmtoListas.tabla.tabla}
          style={{ fontSize: '10px' }}
        >
          <caption
            className={fmtoListas.tabla.caption}
          >LISTADO DE PROFORMAS SELECCIONADAS
          </caption>
          <thead className={fmtoListas.tabla.head} style={{ zIndex: '99' }}>
            {/* <tr>
              <th
                className={fmtoListas.tabla.caption} colSpan={9}
                style={{ position: 'sticky', left: 0 }}
              >LISTADO DE PROFORMAS SELECCIONADAS
              </th>
            </tr> */}
            <tr>
              <th
                key={0}
                className={fmtoMaxRows.fmto}
                style={{ minWidth: fmtoMaxRows.ancho, position: 'sticky', left: 0 }}
              >{registros.length}
              </th>
              <th
                key={1} className={fmtoFijoHead + (orden === 'NO_PROFORMA' ? fmtoOrden : '')}
                style={{ minWidth: 80, position: 'sticky', left: fmtoMaxRows.ancho }}
              >No. Proforma
              </th>
              <th
                key={2} className={fmtoFijoHead + (orden === 'PTO_VENTA' ? fmtoOrden : '')}
                style={{ minWidth: 150 }}
              >Punto de Venta
              </th>
              <th key={3} className={fmtoFijoHead + (orden === 'CLI_NOMBRE' ? fmtoOrden : '')} style={{ minWidth: 300 }}>Cliente</th>
              <th key={4} className={fmtoFijoHead} style={{ minWidth: 50 }}>Anulada</th>
              <th
                key={5} className={fmtoFijoHead + (orden === 'FECHA' ? fmtoOrden : '')}
                style={{ minWidth: 140 }}
              >Fecha
              </th>
              <th
                key={6} className={fmtoFijoHead + (orden === 'SUBTOTAL' ? fmtoOrden : '')}
                style={{ minWidth: 100 }}
              >Subtotal
              </th>
              <th
                key={7} className={fmtoFijoHead + (orden === 'VENDEDOR' ? fmtoOrden : '')}
                style={{ minWidth: 200 }}
              >Vendedor
              </th>
              <th
                key={8} className={fmtoFijoHead + (orden === 'OBS' ? fmtoOrden : '')}
                style={{ minWidth: 400 }}
              >Observaciones
              </th>
            </tr>
          </thead>
          <tbody
            className=''
            onClick={ctrlClickTabla}
          >
            {registros.map((registro, indexReg) => (
              <tr key={registro.NO_PROFORMA}>
                <th
                  key='INDEX' id={indexReg} index={indexReg}
                  className='text-center table-primary border-bottom border-secondary-subtle'
                  style={{ position: 'sticky', left: 0 }}
                >{indexReg + 1}
                </th>
                <td
                  key='NO_PROFORMA' id={registro.NO_PROFORMA} index={indexReg}
                  className='text-center table-primary border-bottom border-secondary-subtle'
                  style={{ position: 'sticky', left: 40 }}
                >{registro.NO_PROFORMA}
                </td>
                <td
                  key='PTO_VENTA' className='' id={registro.PTO_VENTA} index={indexReg}
                >{registro.PTO_VENTA}
                </td>
                <td
                  key='CLI_NOMBRE' className={registro.NO_CLIENTE === null ? gcFmtosColorFondo.noExiste : ''}
                  id={registro.CLI_NOMBRE} index={indexReg}
                >{registro.CLI_NOMBRE}
                </td>
                <td
                  key='ESTADO' className='text-center' id={registro.ESTADO} index={indexReg}
                >{registro.ESTADO === 'A' ? '✓' : ''}
                </td>
                <td
                  key='FECHA' className='text-center' id={registro.FECHA} index={indexReg}
                >{fechaFormat(registro.FECHA, 'MEDIA LARGA HORA')}
                </td>
                <td
                  key='TOTAL' className='text-end' id={registro.SUBTOTAL} index={indexReg}
                >{numberFormat(registro.SUBTOTAL - registro.V_DSCTO + registro.V_IMP, 2)}
                </td>
                <td
                  key='VENDEDOR' className='' id={registro.VENDEDOR} index={indexReg}
                >{registro.VENDEDOR}
                </td>
                <td
                  key='OBS' className='' id={registro.OBS} index={indexReg}
                >{registro.OBS}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ListExistentes
