/* eslint-disable react/jsx-indent */
import { Row, Col } from 'react-bootstrap'

import { gcFmtosColorFondo, sriDatosDoc } from '../../../utils/generales'

function BarraSRI ({ sriAutoriz, sriClave, sriMensaje }) {
  const esProducc = sriDatosDoc(sriDatosDoc(sriAutoriz).esValida ? sriAutoriz : sriClave).esProducc
  const estaAutorizada = sriDatosDoc(sriAutoriz).esValida
  const txtAmbienteYAutoriz = sriAutoriz === null || sriAutoriz === '' ? '' : (esProducc ? 'PRODUCCION: ' : 'PRUEBAS: ')
  const fmtoTxtAutoriz = sriMensaje
    ? 'ms-1 me-1 rounded border border-primary ' + gcFmtosColorFondo.msjeInfo
    : 'ms-1 me-1 mb-1 rounded border border-primary ' + gcFmtosColorFondo.msjeInfo

  return (
    sriAutoriz
      ? <>
        <Row
          className={fmtoTxtAutoriz}
          style={{ fontSize: '12px' }}
        >
          <Col className='text-center'>
            <span className='fw-bold'>{txtAmbienteYAutoriz}</span>{sriAutoriz}
          </Col>
          <Col className='text-center'>
            <span className='fw-bold'>{(estaAutorizada ? 'FECHA Y HORA AUTORIZACIÓN: ' : 'CLAVE: ')}</span>
            {sriClave}
          </Col>
        </Row>
        {sriMensaje
          ? <Row
              className={'ms-1 me-1 mb-1 rounded border-start border-end border-bottom border-primary ' + gcFmtosColorFondo.msjeInfo}
              style={{ fontSize: '10px' }}
            ><Col className='text-center'>
               <span className='fw-bold'>ERROR SRI: </span>{sriMensaje}
             </Col>
            </Row>
          : null}
        </>
      : null
  )
}

export default BarraSRI
