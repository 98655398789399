import { memo } from 'react'
import { Card, Col } from 'react-bootstrap'

import useAdmin from '../../context/Admin'

function Footer () {
  const [stdAdmin] = useAdmin()

  if (!stdAdmin.auth) return null

  return (
    <Card.Footer className='d-flex ps-2 pb-1 pt-1 pe-2'>
      <Col>{stdAdmin.userNombre}</Col>
      <Col className='text-end'>{stdAdmin.orclHost}</Col>
    </Card.Footer>
  )
}

export default memo(Footer)
