/* eslint-disable react/jsx-indent */
import { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2'

import { Form, InputGroup, Spinner } from 'react-bootstrap'

import restAPIAxios from '../../../utils/axios'
import useAdmin from '../../../context/Admin'
import useTransacc from '../../../context/Transacc'

import { fechaFormat } from '../../../utils/generales'

function TablaCampoFecha (props) {
  const {
    fmto, id, label, required, disabled, hidden, min, max, showHora, condFiltro, dias
  } = props.infoCampo

  const [stdAdmin] = useAdmin()
  const [stdTransacc, , , tiposTarea] = useTransacc()

  const valTablaIni = disabled
    ? props.valTabla ? fechaFormat(props.valTabla, 'MEDIA LARGA') : ''
    : props.valTabla ? props.valTabla.substr(0, 10) : ''

  const [campo, setCampo] = useState(valTablaIni)
  const [cargando, setCargando] = useState(false)

  const campoFocus = useRef('')

  const infoTActualiza = props.infoTablaActualiza

  const poolName = stdAdmin.orclHost
  const filtrando = (stdTransacc.tarea === tiposTarea.filtrando)

  // ** Considerar que no hay que poner valTablaIni en dependencias
  //  porque ya se asigna al principio usando los props que si van en dependencias
  useEffect(() => {
    setCampo(valTablaIni)
  }, [props.valTabla, disabled])

  // FUNCIONES PRIVADAS DEL COMPONENTE
  function getFechaCond (opc) {
    switch (opc) {
      case '1': return '>'
      case '2': return '>='
      case '3': return '<'
      case '4': return '<='
      default: return '='
    }
  }
  function getFechaOpc (cond) {
    switch (cond) {
      case '>': return 1
      case '>=': return 2
      case '<': return 3
      case '<=': return 4
      default: return 0
    }
  }

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  async function ctrlChangeCampo (e) {
    // e.preventDefault()
    setCampo(e.target.value)
    props.devuelveEditando(true)
  }

  function ctrlDblClick (e) {
    // e.preventDefault()
    if (props.devuelveDblClick === undefined) {
      Swal.fire(
        'NO ESTA ACTIVA LA OPCION DE ORDENADO PARA ESTE CAMPO',
        '',
        'info'
      )
    } else props.devuelveDblClick()
  }

  async function ctrlBlurCampo (e) {
    // e.preventDefault()
    props.devuelveEditando(false)
    let valCampoOK = e.target.value
    if (valCampoOK === valTablaIni) return null

    // No se actualiza el campo
    if (infoTActualiza === undefined) {
      if (props.devuelveVal !== undefined) props.devuelveVal(valCampoOK)
    // Actualiza el campo en la tabla
    } else {
      // Validaciones
      if (e.target.max !== '' && valCampoOK && valCampoOK > e.target.max) {
        Swal.fire(
          `EL VALOR NO PUEDE SER MAYOR ${e.target.max}`,
          'Se asignó el valor mayor permitido',
          'info'
        )
        valCampoOK = e.target.max
      } else {
        if (e.target.min !== '' && valCampoOK && valCampoOK < e.target.min) {
          Swal.fire(
            `EL VALOR NO PUEDE SER MENOR A ${e.target.min}`,
            'Se asignó el valor menor permitido',
            'info'
          )
          valCampoOK = e.target.min
        }
      }
      setCargando(true)
      const defProps = {
        poolName,
        tabla: infoTActualiza.tabla,
        campo: infoTActualiza.campo,
        valor: `to_date('${valCampoOK}', 'yyyy-mm-dd')`,
        cond: infoTActualiza.campoClave + '=' + stdTransacc.clave,
        ceroEsNulo: false,
        dias
      }
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR LA FECHA EN LA BD',
        {
          method: 'patch',
          url: infoTActualiza.url,
          data: defProps
        }
      )
      if (respuestaAPI.status === 200) {
        setCargando(false)
        if (respuestaAPI.data[0] === 0) {
          setCampo(valTablaIni)
          if (props.devuelveVal !== undefined) props.devuelveVal(valTablaIni)
          Swal.fire(
            'EL VALOR NO SE ACTUALIZO EN LA BD',
            'consulte a su técnico',
            'info'
          )
        } else {
          setCampo(valCampoOK)
          if (props.devuelveVal !== undefined) props.devuelveVal(valCampoOK)
        }
      } else {
        setCargando(false)
        setCampo(valTablaIni)
        if (props.devuelveVal !== undefined) props.devuelveVal(valTablaIni)
      }
    }
  }

  function ctrlChangeCond (e) {
    console.log(getFechaCond(e.target.value))
    props.devuelveCond(getFechaCond(e.target.value))
  }

  if (!stdAdmin.auth) return null

  const fmtoCampo = fmto === undefined ? '' : fmto[0]
  const fmtoLabel = fmto === undefined ? '' : fmto[1]
  const fmtoTexto = fmto === undefined ? '' : fmto[2]
  return (
    <InputGroup
      className={fmtoCampo}
      hidden={hidden}
      size='sm'
    >
      <InputGroup.Text className={fmtoLabel} onDoubleClick={ctrlDblClick}>{label}</InputGroup.Text>
      <Spinner animation='grow' size='sm' hidden={!cargando} />
      <Form.Control
        className={fmtoTexto}
        style={{ minWidth: (disabled ? 120 : 130), maxWidth: (disabled ? 140 : 130) }}
        id={id}
        type={disabled ? 'text' : 'date'}
        aria-describedby={id}
        min={min === undefined ? null : min}
        max={max === undefined ? null : max}
        value={campo}
        required={required}
        disabled={disabled}
        onChange={ctrlChangeCampo}
        onBlur={ctrlBlurCampo}
        onFocus={(e) => { campoFocus.current = campo }}
      />
      {showHora
        ? <>
          <InputGroup.Text id='horaProf' hidden={!showHora}>hora:</InputGroup.Text>
          <Form.Control
            className=''
            style={{ minWidth: 50, maxWidth: 55 }}
            type='text'
            aria-describedby='horaProf'
            value={fechaFormat(props.valTabla, 'SOLO HORA')}
            disabled
            hidden={!showHora}
          />
          </>
        : null}
      {/* Condición */}
      {filtrando && condFiltro
        ? <Form.Select
            className='ms-2' style={{ maxWidth: 80 }}
            value={getFechaOpc(condFiltro)}
            onChange={ctrlChangeCond}
          >
            <option title='igual' value={0}>=</option>
            <option title='mayor' value={1}>{'>'}</option>
            <option title='mayor o igual' value={2}>{'>='}</option>
            <option title='menor' value={3}>{'<'}</option>
            <option title='menor o igual' value={4}>{'<='}</option>
          </Form.Select>
        : null}
    </InputGroup>
  )
}

export default TablaCampoFecha
