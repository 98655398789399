/* eslint-disable react/jsx-indent */
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { Button, InputGroup, Form, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'

import restAPIAxios from '../../../utils/axios'
import encryptStorage from '../../../utils/encryptStorage'
import useAdmin from '../../../context/Admin'
import useTransacc from '../../../context/Transacc'

import TablaFiltro from '../../generales/tablas/TablaFiltro'
import CampoNumerico from '../../generales/CampoNumerico'

import {
  infoItemFiltro, gcFmtosBotones, gcFmtosColorFondo, numberFormat
} from '../../../utils/generales'

import imgMas from '../../../iconos/MAS.ICO'

function ItemFiltro ({
  idName, esNC, datosAPI, sigReg, devuelveRecargaRegs
}) {
  const [stdAdmin] = useAdmin()
  const [stdTransacc, dispatchTransacc, tiposAccionTransacc, tiposTarea] = useTransacc()

  const [infoItemFiltroOrden, setInfoItemFiltroOrden] = useState({})

  const [itemCant, setItemCant] = useState(0)

  const [cargando, setCargando] = useState(false)

  // Extrae los datos de la empresa (mysql). Parámetros de la empresa y usuario
  const userEmpConfig = encryptStorage.getItem('config')
  const decimales = userEmpConfig.decimales

  const poolName = stdAdmin.orclHost
  const filtrando = (stdTransacc.tarea === tiposTarea.filtrando)

  useEffect(() => {
    setItemCant(esNC ? -1 : 1)
  }, [esNC])

  useEffect(() => {
    const campoOrden = () => {
      switch (stdTransacc.itemFiltro.orden) {
        case '0': return 'NO_ITEM_USER'
        case '1': return 'CODIGO'
        case '2': return 'DESCRIP'
        case '3': return 'DESCRIP_AUX'
        case '4': return 'OTROS_COD'
        default: return 'DESCRIP'
      }
    }
    const nuevaInfoTabla = infoItemFiltro(
      stdTransacc.doc, campoOrden(), false,
      ' and E.NO_BODEGA = ' + stdTransacc.otrosDat.noBodega, '', '', 'Filtro:'
    )
    setInfoItemFiltroOrden(nuevaInfoTabla)
  }, [stdTransacc.doc, stdTransacc.itemFiltro.orden, stdTransacc.otrosDat.noBodega])

  // FUNCIONES PRIVADAS DEL COMPONENTE
  function creaDetalle (e) {
    e.preventDefault()
    // Verifica que se haya escogido un item para crear el detalle respectivo
    if (Object.keys(regItemFiltro).length === 0) {
      Swal.fire(
        'NO SE PUEDE CREAR EL REGISTRO',
        'No ha seleccionado ningún item',
        'info'
      )
    } else {
      async function creaReg () {
        setCargando(true)
        const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CREAR EL REGISTRO',
          {
            method: 'post',
            url: datosAPI.urlBase + '/nueva/detalles',
            data: {
              poolName,
              noReg: stdTransacc.clave,
              noRegDoc: sigReg,
              noItem: regItemFiltro.KEY_TAB,
              noUndMed: regItemFiltro.NO_UND_MED,
              itemTIVA: regItemFiltro.T_IVA,
              itemCant,
              itemVUnit: regItemFiltro.V_PVENTA,
              itemPV: regItemFiltro.V_PVENTA,
              itemDetalle: ''
            }
          }
        )
        setCargando(false)
        if (respuestaAPI.status === 200) {
          dispatchTransacc({
            tipo: tiposAccionTransacc.setItemFiltro,
            idFoco: idName + 'itemFiltroCant',
            itemFiltro: { filtro: '', registro: {} }
          })
          // Para que actualice los TOTALES fuera del componente
          if (devuelveRecargaRegs) devuelveRecargaRegs({ source: 'nuevo' })
        }
      }
      creaReg()
    }
  }
  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE

  // FUNCIONES DE CONTROL (EVENTOS) DEVUELTOS POR LOS COMPONENTE
  function setValoresItem (txtCampoEnTabla, filtro, registro) {
    dispatchTransacc({
      tipo: tiposAccionTransacc.setItemFiltro,
      // idFoco: idName + 'btnItemFiltroCrea',
      itemFiltro: { filtro, registro }
    })
  }

  if (!Object.keys(infoItemFiltroOrden).length) return null

  const fmtoRow = 'd-flex justify-content-center rounded m-1 ps-1 pe-1' + gcFmtosColorFondo.msjeInfo
  const regItemFiltro = stdTransacc.itemFiltro.registro

  return (
    <>
    <Form
      className={'ps-2 pe-2' + (filtrando ? ' border-top border-primary pt-2 mb-2' : ' mt-2')}
      size='sm'
      onSubmit={creaDetalle}
    >
      <InputGroup className='justify-content-center' size='sm'>
        {/* Label Filtro */}
        <InputGroup.Text
          className='text-primary fw-bold bg-primary-subtle rounded me-1'
          style={{ maxWidth: 65 }}
        >ITEM
        </InputGroup.Text>
        {/* Label Cantidad */}
        <InputGroup.Text
          className='ms-2'
          id={idName + 'lblItemFiltroCant'}
          hidden={filtrando}
        >Cantidad:
        </InputGroup.Text>
        {/* Cantidad */}
        <CampoNumerico
          idName={idName + 'itemFiltroCant'}
          style={{ maxWidth: 110 }}
          fmtoInput='' ocultar={filtrando}
          devuelveVal={(campo, valor) => setItemCant(valor)}
          decimales={decimales} obligatorio
          deshabilitado={false} soloPositivo={!esNC}
        >{itemCant}
        </CampoNumerico>
        {/* Label Orden */}
        <InputGroup.Text
          id={idName + 'lblItemFiltroOrden'}
          className='ms-2'
          style={{ width: 110 }}
        >Ordenado por:
        </InputGroup.Text>
        {/* Orden */}
        <Form.Select
          className=''
          size='sm'
          style={{ maxWidth: 150 }}
          id={idName + 'optItemFiltroOrden'}
          aria-describedby='lblItemFiltroOrden'
          value={stdTransacc.itemFiltro.orden}
          onChange={(e) => {
            dispatchTransacc({
              tipo: tiposAccionTransacc.setItemFiltro,
              itemFiltro: { filtro: '', registro: {}, orden: e.target.value }
            })
          }}
        >
          <option value={0} key={0}>Número</option>
          <option value={1} key={1}>Código</option>
          <option value={2} key={2}>Descripción</option>
          <option value={3} key={3}>Auxiliar</option>
          <option value={4} key={4}>Otros códigos</option>
        </Form.Select>
        {/* Filtro */}
        <TablaFiltro
          infoTablaLista={infoItemFiltroOrden}
          idRowFiltro={idName + 'itemFiltro-SelRapida'}
          devuelveFiltro={(valor) => {
            dispatchTransacc({
              tipo: tiposAccionTransacc.setItemFiltro,
              itemFiltro: { filtro: valor, registro: stdTransacc.itemFiltro.registro }
            })
          }}
          devuelveRegistro={setValoresItem}
          devuelveEditando={(valor) => {
            // Solo para encerar el registro cuando cambia el filtro (NO ESTA FILTRANDO)
            // y así evitar que se cree el último item seleccionado al presionar ENTER
            if (valor) {
              dispatchTransacc({
                tipo: tiposAccionTransacc.setItemFiltro,
                itemFiltro: { ...stdTransacc.itemFiltro, registro: {} }
              })
            }
          }}
          style={stdTransacc.itemFiltro.orden === '0' || stdTransacc.itemFiltro.orden === '1'
            ? { minWidth: 150 }
            : { minWidth: 250, fontSize: '10px' }}
        >{stdTransacc.itemFiltro.filtro}
        </TablaFiltro>
        {/* Botón Nuevo Registro */}
        <OverlayTrigger overlay={<Tooltip>'nuevo registro'</Tooltip>}>
          <Button
            id={idName + 'btnItemFiltroCrea'}
            className='rounded ms-1 bg-primary-subtle' size='sm'
            type='submit'
            hidden={filtrando || !Object.keys(regItemFiltro).length}
          ><img src={imgMas} alt='crear' width={gcFmtosBotones.imgWidthSM} />
          </Button>
        </OverlayTrigger>
        <Spinner animation='grow' size='sm' hidden={!cargando} />
      </InputGroup>
    </Form>
    {/* Información del registro seleccionado */}
    {Object.keys(regItemFiltro).length
      ? <div
          className={fmtoRow}
          style={{ fontSize: '10px' }}
        ><span><b>Item seleccinado:&nbsp;</b></span>
          {
            '(' + regItemFiltro.NO_ITEM_USER + ') ' + regItemFiltro.CODIGO + ' - ' + regItemFiltro.DESCRIP +
            (regItemFiltro.DESCRIP_AUX ? ' - ' + regItemFiltro.DESCRIP_AUX : '') +
            (regItemFiltro.GRUPO_COD ? ' - (' + regItemFiltro.GRUPO_COD + ')' : '') +
            (regItemFiltro.GRUPO_DESCRIP ? ' - ' + regItemFiltro.GRUPO_DESCRIP : '')
          }
          .&nbsp;&nbsp;<b>PV:&nbsp;</b>{numberFormat(regItemFiltro.V_PVENTA, 2)}
          .&nbsp;&nbsp;<b>Otros códigos:&nbsp;</b>{regItemFiltro.OTROS_COD}
        </div>
      : null}
    </>
  )
}
export default ItemFiltro
