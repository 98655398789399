import { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2'

import { Form, InputGroup, Spinner } from 'react-bootstrap'

import restAPIAxios from '../../utils/axios'
import encryptStorage from '../../utils/encryptStorage'
import useAdmin from '../../context/Admin'
import useTransacc from '../../context/Transacc'

import { existeNoFact, getSigNoFactXSerie } from '../../utils/bd'
import { gcTiposDoc, cargaSeries, gcFmtosColorFondo } from '../../utils/generales'

function NoFactura (props) {
  // console.log(props.infoCampo, props.valTabla, props.infoCampo.fmto)
  const { fmto, id, label, required, disabled, hidden, min, max } = props.infoCampo
  const valTablaIni = props.valTabla === null ? '' : props.valTabla

  const [stdAdmin] = useAdmin()
  const [stdTransacc, , , tiposTarea] = useTransacc()

  const paramsEmp = stdAdmin.paramsEmp

  const [campoSerie, setCampoSerie] = useState('')
  const [campoNo, setCampoNo] = useState('')
  const [series, setSeries] = useState([[], []])
  const [fmtoWarning, setFmtoWarning] = useState(false)
  const [cargando, setCargando] = useState(false)

  const esNC = useRef(false)
  const soloNoFactFoco = useRef('')

  const infoTActualiza = props.infoTablaActualiza

  // Extrae los datos de la empresa (mysql). Parámetros de la empresa y usuario
  const userEmpConfig = encryptStorage.getItem('config')
  const poolName = stdAdmin.orclHost

  const existentes = (window.location.pathname === '/facturas/existentes')
  const filtrando = (stdTransacc.tarea === tiposTarea.filtrando)
  const creando = (stdTransacc.tarea === tiposTarea.creando)
  const consultando = (stdTransacc.tarea === tiposTarea.consultando)

  // Asigna series y No. Factura
  useEffect(() => {
    console.log('🚀 ~ file: NoFactura.jsx ~ useEffect ~ valTablaIni:', valTablaIni)
    asignaValsCampo(valTablaIni)
    // Asigna Series
    let seriesTmp
    // Estoy mirando datos existentes.  Debe colocar la serie que viene en el campo
    if (consultando) {
      if (valTablaIni === '') seriesTmp = [[], [], []]
      else {
        const serie = (id === 'noFactNC') ? valTablaIni.substr(0, 7) : valTablaIni.substr(1, 7)
        seriesTmp = [[serie], [serie], []]
      }
    } else seriesTmp = cargaSeries(esNC.current ? gcTiposDoc.nc : gcTiposDoc.fact, paramsEmp, userEmpConfig.series)
    setSeries([seriesTmp[1], seriesTmp[2]])
    // Pinta con amarillo el campo si ya existe en la BD
    setFmtoWarning(false) // siempre normal por defecto
    if (!filtrando && (creando || id === 'noFactNC')) {
      async function existeDoc () {
        setCargando(true)
        // ** No se puede pasar con '' el parámetro noFactura en existeNoFact porque no ecuentra la url
        const resExiste = await existeNoFact(
          (id === 'noFactNC' ? ('A' + valTablaIni) : valTablaIni === '' ? 'A' : valTablaIni),
          poolName, stdAdmin.orclUsuario, stdAdmin.noEmpresa
        )
        setCargando(false)
        if (id === 'noFactNC') setFmtoWarning(!filtrando && !resExiste.existe)
        else setFmtoWarning(creando && resExiste.existe)
      }
      if (valTablaIni) existeDoc()
    }
  }, [valTablaIni, existentes, id, poolName, stdTransacc.tarea, paramsEmp,
    stdAdmin.orclUsuario, stdAdmin.noEmpresa])
  //* * No se puede incluir userEmpConfig en las dependencias del useEffect porque se queda en un bucle recursivo infinito

  // FUNCIONES PRIVADAS DEL COMPONENTE
  function asignaValsCampo (campo) {
    let tmpSerie = ''
    let tmpNo = ''
    esNC.current = false
    // Analisis para el campo NO_FACTURA_NC
    if (id === 'noFactNC') {
      if (campo) {
        if (campo.length <= 8) {
          tmpSerie = campo.substr(0, 7) === '000-000' ? '' : campo.substr(0, 7)
        } else {
          tmpSerie = campo.substr(0, 7) === '000-000' ? '' : campo.substr(0, 7)
          tmpNo = campo.substr(8)
        }
      }
    // Analisis para el campo NO_FACTURA
    } else {
      if (campo) {
        if (campo.length <= 9) {
          tmpSerie = campo.substr(1, 7) === '000-000' ? '' : campo.substr(1, 7)
        } else {
          tmpSerie = campo.substr(1, 7) === '000-000' ? '' : campo.substr(1, 7)
          tmpNo = campo.substr(9)
        }
        esNC.current = (campo.substring(0, 1) === 'B')
      }
    }
    // Quita los ceros de la izquierda
    tmpNo = tmpNo.replace(/^(0+)/g, '')
    setCampoSerie(tmpSerie)
    setCampoNo(tmpNo)
  }
  function campoDevuelve (serie, numero) {
    const valNC = esNC.current ? 'B' : 'A'
    const valSerie = serie === '' ? '000-000-' : serie + '-'
    const valNo = numero === '' ? '000000000' : '0'.repeat(9 - numero.length) + numero
    // console.log(id==='noFactNC'?valSerie+valNo:valNC+valSerie+valNo)
    if (filtrando) return id === 'noFactNC' ? valSerie + campoNo : valNC + valSerie + campoNo
    else return id === 'noFactNC' ? valSerie + valNo : valNC + valSerie + valNo
  }

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  async function ctrlChangeNo (e) {
    // e.preventDefault()
    setCampoNo(e.target.value)
    props.devuelveEditando(true)
    // No enviar el devuelve para que no se vuelva a ejecutar el useEffect mientras digita
    // y porque eso causa que se coloque el foco en el Cliente con cada dígito
    // if (props.devuelveVal !== undefined) props.devuelveVal(campoDevuelve(campoSerie, e.target.value))
  }

  function ctrlDblClick (e) {
    // e.preventDefault()
    if (props.devuelveDblClick === undefined) {
      Swal.fire(
        'NO ESTA ACTIVA LA OPCION DE ORDENADO PARA ESTE CAMPO',
        '',
        'info'
      )
    } else props.devuelveDblClick()
  }

  async function ctrlBlurCampo (e) {
    // e.preventDefault()
    setCargando(true)
    props.devuelveEditando(true)
    let soloNoFact
    let nuevoNoFact
    // SERIE
    if (e.target.id === 'series') {
      const nuevaSerie = e.target.value.substr(0, 7)
      setCampoSerie(nuevaSerie)
      soloNoFact = isNaN(campoNo) ? 0 : campoNo
      let nuevoNo = campoNo
      if (!existentes && nuevaSerie !== '') {
        nuevoNo = await getSigNoFactXSerie(
          (esNC.current ? 'B' : 'A') + nuevaSerie, poolName,
          stdAdmin.orclUsuario, stdAdmin.noEmpresa
        )
        // Quita los ceros de la izquierda
        nuevoNo = nuevoNo.substring(9).replace(/^(0+)/g, '')
        // console.log('nuevaSerie', nuevaSerie, 'nuevoNo', nuevoNo)
        setCampoNo(nuevoNo)
      }
      nuevoNoFact = campoDevuelve(nuevaSerie, nuevoNo)
      // Pinta con amarillo el campo si ya existe en la BD
      const resExiste = await existeNoFact(
        (id === 'noFactNC' ? 'A' : '') + nuevoNoFact, poolName,
        stdAdmin.orclUsuario, stdAdmin.noEmpresa
      )
      if (id === 'noFactNC') setFmtoWarning(!filtrando && !resExiste.existe)
      else setFmtoWarning(creando && resExiste.existe)
    // No. FACTURA
    } else {
      if (e.target.value === soloNoFactFoco.current) {
        setCargando(false)
        props.devuelveEditando(false)
        if (props.devuelveVal !== undefined) props.devuelveVal(campoDevuelve(campoSerie, campoNo))
        return null
      }
      setCampoNo(e.target.value)
      soloNoFact = isNaN(e.target.value) ? 0 : e.target.value
      nuevoNoFact = campoDevuelve(campoSerie, e.target.value)
      // Pinta con amarillo el campo si ya existe en la BD
      const resExiste = await existeNoFact(
        (id === 'noFactNC' ? 'A' : '') + nuevoNoFact, poolName,
        stdAdmin.orclUsuario, stdAdmin.noEmpresa
      )
      if (id === 'noFactNC') setFmtoWarning(!filtrando && !resExiste.existe)
      else setFmtoWarning(creando && resExiste.existe)
    }
    // No se actualiza el campo
    // console.log('e.target.value', e.target.value,'nuevoNoFact', nuevoNoFact, 'soloNoFact', soloNoFact, 'filtrando', filtrando, 'existentes', existentes, infoTActualiza)
    if (infoTActualiza === undefined) {
      setCargando(false)
      props.devuelveEditando(false)
      if (props.devuelveVal !== undefined) props.devuelveVal(nuevoNoFact)
    // Actualiza el campo en la tabla
    } else {
      // Validaciones
      if (e.target.max !== '' && soloNoFact > e.target.max) {
        Swal.fire(
          `EL VALOR NO PUEDE SER MAYOR ${e.target.max}`,
          'Se asignó el valor mayor permitido',
          'info'
        )
        // updateCampo = false
        setCampoNo(e.target.max)
      } else {
        if (e.target.min !== '' && soloNoFact < e.target.min) {
          Swal.fire(
            `EL VALOR NO PUEDE SER MENOR A ${e.target.min}`,
            'Se asignó el valor menor permitido',
            'info'
          )
          // updateCampo = false
          // setNoProf(1)
          setCampoNo(e.target.min)
        }
      }
      // console.log(nuevoNoFact)
      // actualiza el Campo en la Tabla
      const defProps = {
        poolName,
        tabla: infoTActualiza.tabla,
        campo: infoTActualiza.campo,
        valor: nuevoNoFact,
        cond: infoTActualiza.campoClave + '=' + stdTransacc.clave,
        ceroEsNulo: false
      }
      // console.log(defProps, type, nuevoNoFact)
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR EL NUMERO DE FACTURA EN LA BD',
        {
          method: 'patch',
          url: infoTActualiza.url,
          data: defProps
        }
      )
      if (respuestaAPI.status === 200) {
        setCargando(false)
        props.devuelveEditando(false)
        if (respuestaAPI.data[0] === 0) {
          if (props.devuelveVal !== undefined) props.devuelveVal(valTablaIni)
          Swal.fire({
            title: 'EL VALOR NO SE ACTUALIZO EN LA BD',
            text: 'Consulte a su técnico',
            footer: 'La respuesta de la API fue 0',
            icon: 'info'
          })
        } else {
          if (props.devuelveVal !== undefined) props.devuelveVal(nuevoNoFact)
        }
      } else {
        setCargando(false)
        props.devuelveEditando(false)
        if (props.devuelveVal !== undefined) props.devuelveVal(valTablaIni)
      }
    }
  }

  const fmtoSerie = fmto === undefined ? '' : fmto[0]
  const fmtoLabel = fmto === undefined ? '' : fmto[1]
  const fmtoTexto = fmto === undefined ? '' : fmto[2]

  if (!stdAdmin.auth || !userEmpConfig) return null

  // Solo para cuando viene del botón modificar con el único campo NO_REG en regsCab
  if (props.valTabla === undefined) return null

  return (
    <InputGroup
      className='mb-1'
      size='sm'
      style={{ minWidth: 300, maxWidth: 300 }}
      hidden={hidden}
    >
      <InputGroup.Text className={fmtoLabel} size='sm' onDoubleClick={ctrlDblClick}>{label}</InputGroup.Text>
      <Spinner animation='grow' size='sm' hidden={!cargando} />
      {/* Series */}
      <Form.Select
        id='series' className={fmtoSerie + (fmtoWarning ? gcFmtosColorFondo.noExiste : '')}
        style={{ fontSize: '12px', minWidth: 115, maxWidth: 115 }}
        value={campoSerie}
        onChange={ctrlBlurCampo}
      >{filtrando || id === 'noFactNC' ? <option value='' key='' /> : null}
        {series[0].map(
          serieE => (
            <option
              value={`${serieE}`}
              key={serieE}
            >
              {serieE}
            </option>
          )
        )}
        {series[1].map(
          serieF => (
            <option
              value={`${serieF}`}
              key={serieF}
            >
              {serieF + ' F'}
            </option>
          )
        )}
      </Form.Select>
      {/* Número */}
      <Form.Control
        className={'ms-1 ' + fmtoTexto + (fmtoWarning ? gcFmtosColorFondo.noExiste : '')}
        style={{ minWidth: 105, maxWidth: 105 }}
        id={id}
        type={(filtrando || id === 'noFactNC') ? 'text' : 'number'}
        aria-describedby={id}
        min={min}
        max={max}
        value={campoNo}
        required={required}
        disabled={disabled || (!filtrando && (campoSerie === '' || campoSerie === '000-000'))}
        onChange={ctrlChangeNo}
        onFocus={(e) => { soloNoFactFoco.current = e.target.value }}
        onBlur={ctrlBlurCampo}
      />
    </InputGroup>
  )
}

export default NoFactura
